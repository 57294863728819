<template>
  <!-- Navbar -->
  <nav class="navbar navbar-expand" id="subheader">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item" v-if="GetLangPref === 'en'">
        <div class="dropdown">
          <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
            About Us
            <font-awesome-icon icon="fas fa-chevron-down " class="ml-1 translate-down" />
          </button>
          <ul class="dropdown-menu" role="menu">
            <li>
              <router-link class="dropdown-item" to="/about-us/about-us">About Us</router-link>
            </li>
            <hr class="my-1" />
            <li>
              <router-link class="dropdown-item" to="/about-us/vision">Vision</router-link>
            </li>
            <li>
              <router-link class="dropdown-item" to="/about-us/mission">Mission</router-link>
            </li>
            <li>
              <router-link class="dropdown-item" to="/about-us/our-system">Our System</router-link>
            </li>
            <li>
              <router-link class="dropdown-item" to="/about-us/why-us">Why Us?</router-link>
            </li>
            <li>
              <router-link class="dropdown-item" to="/about-us/reviews-and-testimonials">Review &
                Testimonials</router-link>
            </li>
            <li>
              <router-link class="dropdown-item" to="/about-us/honor-code">Honor Code</router-link>
            </li>
            <li>
              <router-link class="dropdown-item" to="/about-us/careers">Careers</router-link>
            </li>
            <li>
              <router-link class="dropdown-item" to="/about-us/tutoring-consulting-jobs">Tutoring Consulting
                Job</router-link>
            </li>
            <li>
              <router-link class="dropdown-item" to="/about-us/contact-us">Contact Us</router-link>
            </li>
            <li>
              <router-link class="dropdown-item" to="/about-us/location">Location</router-link>
            </li>
          </ul>
        </div>
      </li>

      <li class="nav-item" v-if="GetLangPref === 'tr'">
        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
          Hakkımızda
          <font-awesome-icon icon="fas fa-chevron-down " class="ml-1 translate-down" />
        </button>
        <ul class="dropdown-menu" role="menu">
          <li>
            <router-link class="dropdown-item" to="/about-us/about-us">Hakkımızda</router-link>
          </li>
          <hr class="my-1" />
          <li>
            <router-link class="dropdown-item" to="/about-us/vision">Vizyon</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" to="/about-us/mission">Misyon</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" to="/about-us/our-system">Sistemimiz</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" to="/about-us/why-us">Neden Biz?</router-link>
          </li>

          <li>
            <router-link class="dropdown-item" to="/about-us/reviews-and-testimonials">Yorum & Referanslar</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" to="/about-us/honor-code">Etik Kurallar</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" to="/about-us/careers">Kariyer</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" to="/about-us/tutoring-consulting-jobs">Hocalık ve Danışmanlık
              Başvurusu</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" to="/about-us/contact-us">Bize Ulaşın</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" to="/about-us/location">Lokasyon</router-link>
          </li>
        </ul>
      </li>
      <li class="nav-item" v-if="GetLangPref === 'en'">
        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
          Private Tutoring
          <font-awesome-icon icon="fas fa-chevron-down" class="ml-1 translate-down" />
        </button>
        <ul class="dropdown-menu" role="menu">
          <li>
            <router-link to="/private-tutoring" type="button" class="btn dropdown-item" data-toggle="dropdown">
              Private Tutoring
            </router-link>
          </li>
          <hr class="my-1" />
          <li>
            <router-link to="/private-tutoring/IB" type="button" class="btn dropdown-item" data-toggle="dropdown">
              IB
              <font-awesome-icon icon="fas fa-chevron-right" class="float-end" />
            </router-link>
            <ul class="dropdown-menu dropdown-submenu" role="menu">
              <li>
                <router-link class="dropdown-item" to="/private-tutoring/IB/economics">Economics</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/private-tutoring/IB/mathematics">Mathematics</router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/private-tutoring/AP" type="button" class="btn dropdown-item" data-toggle="dropdown">
              AP
              <font-awesome-icon icon="fas fa-chevron-right" class="float-end" />
            </router-link>
            <ul class="dropdown-menu dropdown-submenu" role="menu">
              <li>
                <router-link class="dropdown-item" to="/private-tutoring/AP/microeconomics">Microeconomics</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/private-tutoring/AP/macroeconomics">Macroeconomics</router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/private-tutoring/A-Level" type="button" class="btn dropdown-item" data-toggle="dropdown">
              A-Level
              <font-awesome-icon icon="fas fa-chevron-right" class="float-end" />
            </router-link>
            <ul class="dropdown-menu dropdown-submenu" role="menu">
              <li>
                <router-link class="dropdown-item" to="/private-tutoring/A-Level/economics">Economics</router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/private-tutoring/AS-Level" type="button" class="btn dropdown-item" data-toggle="dropdown">
              AS-Level
              <font-awesome-icon icon="fas fa-chevron-right" class="float-end" />
            </router-link>
            <ul class="dropdown-menu dropdown-submenu" role="menu">
              <li>
                <router-link class="dropdown-item" to="/private-tutoring/AS-Level/economics">Economics</router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/private-tutoring/IGCSE" type="button" class="btn dropdown-item" data-toggle="dropdown">
              IGCSE
              <font-awesome-icon icon="fas fa-chevron-right" class="float-end" />
            </router-link>
            <ul class="dropdown-menu dropdown-submenu" role="menu">
              <li>
                <router-link class="dropdown-item" to="/private-tutoring/IGCSE/economics">Economics 0455</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="nav-item" v-if="GetLangPref === 'tr'">
        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
          Özel Ders
          <font-awesome-icon icon="fas fa-chevron-down" class="ml-1 translate-down" />
        </button>
        <ul class="dropdown-menu" role="menu">
          <li>
            <router-link to="/private-tutoring" type="button" class="btn dropdown-item" data-toggle="dropdown">
              Özel Ders
            </router-link>
          </li>
          <hr class="my-1" />
          <li>
            <router-link to="/private-tutoring/IB" type="button" class="btn dropdown-item" data-toggle="dropdown">
              IB
              <font-awesome-icon icon="fas fa-chevron-right" class="float-end" />
            </router-link>
            <ul class="dropdown-menu dropdown-submenu" role="menu">
              <li>
                <router-link class="dropdown-item" to="/private-tutoring/IB/economics">Ekonomi</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/private-tutoring/IB/mathematics">Matematik</router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/private-tutoring/AP" type="button" class="btn dropdown-item" data-toggle="dropdown">
              AP
              <font-awesome-icon icon="fas fa-chevron-right" class="float-end" />
            </router-link>
            <ul class="dropdown-menu dropdown-submenu" role="menu">
              <li>
                <router-link class="dropdown-item" to="/private-tutoring/AP/microeconomics">Mikroekonomi</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/private-tutoring/AP/macroeconomics">Makroekonomi</router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/private-tutoring/A-Level" type="button" class="btn dropdown-item" data-toggle="dropdown">
              A-Level
              <font-awesome-icon icon="fas fa-chevron-right" class="float-end" />
            </router-link>
            <ul class="dropdown-menu dropdown-submenu" role="menu">
              <li>
                <router-link class="dropdown-item" to="/private-tutoring/A-Level/economics">Ekonomi</router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/private-tutoring/AS-Level" type="button" class="btn dropdown-item" data-toggle="dropdown">
              AS-Level
              <font-awesome-icon icon="fas fa-chevron-right" class="float-end" />
            </router-link>
            <ul class="dropdown-menu dropdown-submenu" role="menu">
              <li>
                <router-link class="dropdown-item" to="/private-tutoring/AS-Level/economics">Ekonomi</router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/private-tutoring/IGCSE" type="button" class="btn dropdown-item" data-toggle="dropdown">
              IGCSE
              <font-awesome-icon icon="fas fa-chevron-right" class="float-end" />
            </router-link>
            <ul class="dropdown-menu dropdown-submenu" role="menu">
              <li>
                <router-link class="dropdown-item" to="/private-tutoring/IGCSE/economics">Ekonomi 0455</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="nav-item" v-if="GetLangPref === 'en'">
        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
          Test Preparation
          <font-awesome-icon icon="fas fa-chevron-down" class="ml-1 translate-down" />
        </button>
        <ul class="dropdown-menu" role="menu">
          <li>
            <router-link to="/test-preparation" type="button" class="btn dropdown-item" data-toggle="dropdown">
              Test Preparation
            </router-link>
          </li>
          <hr class="my-1" />
          <li>
            <router-link to="/test-preparation/SAT" type="button" class="btn dropdown-item" data-toggle="dropdown">
              SAT
            </router-link>
          </li>
          <li>
            <router-link to="/test-preparation/ACT" type="button" class="btn dropdown-item" data-toggle="dropdown">
              ACT
              <font-awesome-icon icon="fas fa-chevron-right" class="float-end" />
            </router-link>
            <ul class="dropdown-menu dropdown-submenu" role="menu">
              <li>
                <router-link class="dropdown-item" to="/test-preparation/ACT/mathematics">Mathematics</router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/test-preparation/TOEFL" type="button" class="btn dropdown-item" data-toggle="dropdown">
              TOEFL
            </router-link>
          </li>
          <li>
            <router-link to="/test-preparation/IELTS" type="button" class="btn dropdown-item" data-toggle="dropdown">
              IELTS
            </router-link>
          </li>
        </ul>
      </li>
      <li class="nav-item" v-if="GetLangPref === 'tr'">
        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
          Sınava Hazırlık
          <font-awesome-icon icon="fas fa-chevron-down" class="ml-1 translate-down" />
        </button>
        <ul class="dropdown-menu" role="menu">
          <li>
            <router-link to="/test-preparation" type="button" class="btn dropdown-item" data-toggle="dropdown">
              Sınava Hazırlık
            </router-link>
          </li>
          <hr class="my-1" />
          <li>
            <router-link to="/test-preparation/SAT" type="button" class="btn dropdown-item" data-toggle="dropdown">
              SAT
            </router-link>
          </li>
          <li>
            <router-link to="/test-preparation/ACT" type="button" class="btn dropdown-item" data-toggle="dropdown">
              ACT
              <font-awesome-icon icon="fas fa-chevron-right" class="float-end" />
            </router-link>
            <ul class="dropdown-menu dropdown-submenu" role="menu">
              <li>
                <router-link class="dropdown-item" to="/test-preparation/ACT/mathematics">Matematik</router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/test-preparation/TOEFL" type="button" class="btn dropdown-item" data-toggle="dropdown">
              TOEFL
              <font-awesome-icon icon="fas fa-chevron-right" class="float-end" />
            </router-link>
          </li>
          <li>
            <router-link to="/test-preparation/IELTS" type="button" class="btn dropdown-item" data-toggle="dropdown">
              IELTS
              <font-awesome-icon icon="fas fa-chevron-right" class="float-end" />
            </router-link>
          </li>
        </ul>
      </li>
      <li class="nav-item" v-if="GetLangPref === 'en'">
        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
          Consulting
          <font-awesome-icon icon="fas fa-chevron-down" class="ml-1 translate-down" />
        </button>
        <ul class="dropdown-menu" role="menu">
          <li>
            <router-link to="/consulting" type="button" class="btn dropdown-item" data-toggle="dropdown">
              Consulting
            </router-link>
          </li>
          <hr class="my-1" />
          <li>
            <router-link class="dropdown-item" to="/consulting/academic-coaching">Academic Coaching</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" to="/consulting/overseas-education-consulting">Overseas Education
              Consulting</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" to="/consulting/college-counseling">College Counseling</router-link>
          </li>
        </ul>
      </li>
      <li class="nav-item" v-if="GetLangPref === 'tr'">
        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
          Danışmanlık
          <font-awesome-icon icon="fas fa-chevron-down" class="ml-1 translate-down" />
        </button>
        <ul class="dropdown-menu" role="menu">
          <li>
            <router-link to="/consulting" type="button" class="btn dropdown-item" data-toggle="dropdown">
              Danışmanlık
            </router-link>
          </li>
          <hr class="my-1" />
          <li>
            <router-link class="dropdown-item" to="/consulting/academic-coaching">Akademik Koçluk</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" to="/consulting/overseas-education-consulting">Yurtdışı Eğitim
              Danışmanlığı</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" to="/consulting/college-counseling">Üniversite Başvuru
              Danışmanlığı</router-link>
          </li>
        </ul>
      </li>
      <li class="nav-item" v-if="GetLangPref === 'en'">
        <router-link type="button" class="btn dropdown-toggle" to="/FAQ">
          FAQ
        </router-link>
      </li>
      <li class="nav-item" v-if="GetLangPref === 'tr'">
        <router-link type="button" class="btn dropdown-toggle" to="/FAQ">
          SSS
        </router-link>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
</template>

<script>
export default {
  computed: {
    GetLangPref() {
      return this.$store.state.langPref;
    },
  },
};
</script>
<style scoped>
.navbar {
  width: 100%;
  background-color: var(--background-color-third);
  height: 50px;
  border: none;
  position: sticky;
  top: 65px;
  z-index: 90;
}

.navbar-nav {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
  row-gap: 10px;
}

.nav-item {
  position: relative;
}

.dropdown-toggle {
  color: var(--text-primary-color);
}

.dropdown-toggle::after {
  display: none;
}

.translate-down {
  transition: all 0.3s;
}

.dropdown-toggle:hover {
  background-color: var(--navlink-hovered-bg);
}

.dropdown-toggle:hover .translate-down {
  transform: translateY(3px);
}

.dropdown-menu {
  padding: 0.25rem 0;
  background-color: var(--background-color-secondary);
}

.dropdown-item {
  color: var(--text-primary-color);
  border-radius: 0;
}

.dropdown-item:hover {
  background-color: var(--background-color-third);
}

.dropdown-item:focus {
  background-color: var(--background-color-third);
}

.float-end {
  float: right;
  margin-top: 3px;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu>li:hover>.dropdown-submenu {
  display: block;
}

.dropdown-hover:hover>.dropdown-menu {
  display: inline-block;
}

.dropdown-hover>.dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .dropdown-menu .dropdown-submenu {
    display: none;
    position: absolute;
    left: 50%;
    top: -7px;
  }
}
</style>

<!--<div class="dropdown">
          <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            aria-expanded="false"
            data-toggle="dropdown"
          >
            Dropdown button
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li>
              <a class="dropdown-item" href="#">Another action</a>
            </li>
            <li>
              <a class="dropdown-item" href="#"> Submenu &raquo; </a>
              <ul class="dropdown-menu dropdown-submenu">
                <li>
                  <a class="dropdown-item" href="#">Submenu item 1</a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">Submenu item 2</a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">Submenu item 3 &raquo; </a>
                  <ul class="dropdown-menu dropdown-submenu">
                    <li>
                      <a class="dropdown-item" href="#">Multi level 1</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Multi level 2</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a class="dropdown-item" href="#">Submenu item 4</a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">Submenu item 5</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>-->
