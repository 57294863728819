import { createStore } from "vuex";

const store =  createStore({
  state: {
    role:"admin",
    officeType: "frontoffice",
    langPref: "en",
    signType: "login",
    cardValid: true,
    cardVisibility: "visible",
    sidebarPos:"offside",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});


export default store;