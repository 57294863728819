<template>
  <!-- Navbar -->
  <nav class="navbar navbar-expand-lg" id="frontoffice-expand-navbar">
    <div class="container-fluid">
      <router-link id="frontoffice-navbar-brand" class="navbar-brand" to="/index.html">TBU</router-link>
      <div class="link register-link-small-screen">
        <router-link style="font-weight: 400" to="/register"><span v-if="GetLangPref === 'en'">Register</span><span
            v-if="GetLangPref === 'tr'">Kayıt Ol</span></router-link>
      </div>
      <div class="link call-us-link-small-screen">
        <a href="tel:6466203474" style="font-size: 0.9rem; font-weight: 400">
          <font-awesome-icon icon="fas fa-phone" />
          (646) 620 3474
        </a>
      </div>
      <div class="link login-link-small-screen">
        <router-link style="font-weight: 400" @click="ChangeSignType('login')" to="/login"><span
            v-if="GetLangPref === 'en'">Login</span><span v-if="GetLangPref === 'tr'">Giriş Yap</span></router-link>
      </div>
      <div class="role-link-small-screen" style="font-size: 0.9rem">
        <div>
          <input type="radio" class="radio-button mr-1" name="radio" />
          <span v-if="GetLangPref === 'en'">I am a student</span>
          <span v-if="GetLangPref === 'tr'">Öğrenciyim</span>
        </div>
        <div>
          <input type="radio" class="radio-button mr-1" name="radio" />
          <span v-if="GetLangPref === 'en'">I am a parent</span>
          <span v-if="GetLangPref === 'tr'">Veliyim</span>
        </div>
      </div>

      <div class="country-link-small-screen" v-if="GetLangPref === 'en'">
        <select class="form-control selectbox">
          <option selected>US</option>
          <option>Canada</option>
          <option>Turkey</option>
          <option>India</option>
          <option>UK</option>
        </select>
      </div>
      <div class="country-link-small-screen" v-if="GetLangPref === 'tr'">
        <select class="form-control selectbox">
          <option selected>ABD</option>
          <option>Kanada</option>
          <option>Türkiye</option>
          <option>Hindistan</option>
          <option>İngiltere</option>
        </select>
      </div>
      <div class="lang-link-small-screen" v-if="GetLangPref === 'en'">
        <select class="form-control selectbox" @change="ChangeLangPref('tr')">
          <option selected>English</option>
          <option>Turkish</option>
        </select>
      </div>
      <div class="lang-link-small-screen" v-if="GetLangPref === 'tr'">
        <select class="form-control selectbox" @change="ChangeLangPref('en')">
          <option selected>Türkçe</option>
          <option>İngilizce</option>
        </select>
      </div>
      <button class="navbar-toggler" type="button" @click="ToggleSidebar()">
        <font-awesome-icon style="font-size: 1.5rem" icon="fas fa-bars" />
      </button>
      <div class="frontofficenavbar" id="frontofficenavbar">
        <button id="sidebar-close-btn" style="display: none" type="button" class="close btn mr-4 mt-2"
          @click="ToggleSidebar()">
          <font-awesome-icon style="font-size: 2rem" icon="fas fa-xmark" />
        </button>

        <ul class="navbar-nav me-auto mb-2 mb-lg-0" id="frontoffice-navbar-nav">
          <li class="nav-item link register-link-big-screen">
            <router-link style="font-weight: 400" to="/register"><span v-if="GetLangPref === 'en'">Register</span><span
                v-if="GetLangPref === 'tr'">Kayıt Ol</span></router-link>
          </li>
          <li class="nav-item link call-us-link-big-screen">
            <a href="tel:6466203474" style="font-size: 0.9rem; font-weight: 400">
              <font-awesome-icon icon="fas fa-phone" />
              (646) 620 3474
            </a>
          </li>
          <li class="nav-item link login-link-big-screen">
            <router-link style="font-weight: 400" @click="ChangeSignType('login')" to="/login"><span
                v-if="GetLangPref === 'en'">Login</span><span v-if="GetLangPref === 'tr'">Giriş Yap</span></router-link>
          </li>
          <li class="role-link-big-screen nav-item " style="font-size: 0.9rem">
            <div class="d-block">
              <input type="radio" class="radio-button mr-1" name="radio" />
              <span v-if="GetLangPref === 'en'">I am a student</span>
              <span v-if="GetLangPref === 'tr'">Öğrenciyim</span>
            </div>
            <div class="d-block">
              <input type="radio" class="radio-button mr-1" name="radio" />
              <span v-if="GetLangPref === 'en'">I am a parent</span>
              <span v-if="GetLangPref === 'tr'">Veliyim</span>
            </div>
          </li>

          <li class="nav-item country-link-big-screen" v-if="GetLangPref === 'en'">
            <select class="form-control selectbox text-sm">
              <option selected>US</option>
              <option>Canada</option>
              <option>Turkey</option>
              <option>India</option>
              <option>UK</option>
            </select>
          </li>
          <li class="nav-item country-link-big-screen" v-if="GetLangPref === 'tr'">
            <select class="form-control selectbox text-sm">
              <option selected>ABD</option>
              <option>Kanada</option>
              <option>Türkiye</option>
              <option>Hindistan</option>
              <option>İngiltere</option>
            </select>
          </li>
          <li class="nav-item lang-link-big-screen" v-if="GetLangPref === 'en'">
            <select class="form-control selectbox text-sm" @change="ChangeLangPref('tr')">
              <option selected>English</option>
              <option>Turkish</option>
            </select>
          </li>
          <li class="nav-item lang-link-big-screen" v-if="GetLangPref === 'tr'">
            <select class="form-control selectbox text-sm" @change="ChangeLangPref('en')">
              <option selected>Türkçe</option>
              <option>İngilizce</option>
            </select>
          </li>

          <li class="nav-item">
            <SwitchThemeButton></SwitchThemeButton>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!-- /.navbar -->
</template>

<script>
import SwitchThemeButton from "./SwitchThemeButton.vue";
export default {
  components: {
    SwitchThemeButton,
  },
  methods: {
    ChangeSignType(type) {
      this.$store.state.signType = type;
    },

    ToggleSidebar() {
      var sidebar = document.getElementById("frontofficenavbar");

      sidebar.classList.toggle("moveRight");
    },
    ChangeLangPref(lang) {
      this.$store.state.langPref = lang;
    },
  },
  computed: {
    GetLangPref() {
      return this.$store.state.langPref;
    },
  },
};
</script>
<style scoped>
* {
  color: var(--text-color);
}

.moveRight {
  left: 0;
}

.navbar {
  background-color: var(--background-color-header);
  height: 65px;
  border: none;
  position: sticky;
  top: 0;
  z-index: 999;
}

/* 
.radio-button {
  visibility: hidden;
}
.radio-button::after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--background-color-fourth);
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid var(--text-color);
}
.radio-button:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: lightseagreen;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid var(--text-color);
}*/

.link {
  transition: all 0.3s;
}

.link a:hover {
  color: var(--text-color);
  text-decoration: underline;
}

.selectbox {
  width: 95px;
  background-color: var(--background-color-header);
  border: 1px solid var(--text-color);
}

.selectbox:focus {
  color: var(--text-color);
}
</style>
