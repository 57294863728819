<template>
  <div class="wrapper">
    <BackOfficeHeader v-if="GetOfficeType === 'backoffice'"></BackOfficeHeader>
    <FrontOfficeHeader v-if="GetOfficeType === 'frontoffice'"></FrontOfficeHeader>
    <SubHeader v-if="GetOfficeType === 'frontoffice'"></SubHeader>
    <router-view name="Sidebar"></router-view>
    <!--
      <div style="display: none">
      <Sidebar class="dnone"></Sidebar>
    </div>
    -->

    <router-view />
    <SubFooter v-if="GetOfficeType === 'frontoffice'"></SubFooter>
    <FrontOfficeFooter v-if="GetOfficeType === 'frontoffice'"></FrontOfficeFooter>
    <Footer v-if="GetOfficeType === 'backoffice'"></Footer>
  </div>
</template>

<script>
import BackOfficeHeader from "./components/BackOfficeHeader.vue";
import Footer from "./components/Footer.vue";
import FrontOfficeHeader from "./components/FrontOfficeHeader.vue";
import SubHeader from "./components/SubHeader.vue";
import FrontOfficeFooter from "./components/FrontOfficeFooter.vue";
import SubFooter from "./components/SubFooter.vue";

export default {
  components: {
    BackOfficeHeader,
    Footer,
    FrontOfficeHeader,
    SubHeader,
    FrontOfficeFooter,
    SubFooter,
  },

  computed: {
    GetOfficeType() {
      return this.$store.state.officeType;
    },
  },
};
</script>
<style>
:root {
  --background-color-sidebar: #d6d6ff;
  --background-color-darker-sidebar: #ad9fc5;
  --background-color-darkest-sidebar: #a88ed3;
  --background-color-black-sidebar: #926cce;
  --background-color-header: #f2e9e4;
  --background-color-primary: #1e1e1e;
  --background-color-secondary: #fafafa;
  --background-color-third: #f2f2f2;
  --background-color-fourth: #cccccc;
  --background-color-fifth: #7e7878;
  --switchlabel-bg: #e6e6e6;
  --accent-color: #1a1a1a;
  --text-primary-color: #222;
  --bg-sidebar-link-hovered: #e6e6e6;
  --bg-sidebar-link-active: #be92a2;
  --navlink-hovered-bg: #e6e6e6;
  --text-color: #1a1a1a;
  --absolute-color: #000000;
  --buttonicon-hovered-bg: #cccccc;
  --element-size: 2rem;
  --fc-button-active-border-color: transparent;
  --tab-border-color: #1a1a1a;
  --toast-bg-color: #daceee;
  --edit-mode-bg-color: #d0f4de;
  --visible-card-bg-color: #fde4cf;
  --color-success: #3dca5e;
  --color-danger: tomato;
  --bs-accordion-btn-color: var(--text-color);
}

/* Define styles for the root window with dark - mode preference */
:root.dark-theme {
  --background-color-primary: #e6e6e6;
  --background-color-secondary: #0f0f0f;
  --background-color-third: #343a40;
  --background-color-fourth: #6d7781;
  --background-color-fifth: #d1d3d4;
  --switchlabel-bg: #012649;
  --accent-color: #e6e6e6;
  --text-primary-color: #ddd;
  --background-color-sidebar: #22223b;
  --background-color-darker-sidebar: #02254b;
  --background-color-darkest-sidebar: #021830;
  --background-color-black-sidebar: #000000;
  --background-color-header: #22223b;
  --bg-sidebar-link-hovered: #6d7781;
  --navlink-hovered-bg: #6d7781;
  --text-color: #e6e6e6;
  --absolute-color: #ffffff;
  --buttonicon-hovered-bg: #666666;
  --fc-button-bg-color: var(--background-color-fourth);
  --fc-button-active-bg-color: rgb(160, 125, 161);
  --fc-button-active-border-color: transparent;
  --fc-button-hover-bg-color: rgb(160, 125, 161);
  --fc-button-hover-border-color: transparent;
  --tab-border-color: #daceee;
  --toast-bg-color: #daceee;
  --edit-mode-bg-color: #264653;
  --visible-card-bg-color: #22577a;
  --color-success: #24973f;
  --color-danger: tomato;
  --bs-accordion-btn-color: var(--text-color);
}

.accordion {
  --bs-accordion-btn-color: var(--text-color);
}

.accoridon-button::after {
  color: var(--text-color);
}

.dnone {
  display: none !important;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(160, 160, 160);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

::-webkit-datetime-edit {
  padding: 1em;
}

::-webkit-datetime-edit-text {
  padding: 0 0.3em;
}

#app {
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content-wrapper {
  background-color: var(--background-color-secondary);
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

.card {
  background-color: transparent;
  box-shadow: none;
}

.btn {
  border-radius: 10px;
}

a {
  color: var(--text-color);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-color);
}

.fc-scrollgrid-shrink-cushion {
  color: var(--text-color);
}

.fc-daygrid-event:hover {
  box-shadow: inset 0 0 90px rgb(0 0 0 / 20%);
}

.sendButton {
  border: none;
  border-radius: 50px;
  background-color: transparent;
  color: var(--text-color);
}

.fc-daygrid-event:hover .sendButton {
  display: flex;
}

.modal-content {
  background-color: var(--background-color-secondary);
  border-radius: 10px;
}

.modal-body {
  color: var(--text-color);
}

.selectable-card-selection-container {
  position: absolute;
  top: -25px;
  left: 0;
  width: 100%;
  height: 25px;
  background-color: var(--background-color-fourth);
  transition: all 0.3s;
  text-align: center;
  z-index: 10;
}

.selectable-cards:hover .selectable-card-selection-container {
  top: 0;
}

.form-control {
  border: none;
  background-color: var(--background-color-third);
  border-radius: 8px;
  color: var(--text-color);
}

.form-control:focus {
  color: var(--text-color);
  background-color: var(--background-color-third);
}

.card-brand {
  position: absolute;
  top: 40px;
  left: 40px;
  font-size: 32px;
}

.close {
  color: var(--text-color);
}

.close:hover {
  color: var(--absolute-color);
}

.icon {
  z-index: 999;
}

.save-icon {
  bottom: 10px;
  right: 10px;
}

.edit-icon {
  top: 30px;
  right: 20px;
}

.delete-icon {
  top: 30px;
  right: 140px;
}

.visible-icon {
  top: 30px;
  right: 80px;
}

.cancel-icon {
  bottom: 10px;
  left: 20px;
}

.focus-out-icon {
  bottom: 10px;
  left: 20px;
}

.invisible-icon {
  top: 30px;
  right: 80px;
}

.selected-icon {
  position: absolute;
  padding: 5px 15px;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-success);
  color: #000000;
  font-weight: 700;
  border-radius: 50px;
}

.select-icon {
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--background-color-third);
}

.send-btn {
  height: 40px;
}

.key-value-pair {
  line-height: 28px;
  flex: 1 1 400px;
  text-align: left;
}

.value {
  font-weight: 600;
  padding: 0px 10px;
  border-radius: 10px;
}

.value:focus {
  outline: none;
}

.border {
  border-color: var(--background-color-fourth) !important;
}

.frontofficenavbar {
  transition: all 0.3s;
}

.inputs {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

input[type="checkbox"] {
  appearance: none;
  background-color: var(--background-color-fourth);
  margin: 0;
  font: inherit;
  color: var(--background-color-third);
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid var(--background-color-third);
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

input[type="checkbox"]::before {
  content: "";
  width: 1rem;
  height: 1rem;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1rem 1rem var(--color-success);
  border-radius: 3px;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.btn-default {
  background-color: var(--background-color-third);
}

.btn-default:hover {
  background-color: var(--background-color-fourth);
}

/*front office css*/
#frontoffice-navbar-nav {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 50px;
}

#frontoffice-navbar-small-screen {
  width: 100%;
  display: flex;
  align-items: center;
  float: left;
  overflow: hidden;
  column-gap: 50px;
}

#frontoffice-navbar-brand {
  font-size: 24px;
  margin-left: 40px;
}

#frontoffice-navbar-brand:hover {
  color: var(--text-color);
}

.content-group {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.text {
  font-size: 1rem;
}

.text-group {
  display: block;
}

hr:not(.hr-text) {
  background-color: var(--background-color-fourth);
}

.breadcrumb-link:hover {
  color: var(--text-color);
  text-decoration: underline;
}

.info-header {
  font-size: 22px;
  font-weight: 600;
}

.info-sub-header {
  font-size: 18px;
}

.custom-file-label {
  background-color: var(--background-color-third);
  color: var(--text-color);
  border-radius: 8px;
  border-color: var(--background-color-fourth);
}

.custom-file-label::after {
  background-color: var(--background-color-fourth);
  color: var(--text-color);
  border-radius: 8px;
  border-color: transparent;
}

.country-link-small-screen,
.role-link-small-screen,
.lang-link-small-screen,
.login-link-small-screen,
.call-us-link-small-screen,
.register-link-small-screen {
  display: none;
}

.intro-container {
  height: 500px;
}

.star-full {
  color: yellow !important;
}

.blue-icon {
  color: blue;
}

.rounded-full {
  border-radius: 5px;
  width: 100%;
  height: 250px;
}

.right-img {
  width: 300px;
  height: fit-content;
  float: right;
  padding: 10px;
  border: 1px solid var(--background-color-third);
  border-radius: 10px;
  margin: 5px;
}

.right-img img {
  width: 100%;
}

@media screen and (max-width: 1400px) {
  #frontoffice-navbar-nav {
    column-gap: 20px;
  }

  #frontoffice-navbar-brand {
    margin-left: 10px;
  }
}

@media screen and (max-width: 1200px) {
  #subheader {
    height: fit-content !important;
  }
}

@media screen and (max-width: 992px) {
  .content-group {
    flex-direction: column;
  }

  #sidebar-close-btn {
    display: block !important;
  }

  #frontoffice-expand-navbar {
    padding-right: 20px;
  }

  .frontofficenavbar {
    z-index: 999;
    background-color: var(--background-color-sidebar);
    height: 100vh;
    width: 300px;
    position: absolute;
    top: 0;
    left: -300px;
    padding-top: 20px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
  }

  .frontofficenavbar .nav-item {
    margin-top: 30px;
  }

  .parent {
    grid-template-columns: 1fr 0.2fr repeat(2, 1fr) !important;
  }

  .country-link-small-screen,
  .role-link-small-screen,
  .lang-link-small-screen,
  .login-link-small-screen,
  .call-us-link-small-screen,
  .register-link-small-screen {
    display: inline-block;
  }

  .country-link-big-screen,
  .role-link-big-screen,
  .lang-link-big-screen,
  .register-link-big-screen,
  .login-link-big-screen,
  .call-us-link-big-screen {
    display: none;
  }

  .second-row-cards {
    height: fit-content !important;
  }
}

@media screen and (max-width: 768px) {
  #frontoffice-navbar-nav {
    font-size: 12px;
  }

  .container-about-us {
    padding-top: 50px !important;
  }

  .navbar-nav {
    justify-content: flex-start;
    column-gap: 0;
  }

  .right-img {
    float: none;
  }

  .switch-label {
    width: 56px !important;
  }

  .subdropdown-menu {
    transform: translate(0, 40px) !important;
  }

  .country-link-small-screen,
  .lang-link-small-screen {
    display: none;
  }

  .country-link-big-screen,
  .lang-link-big-screen {
    display: inline-block;
  }
}

@media screen and (max-width: 576px) {
  * {
    font-size: 12px;
  }

  .edit-icon,
  .delete-icon,
  .visible-icon,
  .invisible-icon {
    top: 20px;
  }

  .switch-label {
    width: 48px !important;
  }

  .subdropdown-menu {
    transform: translate(0, 40px) !important;
  }

  .right-img {
    width: 100% !important;
  }

  img:not(.img-profile) {
    width: 100% !important;
  }

  .role-link-small-screen {
    display: none;
  }

  .role-link-big-screen {
    display: inline-block;
  }
}

@media screen and (max-width: 450px) {
  #frontoffice-navbar-brand {
    margin-left: 10px;
  }
}

/*https://coolors.co/d8e1ff-daceee-dbbadd-be92a2*/
</style>
