<template>
  <div class="subfooter">
    <div class="container">
      <div class="links" v-if="GetLangPref === 'en'">
        <router-link to="/about-us/about-us" class="subfooter-link"
          >About Us</router-link
        >
        <router-link to="/private-tutoring" class="subfooter-link"
          >Tutoring</router-link
        >
        <router-link to="/about-us/vision" class="subfooter-link"
          >Vision</router-link
        >
        <router-link to="/test-preparation" class="subfooter-link"
          >Test Preparation</router-link
        ><router-link to="/about-us/mission" class="subfooter-link"
          >Mission</router-link
        >
        <router-link to="/consulting" class="subfooter-link"
          >Consulting</router-link
        >
        <router-link
          to="/about-us/reviews-and-testimonials"
          class="subfooter-link"
          >Reviews and Testimaonials</router-link
        >
        <router-link to="/about-us/careers" class="subfooter-link"
          >Careers</router-link
        >
        <router-link
          to="/about-us/tutoring-consulting-jobs"
          class="subfooter-link"
          >Tutoring Consulting Jobs</router-link
        >
        <router-link to="/about-us/why-us" class="subfooter-link"
          >Why Us?</router-link
        >
        <router-link to="/about-us/contact-us" class="subfooter-link"
          >Contact Us</router-link
        >
        <router-link to="/about-us/our-system" class="subfooter-link"
          >Our System</router-link
        >
        <router-link to="/about-us/honor-code" class="subfooter-link"
          >Honor Code</router-link
        >
        <router-link to="/about-us/location" class="subfooter-link"
          >Location</router-link
        >
      </div>
      <div class="links" v-if="GetLangPref === 'tr'">
        <router-link to="/about-us/about-us" class="subfooter-link"
          >Hakkımızda</router-link
        >
        <router-link to="/private-tutoring" class="subfooter-link"
          >Özel Ders</router-link
        >
        <router-link to="/about-us/vision" class="subfooter-link"
          >Vizyon</router-link
        >
        <router-link to="/test-preparation" class="subfooter-link"
          >Sınava Hazırlık</router-link
        ><router-link to="/about-us/mission" class="subfooter-link"
          >Misyon</router-link
        >
        <router-link to="/consulting" class="subfooter-link"
          >Danışmanlık</router-link
        >
        <router-link
          to="/about-us/reviews-and-testimonials"
          class="subfooter-link"
          >Yorum & Referanslar</router-link
        >
        <router-link to="/about-us/careers" class="subfooter-link"
          >Kariyer</router-link
        >
        <router-link
          to="/about-us/tutoring-consulting-jobs"
          class="subfooter-link"
          >Hocalık ve Danışmanlık Başvuruları</router-link
        >
        <router-link to="/about-us/why-us" class="subfooter-link"
          >Neden Biz?</router-link
        >
        <router-link to="/about-us/contact-us" class="subfooter-link"
          >Bize Ulaşın</router-link
        >
        <router-link to="/about-us/our-system" class="subfooter-link"
          >Sistemimiz</router-link
        >
        <router-link to="/about-us/honor-code" class="subfooter-link"
          >Etik Kurallar</router-link
        >
        <router-link to="/about-us/location" class="subfooter-link"
          >Lokasyon</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    GetLangPref() {
      return this.$store.state.langPref;
    },
  },
};
</script>

<style>
.subfooter {
  width: 100%;
  background-color: var(--background-color-header);
  height: fit-content;
  padding: 20px 0;
}
.links {
  display: flex;
  flex-wrap: wrap;
}
.subfooter-link {
  width: 50%;
  height: 40px;
}
.subfooter-link:hover {
  text-decoration: underline;
  color: var(--text-color);
}
</style>
