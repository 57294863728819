<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <div class="nav-link" data-widget="pushmenu" @click="ToggleSidebar()" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" style="width: 20px; height: 20px" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </div>
      </li>
      <li class="nav-item">
        <SwitchThemeButton></SwitchThemeButton>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto mr-3">
      <li class="nav-item dropdown">
        <a id="navbarDropdown" class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <font-awesome-icon icon="fas fa-cog" />
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">FAQ</a>
          <a class="dropdown-item" href="#">Support</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Contact</a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a id="navbarDropdownProfile" class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <div class="d-flex justify-content-center align-items-center h-100">
            <div class="avatar">
              <img class="img-fluid img-circle img-size-32 mr-1 img-profile"
                src="../assets/istockphoto-1330286152-170667a.jpg" alt="User profile picture" />
            </div>
            <div class="status-info-name lightsidebar-text">Enes Altan</div>
          </div>
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownProfile">
          <a class="dropdown-item" href="#">Profile</a>
          <router-link class="dropdown-item" to="/index.html" @click="LogOut()" id="signout-item">Sign Out</router-link>
        </div>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
</template>

<script>
import SwitchThemeButton from "./SwitchThemeButton.vue";
export default {
  components: {
    SwitchThemeButton,
  },
  methods: {
    LogOut() {
      this.$store.state.officeType = "frontoffice";
      this.$store.state.sidebarPos = "offside";
      this.$router.push('/login');
    },
    ToggleSidebar() {
      if (this.$store.state.sidebarPos == 'onside') {
        this.$store.state.sidebarPos = "offside";
      } else {
        this.$store.state.sidebarPos = "onside";
      }

    }
  },
};
</script>

<style scoped>
.main-header {
  background-color: var(--background-color-header);
  border: none;
}

.nav-link {
  font-weight: 500;
  border-radius: 5px;
  color: var(--accent-color);
}

.nav-link:hover {
  background-color: var(--navlink-hovered-bg);
  color: var(--text-primary-color);
}

.dropdown-toggle::after {
  display: none;
}

#signout-item:hover {
  background-color: #dc3545;
  color: white;
}
</style>
