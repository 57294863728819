import { createRouter, createWebHistory } from "vue-router";
const routesOptions = [
  {
    path: "/admin",
    name: "BackOffice/Admin/Dashboard",
  },
  {
    path: "/admin/profile/personal-info",
    name: "BackOffice/Admin/Profile_Admin/PersonalInfo",
  },
  {
    path: "/admin/profile/introduction",
    name: "BackOffice/Admin/Profile_Admin/Introduction",
  },
  {
    path: "/admin/profile/education",
    name: "BackOffice/Admin/Profile_Admin/Education",
  },
  {
    path: "/admin/profile/professional-experience",
    name: "BackOffice/Admin/Profile_Admin/ProfExp",
  },
  {
    path: "/admin/profile/teaching-skills",
    name: "BackOffice/Admin/Profile_Admin/TeachingSkills",
  },
  {
    path: "/admin/profile/additional-qualifications",
    name: "BackOffice/Admin/Profile_Admin/AdditionalQualifi",
  },
  {
    path: "/admin/profile/awards-and-honors",
    name: "BackOffice/Admin/Profile_Admin/AwardsandHonors",
  },
  {
    path: "/admin/profile/languages",
    name: "BackOffice/Admin/Profile_Admin/Languages",
  },
  {
    path: "/admin/profile/notes",
    name: "BackOffice/Admin/Profile_Admin/Notes",
  },
  {
    path: "/admin/profile/publication",
    name: "BackOffice/Admin/Profile_Admin/Publication",
  },
  {
    path: "/admin/profile/reviews",
    name: "BackOffice/Admin/Profile_Admin/Reviews",
  },
  {
    path: "/admin/profile/seo-keywords",
    name: "BackOffice/Admin/Profile_Admin/SEOKeywords",
  },
  {
    path: "/admin/profile/teaching-philosophy",
    name: "BackOffice/Admin/Profile_Admin/TeachingPhilosophy",
  },
  {
    path: "/admin/profile/tests-taken",
    name: "BackOffice/Admin/Profile_Admin/TestTaken",
  },
  {
    path: "/admin/profile/track-record",
    name: "BackOffice/Admin/Profile_Admin/TrackRecord",
  },
  {
    path: "/admin/profile/subject-expertise",
    name: "BackOffice/Admin/Profile_Admin/SubjectExpertise",
  },
  {
    path: "/admin/live-meeting/new-meeting",
    name: "BackOffice/Admin/LiveMeeting/NewMeeting",
  },
  {
    path: "/admin/live-meeting/settings",
    name: "BackOffice/Admin/LiveMeeting/Settings",
  },
  {
    path: "/admin/messages/inbox",
    name: "BackOffice/Admin/Messages_Admin/Inbox",
  },
  {
    path: "/admin/messages/create-message",
    name: "BackOffice/Admin/Messages_Admin/CreateMessage",
  },
  {
    path: "/admin/messages/drafts",
    name: "BackOffice/Admin/Messages_Admin/Drafts",
  },
  {
    path: "/admin/messages/message-settings",
    name: "BackOffice/Admin/Messages_Admin/Message_Settings",
  },
  {
    path: "/admin/schedule/my-schedule",
    name: "BackOffice/Admin/Schedule/MySchedule",
  },
  {
    path: "/admin/registration/user-registration",
    name: "BackOffice/Admin/Registration/UserRegistration",
  },
  {
    path: "/admin/registration/add-new-user",
    name: "BackOffice/Admin/Registration/AddNewUser",
  },
  {
    path: "/admin/profile-student/personal-info",
    name: "BackOffice/Admin/ProfileStudent/PersonalInfo",
  },
  {
    path: "/admin/profile-student/introduction",
    name: "BackOffice/Admin/ProfileStudent/Introduction",
  },
  {
    path: "/admin/profile-student/education",
    name: "BackOffice/Admin/ProfileStudent/Education",
  },
  {
    path: "/admin/profile-student/professional-experience",
    name: "BackOffice/Admin/ProfileStudent/ProfExp",
  },
  {
    path: "/admin/profile-student/skills",
    name: "BackOffice/Admin/ProfileStudent/Skills",
  },
  {
    path: "/admin/profile-student/additional-qualifications",
    name: "BackOffice/Admin/ProfileStudent/AdditionalQualifi",
  },
  {
    path: "/admin/profile-student/awards-and-honors",
    name: "BackOffice/Admin/ProfileStudent/AwardsandHonors",
  },
  {
    path: "/admin/profile-student/languages",
    name: "BackOffice/Admin/ProfileStudent/Languages",
  },
  {
    path: "/admin/profile-student/notes",
    name: "BackOffice/Admin/ProfileStudent/Notes",
  },
  {
    path: "/admin/profile-student/publication",
    name: "BackOffice/Admin/ProfileStudent/Publication",
  },
  {
    path: "/admin/profile-student/reviews",
    name: "BackOffice/Admin/ProfileStudent/Reviews",
  },
  {
    path: "/admin/profile-student/seo-keywords",
    name: "BackOffice/Admin/ProfileStudent/SEOKeywords",
  },
  {
    path: "/admin/profile-student/teaching-philosophy",
    name: "BackOffice/Admin/ProfileStudent/TeachingPhilosophy",
  },
  {
    path: "/admin/profile-student/tests-taken",
    name: "BackOffice/Admin/ProfileStudent/TestTaken",
  },
  {
    path: "/admin/profile-student/track-record",
    name: "BackOffice/Admin/ProfileStudent/TrackRecord",
  },
  {
    path: "/admin/profile-student/subject-expertise",
    name: "BackOffice/Admin/ProfileStudent/SubjectExpertise",
  },
  {
    path: "/admin/profile-parent/personal-info",
    name: "BackOffice/Admin/ProfileParent/PersonalInfo",
  },
  {
    path: "/admin/profile-parent/education",
    name: "BackOffice/Admin/ProfileParent/Education",
  },
  {
    path: "/admin/profile-parent/languages",
    name: "BackOffice/Admin/ProfileParent/Languages",
  },
  {
    path: "/admin/profile-parent/notes",
    name: "BackOffice/Admin/ProfileParent/Notes",
  },
  {
    path: "/admin/profile-parent/target-countries",
    name: "BackOffice/Admin/ProfileParent/TargetCountries",
  },
  {
    path: "/admin/profile-parent/target-schools",
    name: "BackOffice/Admin/ProfileParent/TargetSchools",
  },
  {
    path: "/admin/profile-parent/target-programs",
    name: "BackOffice/Admin/ProfileParent/TargetPrograms",
  },
  {
    path: "/admin/profile-parent/financial-aid",
    name: "BackOffice/Admin/ProfileParent/FinancialAid",
  },
  {
    path: "/admin/profile-parent/scholarship-requests",
    name: "BackOffice/Admin/ProfileParent/ScholarshipRequests",
  },
  {
    path: "/admin/profile-instructor/personal-info",
    name: "BackOffice/Admin/ProfileInstructor/PersonalInfo",
  },
  {
    path: "/admin/profile-instructor/introduction",
    name: "BackOffice/Admin/ProfileInstructor/Introduction",
  },
  {
    path: "/admin/profile-instructor/education",
    name: "BackOffice/Admin/ProfileInstructor/Education",
  },
  {
    path: "/admin/profile-instructor/professional-experience",
    name: "BackOffice/Admin/ProfileInstructor/ProfExp",
  },
  {
    path: "/admin/profile-instructor/teaching-skills",
    name: "BackOffice/Admin/ProfileInstructor/TeachingSkills",
  },
  {
    path: "/admin/profile-instructor/additional-qualifications",
    name: "BackOffice/Admin/ProfileInstructor/AdditionalQualifi",
  },
  {
    path: "/admin/profile-instructor/awards-and-honors",
    name: "BackOffice/Admin/ProfileInstructor/AwardsandHonors",
  },
  {
    path: "/admin/profile-instructor/languages",
    name: "BackOffice/Admin/ProfileInstructor/Languages",
  },
  {
    path: "/admin/profile-instructor/notes",
    name: "BackOffice/Admin/ProfileInstructor/Notes",
  },
  {
    path: "/admin/profile-instructor/publication",
    name: "BackOffice/Admin/ProfileInstructor/Publication",
  },
  {
    path: "/admin/profile-instructor/reviews",
    name: "BackOffice/Admin/ProfileInstructor/Reviews",
  },
  {
    path: "/admin/profile-instructor/seo-keywords",
    name: "BackOffice/Admin/ProfileInstructor/SEOKeywords",
  },
  {
    path: "/admin/profile-instructor/teaching-philosophy",
    name: "BackOffice/Admin/ProfileInstructor/TeachingPhilosophy",
  },
  {
    path: "/admin/profile-instructor/tests-taken",
    name: "BackOffice/Admin/ProfileInstructor/TestTaken",
  },
  {
    path: "/admin/profile-instructor/track-record",
    name: "BackOffice/Admin/ProfileInstructor/TrackRecord",
  },
  {
    path: "/admin/profile-instructor/subject-expertise",
    name: "BackOffice/Admin/ProfileInstructor/SubjectExpertise",
  },
  {
    path: "/admin/profile-instructor/past-placements",
    name: "BackOffice/Admin/ProfileInstructor/PastPlacements",
  },
  {
    path: "/admin/profile-coach/personal-info",
    name: "BackOffice/Admin/ProfileCoach/PersonalInfo",
  },
  {
    path: "/admin/profile-coach/introduction",
    name: "BackOffice/Admin/ProfileCoach/Introduction",
  },
  {
    path: "/admin/profile-coach/education",
    name: "BackOffice/Admin/ProfileCoach/Education",
  },
  {
    path: "/admin/profile-coach/professional-experience",
    name: "BackOffice/Admin/ProfileCoach/ProfExp",
  },
  {
    path: "/admin/profile-coach/skills",
    name: "BackOffice/Admin/ProfileCoach/Skills",
  },
  {
    path: "/admin/profile-coach/additional-qualifications",
    name: "BackOffice/Admin/ProfileCoach/AdditionalQualifi",
  },
  {
    path: "/admin/profile-coach/awards-and-honors",
    name: "BackOffice/Admin/ProfileCoach/AwardsandHonors",
  },
  {
    path: "/admin/profile-coach/languages",
    name: "BackOffice/Admin/ProfileCoach/Languages",
  },
  {
    path: "/admin/profile-coach/notes",
    name: "BackOffice/Admin/ProfileCoach/Notes",
  },
  {
    path: "/admin/profile-coach/publication",
    name: "BackOffice/Admin/ProfileCoach/Publication",
  },
  {
    path: "/admin/profile-coach/tests-taken",
    name: "BackOffice/Admin/ProfileCoach/TestTaken",
  },
  {
    path: "/admin/profile-coach/coaching-philosophy",
    name: "BackOffice/Admin/ProfileCoach/CoachingPhilosophy",
  },
  {
    path: "/admin/profile-coach/track-record",
    name: "BackOffice/Admin/ProfileCoach/TrackRecord",
  },
  {
    path: "/admin/profile-coach/seo-keywords",
    name: "BackOffice/Admin/ProfileCoach/SEOKeywords",
  },
  {
    path: "/admin/profile-coach/past-placements",
    name: "BackOffice/Admin/ProfileCoach/PastPlacements",
  },
  {
    path: "/admin/profile-coach/reviews",
    name: "BackOffice/Admin/ProfileCoach/Reviews",
  },
  {
    path: "/admin/profile-consultant/personal-info",
    name: "BackOffice/Admin/ProfileConsultant/PersonalInfo",
  },
  {
    path: "/admin/profile-consultant/introduction",
    name: "BackOffice/Admin/ProfileConsultant/Introduction",
  },
  {
    path: "/admin/profile-consultant/education",
    name: "BackOffice/Admin/ProfileConsultant/Education",
  },
  {
    path: "/admin/profile-consultant/professional-experience",
    name: "BackOffice/Admin/ProfileConsultant/ProfExp",
  },
  {
    path: "/admin/profile-consultant/skills",
    name: "BackOffice/Admin/ProfileConsultant/Skills",
  },
  {
    path: "/admin/profile-consultant/additional-qualifications",
    name: "BackOffice/Admin/ProfileConsultant/AdditionalQualifi",
  },
  {
    path: "/admin/profile-consultant/awards-and-honors",
    name: "BackOffice/Admin/ProfileConsultant/AwardsandHonors",
  },
  {
    path: "/admin/profile-consultant/languages",
    name: "BackOffice/Admin/ProfileConsultant/Languages",
  },
  {
    path: "/admin/profile-consultant/notes",
    name: "BackOffice/Admin/ProfileConsultant/Notes",
  },
  {
    path: "/admin/profile-consultant/publication",
    name: "BackOffice/Admin/ProfileConsultant/Publication",
  },
  {
    path: "/admin/profile-consultant/reviews",
    name: "BackOffice/Admin/ProfileConsultant/Reviews",
  },
  {
    path: "/admin/profile-consultant/seo-keywords",
    name: "BackOffice/Admin/ProfileConsultant/SEOKeywords",
  },
  {
    path: "/admin/profile-consultant/consulting-philosophy",
    name: "BackOffice/Admin/ProfileConsultant/ConsultingPhilosophy",
  },
  {
    path: "/admin/profile-consultant/tests-taken",
    name: "BackOffice/Admin/ProfileConsultant/TestTaken",
  },
  {
    path: "/admin/profile-consultant/track-record",
    name: "BackOffice/Admin/ProfileConsultant/TrackRecord",
  },
  {
    path: "/admin/profile-consultant/subject-expertise",
    name: "BackOffice/Admin/ProfileConsultant/SubjectExpertise",
  },
  {
    path: "/admin/profile-consultant/past-placements",
    name: "BackOffice/Admin/ProfileConsultant/PastPlacements",
  },
  {
    path: "/admin/select-user",
    name: "BackOffice/Admin/SelectUser",
  },
  {
    path: "/admin/receivables/overview-of-parents",
    name: "BackOffice/Admin/Receivables/OverviewofParents",
  },
  {
    path: "/admin/receivables/coin-balance",
    name: "BackOffice/Admin/Receivables/CoinBalance",
  },
  {
    path: "/admin/receivables/payments-made",
    name: "BackOffice/Admin/Receivables/PaymentsMade",
  },
  {
    path: "/admin/receivables/settings",
    name: "BackOffice/Admin/Receivables/ReceivablesSettings",
  },
  {
    path: "/admin/receivables/invoices",
    name: "BackOffice/Admin/Receivables/Invoices",
  },
  {
    path: "/admin/receivables/select-user",
    name: "BackOffice/Admin/Receivables/ReceivablesSelectUser",
  },
  {
    path: "/admin/payables/overview-of-coworkers",
    name: "BackOffice/Admin/Payables/OverviewofCoworkers",
  },
  {
    path: "/admin/payables/select-user",
    name: "BackOffice/Admin/Payables/PayablesSelectUser",
  },
  {
    path: "/admin/payables/financials/settings",
    name: "BackOffice/Admin/Payables/Financials/Settings",
  },
  {
    path: "/admin/payables/financials/coin-balance",
    name: "BackOffice/Admin/Payables/Financials/CoinBalance",
  },
  {
    path: "/admin/payables/financials/bank-payments-received",
    name: "BackOffice/Admin/Payables/Financials/BankPaymentsReceived",
  },
  {
    path: "/admin/payables/financials/performance",
    name: "BackOffice/Admin/Payables/Financials/Performance",
  },
  {
    path: "/admin/payables/financials/send-payroll",
    name: "BackOffice/Admin/Payables/Financials/SendPayroll",
  },
  {
    path: "/admin/payables/financials/tax-forms",
    name: "BackOffice/Admin/Payables/Financials/TaxForms",
  },
  {
    path: "/admin/website-statistics/active-students",
    name: "BackOffice/Admin/WebsiteStatistics/ActiveStudents",
  },
  {
    path: "/admin/website-statistics/course-review-stars",
    name: "BackOffice/Admin/WebsiteStatistics/CourseReviewStars",
  },
  {
    path: "/admin/website-statistics/grade-increase-on-average",
    name: "BackOffice/Admin/WebsiteStatistics/GradeIncreaseonAverage",
  },
  {
    path: "/admin/website-statistics/instructor-reports",
    name: "BackOffice/Admin/WebsiteStatistics/InstructorReports",
  },
  {
    path: "/admin/website-statistics/lesson-reviews",
    name: "BackOffice/Admin/WebsiteStatistics/LessonReviews",
  },
  {
    path: "/admin/website-statistics/new-registrations",
    name: "BackOffice/Admin/WebsiteStatistics/NewRegistrations",
  },
  {
    path: "/admin/website-statistics/number-of-coworkers",
    name: "BackOffice/Admin/WebsiteStatistics/NumberofCoWorkers",
  },
  {
    path: "/admin/website-statistics/number-of-exams",
    name: "BackOffice/Admin/WebsiteStatistics/NumberofExams",
  },
  {
    path: "/admin/website-statistics/number-of-hours-of-service",
    name: "BackOffice/Admin/WebsiteStatistics/NumberofHoursofService",
  },
  {
    path: "/admin/website-statistics/number-of-lessons",
    name: "BackOffice/Admin/WebsiteStatistics/NumberofLessons",
  },
  {
    path: "/admin/website-statistics/overall-satisfaction",
    name: "BackOffice/Admin/WebsiteStatistics/OverallSatisfaction",
  },
  {
    path: "/admin/website-statistics/profits",
    name: "BackOffice/Admin/WebsiteStatistics/Profits",
  },
  {
    path: "/admin/website-statistics/revenues",
    name: "BackOffice/Admin/WebsiteStatistics/Revenues",
  },
  {
    path: "/admin/website-statistics/score-increase",
    name: "BackOffice/Admin/WebsiteStatistics/ScoreIncrease",
  },
  {
    path: "/admin/manage-student/overview-of-my-students",
    name: "BackOffice/Admin/ManageStudent/OverviewofStudents",
  },
  {
    path: "/admin/manage-student/select-user",
    name: "BackOffice/Admin/ManageStudent/SelectUser",
  },
  {
    path: "/admin/manage-student/calendar/view-student-calendar",
    name: "BackOffice/Admin/ManageStudent/CalenderStudent/ViewStudentCalender",
  },
  {
    path: "/admin/manage-student/complaints-and-tech-cases/complaints-history",
    name: "BackOffice/Admin/ManageStudent/ComplaintsandTechCases/ComplaintsHistory",
  },
  {
    path: "/admin/manage-student/complaints-and-tech-cases/view-complaints",
    name: "BackOffice/Admin/ManageStudent/ComplaintsandTechCases/ViewComplaints",
  },
  {
    path: "/admin/manage-student/complaints-and-tech-cases/tech-cases-history",
    name: "BackOffice/Admin/ManageStudent/ComplaintsandTechCases/TechCasesHistory",
  },
  {
    path: "/admin/manage-student/complaints-and-tech-cases/view-tech-cases",
    name: "BackOffice/Admin/ManageStudent/ComplaintsandTechCases/ViewTechCases",
  },
  {
    path: "/admin/manage-student/profile-student/education",
    name: "BackOffice/Admin/ManageStudent/ProfileStudent/Education",
  },
  {
    path: "/admin/manage-student/profile-student/future-courses",
    name: "BackOffice/Admin/ManageStudent/ProfileStudent/FutureCourses",
  },
  {
    path: "/admin/manage-student/profile-student/ongoing-courses",
    name: "BackOffice/Admin/ManageStudent/ProfileStudent/OngoingCourses",
  },
  {
    path: "/admin/manage-student/profile-student/personal-info",
    name: "BackOffice/Admin/ManageStudent/ProfileStudent/PersonalInfo",
  },
  {
    path: "/admin/manage-student/profile-student/target-grades",
    name: "BackOffice/Admin/ManageStudent/ProfileStudent/TargetGrades",
  },
  {
    path: "/admin/manage-student/student-performance/coach-overall-report",
    name: "BackOffice/Admin/ManageStudent/StudentPerformance/CoachOverallReport",
  },
  {
    path: "/admin/manage-student/student-performance/lesson-reports",
    name: "BackOffice/Admin/ManageStudent/StudentPerformance/LessonReports",
  },
  {
    path: "/admin/manage-student/student-performance/lesson-reviews",
    name: "BackOffice/Admin/ManageStudent/StudentPerformance/LessonReviews",
  },
  {
    path: "/admin/manage-student/student-performance/request-course-review",
    name: "BackOffice/Admin/ManageStudent/StudentPerformance/RequestCourseReview",
  },
  {
    path: "/admin/manage-student/student-performance/request-satisfaction-survey",
    name: "BackOffice/Admin/ManageStudent/StudentPerformance/RequestSatisfactionSurvey",
  },
  {
    path: "/admin/manage-student/student-performance/send-lesson-reminder",
    name: "BackOffice/Admin/ManageStudent/StudentPerformance/SendLessonReminder",
  },
  {
    path: "/admin/manage-student/student-performance/view-interactions",
    name: "BackOffice/Admin/ManageStudent/StudentPerformance/ViewInteractions",
  },

  {
    path: "/admin/manage-parent/overview-of-my-parents",
    name: "BackOffice/Admin/ManageParent/OverviewofParents",
  },
  {
    path: "/admin/manage-parent/select-user",
    name: "BackOffice/Admin/ManageParent/SelectUser",
  },
  {
    path: "/admin/manage-parent/calendar/view-parent-calendar",
    name: "BackOffice/Admin/ManageParent/Calendar/ViewParentCalendar",
  },
  {
    path: "/admin/manage-parent/complaints-and-tech-cases/complaints-history",
    name: "BackOffice/Admin/ManageParent/ComplaintsandTechCases/ComplaintsHistory",
  },
  {
    path: "/admin/manage-parent/complaints-and-tech-cases/view-complaints",
    name: "BackOffice/Admin/ManageParent/ComplaintsandTechCases/ViewComplaints",
  },
  {
    path: "/admin/manage-parent/complaints-and-tech-cases/tech-cases-history",
    name: "BackOffice/Admin/ManageParent/ComplaintsandTechCases/TechCasesHistory",
  },
  {
    path: "/admin/manage-parent/complaints-and-tech-cases/view-tech-cases",
    name: "BackOffice/Admin/ManageParent/ComplaintsandTechCases/ViewTechCases",
  },
  {
    path: "/admin/manage-parent/profile/education",
    name: "BackOffice/Admin/ManageParent/Profile/Education",
  },
  {
    path: "/admin/manage-parent/profile/personal-info",
    name: "BackOffice/Admin/ManageParent/Profile/PersonalInfo",
  },
  {
    path: "/admin/manage-parent/performance/coach-overall-report",
    name: "BackOffice/Admin/ManageParent/Performance/CoachOverallReport",
  },
  {
    path: "/admin/manage-parent/performance/lesson-reports",
    name: "BackOffice/Admin/ManageParent/Performance/LessonReports",
  },
  {
    path: "/admin/manage-parent/performance/view-interactions",
    name: "BackOffice/Admin/ManageParent/Performance/ViewInteractions",
  },
  {
    path: "/admin/manage-parent/reviews-and-satisfaction/request-course-review",
    name: "BackOffice/Admin/ManageParent/ReviewsandSatisfaction/RequestCourseReview",
  },
  {
    path: "/admin/manage-parent/reviews-and-satisfaction/request-satisfaction-survey",
    name: "BackOffice/Admin/ManageParent/ReviewsandSatisfaction/RequestSatisfactionSurvey",
  },
  {
    path: "/admin/manage-parent/reviews-and-satisfaction/reviews-and-ratings",
    name: "BackOffice/Admin/ManageParent/ReviewsandSatisfaction/ReviewsandRatings",
  },
  {
    path: "/admin/manage-parent/reviews-and-satisfaction/request-review",
    name: "BackOffice/Admin/ManageParent/ReviewsandSatisfaction/RequestReview",
  },
  {
    path: "/admin/manage-parent/financials/settings",
    name: "BackOffice/Admin/ManageParent/Financials/Settings",
  },
  {
    path: "/admin/manage-parent/financials/payments-made",
    name: "BackOffice/Admin/ManageParent/Financials/PaymentsMade",
  },
  {
    path: "/admin/manage-parent/financials/coin-balance",
    name: "BackOffice/Admin/ManageParent/Financials/CoinBalance",
  },
  {
    path: "/admin/manage-parent/financials/invoices",
    name: "BackOffice/Admin/ManageParent/Financials/Invoices",
  },

  //ManageCoach///////////////////////////////////////////////////////////
  {
    path: "/admin/manage-coach/assign-student",
    name: "BackOffice/Admin/ManageCoach/AssignStudent",
  },
  {
    path: "/admin/manage-coach/overview-of-my-coaches",
    name: "BackOffice/Admin/ManageCoach/OverviewofMyCoaches",
  },
  {
    path: "/admin/manage-coach/select-user",
    name: "BackOffice/Admin/ManageCoach/SelectUser",
  },
  {
    path: "/admin/manage-coach/profile/personal-info",
    name: "BackOffice/Admin/ManageCoach/Profile/PersonalInfo",
  },
  {
    path: "/admin/manage-coach/profile/introduction",
    name: "BackOffice/Admin/ManageCoach/Profile/Introduction",
  },
  {
    path: "/admin/manage-coach/profile/education",
    name: "BackOffice/Admin/ManageCoach/Profile/Education",
  },
  {
    path: "/admin/manage-coach/profile/professional-experience",
    name: "BackOffice/Admin/ManageCoach/Profile/ProfExp",
  },
  {
    path: "/admin/manage-coach/profile/skills",
    name: "BackOffice/Admin/ManageCoach/Profile/Skills",
  },
  {
    path: "/admin/manage-coach/profile/additional-qualifications",
    name: "BackOffice/Admin/ManageCoach/Profile/AdditionalQualifi",
  },
  {
    path: "/admin/manage-coach/profile/awards-and-honors",
    name: "BackOffice/Admin/ManageCoach/Profile/AwardsandHonors",
  },
  {
    path: "/admin/manage-coach/profile/languages",
    name: "BackOffice/Admin/ManageCoach/Profile/Languages",
  },
  {
    path: "/admin/manage-coach/profile/notes",
    name: "BackOffice/Admin/ManageCoach/Profile/Notes",
  },
  {
    path: "/admin/manage-coach/profile/publication",
    name: "BackOffice/Admin/ManageCoach/Profile/Publication",
  },
  {
    path: "/admin/manage-coach/profile/reviews",
    name: "BackOffice/Admin/ManageCoach/Profile/Reviews",
  },
  {
    path: "/admin/manage-coach/profile/seo-keywords",
    name: "BackOffice/Admin/ManageCoach/Profile/SEOKeywords",
  },
  {
    path: "/admin/manage-coach/profile/coaching-philosophy",
    name: "BackOffice/Admin/ManageCoach/Profile/CoachingPhilosophy",
  },
  {
    path: "/admin/manage-coach/profile/test-taken",
    name: "BackOffice/Admin/ManageCoach/Profile/TestTaken",
  },
  {
    path: "/admin/manage-coach/profile/track-record",
    name: "BackOffice/Admin/ManageCoach/Profile/TrackRecord",
  },
  {
    path: "/admin/manage-coach/profile/past-placements",
    name: "BackOffice/Admin/ManageCoach/Profile/PastPlacements",
  },
  {
    path: "/admin/manage-coach/calendar/view-coach-calendar",
    name: "BackOffice/Admin/ManageCoach/Calendar/ViewCoachCalendar",
  },
  {
    path: "/admin/manage-coach/performance/financial-performance",
    name: "BackOffice/Admin/ManageCoach/Performance/FinancialPerformance",
  },
  {
    path: "/admin/manage-coach/performance/missed-calls",
    name: "BackOffice/Admin/ManageCoach/Performance/MissedCalls",
  },
  {
    path: "/admin/manage-coach/performance/missed-calls-report",
    name: "BackOffice/Admin/ManageCoach/Performance/MissedCallsReport",
  },
  {
    path: "/admin/manage-coach/performance/registrations",
    name: "BackOffice/Admin/ManageCoach/Performance/Registrations",
  },
  {
    path: "/admin/manage-coach/performance/students-assigned",
    name: "BackOffice/Admin/ManageCoach/Performance/StudentsAssigned",
  },
  {
    path: "/admin/manage-coach/performance/view-interactions",
    name: "BackOffice/Admin/ManageCoach/Performance/ViewInteractions",
  },
  {
    path: "/admin/manage-coach/complaints/view-complaints",
    name: "BackOffice/Admin/ManageCoach/Complaints/ViewComplaints",
  },
  {
    path: "/admin/manage-coach/complaints/complaints-history",
    name: "BackOffice/Admin/ManageCoach/Complaints/ComplaintsHistory",
  },
  {
    path: "/admin/manage-coach/financials/financial-ratios",
    name: "BackOffice/Admin/ManageCoach/Financials/FinancialRatios",
  },
  {
    path: "/admin/manage-coach/manage-working-days-and-hours/phone-handling-schedule",
    name: "BackOffice/Admin/ManageCoach/ManageWorkingDaysandHours/PhoneHandlingSchedule",
  },
  {
    path: "/admin/manage-coach/reports/entire-coaching-period-review-received",
    name: "BackOffice/Admin/ManageCoach/ReportsandReviews/EntireCoachingPeriodReviewReceived",
  },
  {
    path: "/admin/manage-coach/reports/pending-coaching-reports",
    name: "BackOffice/Admin/ManageCoach/ReportsandReviews/PendingCoachingReports",
  },
  {
    path: "/admin/manage-coach/reports/previous-coaching-reports",
    name: "BackOffice/Admin/ManageCoach/ReportsandReviews/PreviousCoachingReports",
  },
  {
    path: "/admin/manage-coach/reports/reviews-on-coach-profile",
    name: "BackOffice/Admin/ManageCoach/ReportsandReviews/ReviewsonCoachProfile",
  },
  {
    path: "/admin/manage-coach/reports/satisfaction-survey",
    name: "BackOffice/Admin/ManageCoach/ReportsandReviews/SatisfactionSurvey",
  },
  {
    path: "/admin/manage-coach/reports/session-review-received",
    name: "BackOffice/Admin/ManageCoach/ReportsandReviews/SessionReviewReceived",
  },

  //ManageInstructor///////////////////////////////////////////////////////////
  {
    path: "/admin/manage-instructor/assign-student",
    name: "BackOffice/Admin/ManageInstructor/AssignStudent",
  },
  {
    path: "/admin/manage-instructor/overview-of-my-instructors",
    name: "BackOffice/Admin/ManageInstructor/OverviewofMyInstructors",
  },
  {
    path: "/admin/manage-instructor/select-user",
    name: "BackOffice/Admin/ManageInstructor/SelectUser",
  },
  {
    path: "/admin/manage-instructor/profile/personal-info",
    name: "BackOffice/Admin/ManageInstructor/Profile/PersonalInfo",
  },
  {
    path: "/admin/manage-instructor/profile/introduction",
    name: "BackOffice/Admin/ManageInstructor/Profile/Introduction",
  },
  {
    path: "/admin/manage-instructor/profile/education",
    name: "BackOffice/Admin/ManageInstructor/Profile/Education",
  },
  {
    path: "/admin/manage-instructor/profile/professional-experience",
    name: "BackOffice/Admin/ManageInstructor/Profile/ProfExp",
  },
  {
    path: "/admin/manage-instructor/profile/teaching-skills",
    name: "BackOffice/Admin/ManageInstructor/Profile/TeachingSkills",
  },
  {
    path: "/admin/manage-instructor/profile/additional-qualifications",
    name: "BackOffice/Admin/ManageInstructor/Profile/AdditionalQualifi",
  },
  {
    path: "/admin/manage-instructor/profile/awards-and-honors",
    name: "BackOffice/Admin/ManageInstructor/Profile/AwardsandHonors",
  },
  {
    path: "/admin/manage-instructor/profile/languages",
    name: "BackOffice/Admin/ManageInstructor/Profile/Languages",
  },
  {
    path: "/admin/manage-instructor/profile/notes",
    name: "BackOffice/Admin/ManageInstructor/Profile/Notes",
  },
  {
    path: "/admin/manage-instructor/profile/publication",
    name: "BackOffice/Admin/ManageInstructor/Profile/Publication",
  },
  {
    path: "/admin/manage-instructor/profile/reviews",
    name: "BackOffice/Admin/ManageInstructor/Profile/Reviews",
  },
  {
    path: "/admin/manage-instructor/profile/seo-keywords",
    name: "BackOffice/Admin/ManageInstructor/Profile/SEOKeywords",
  },
  {
    path: "/admin/manage-instructor/profile/teaching-philosophy",
    name: "BackOffice/Admin/ManageInstructor/Profile/TeachingPhilosophy",
  },
  {
    path: "/admin/manage-instructor/profile/test-taken",
    name: "BackOffice/Admin/ManageInstructor/Profile/TestTaken",
  },
  {
    path: "/admin/manage-instructor/profile/track-record",
    name: "BackOffice/Admin/ManageInstructor/Profile/TrackRecord",
  },
  {
    path: "/admin/manage-instructor/profile/past-placements",
    name: "BackOffice/Admin/ManageInstructor/Profile/PastPlacements",
  },
  {
    path: "/admin/manage-instructor/calendar/view-instructor-calendar",
    name: "BackOffice/Admin/ManageInstructor/Calender/ViewInstructorCalender",
  },
  {
    path: "/admin/manage-instructor/performance/financial-performance",
    name: "BackOffice/Admin/ManageInstructor/Performance/FinancialPerformance",
  },
  {
    path: "/admin/manage-instructor/performance/view-interactions",
    name: "BackOffice/Admin/ManageInstructor/Performance/ViewInteractions",
  },
  {
    path: "/admin/manage-instructor/complaints/open-complaints",
    name: "BackOffice/Admin/ManageInstructor/Complaints/ViewComplaints",
  },
  {
    path: "/admin/manage-instructor/complaints/complaints-history",
    name: "BackOffice/Admin/ManageInstructor/Complaints/ComplaintsHistory",
  },
  {
    path: "/admin/manage-instructor/financials/settings",
    name: "BackOffice/Admin/ManageInstructor/Financials/Settings",
  },
  {
    path: "/admin/manage-instructor/financials/coin-balance",
    name: "BackOffice/Admin/ManageInstructor/Financials/CoinBalance",
  },
  {
    path: "/admin/manage-instructor/financials/performance",
    name: "BackOffice/Admin/ManageInstructor/Financials/Performance",
  },
  {
    path: "/admin/manage-instructor/financials/tax-forms",
    name: "BackOffice/Admin/ManageInstructor/Financials/TaxForms",
  },
  {
    path: "/admin/manage-instructor/financials/send-payroll",
    name: "BackOffice/Admin/ManageInstructor/Financials/SendPayroll",
  },
  {
    path: "/admin/manage-instructor/financials/bank-payments-received",
    name: "BackOffice/Admin/ManageInstructor/Financials/BankPaymentsReceived",
  },
  {
    path: "/admin/manage-instructor/reports/course-reviews-received",
    name: "BackOffice/Admin/ManageInstructor/ReportsandReviews/CourseReviewsReceived",
  },
  {
    path: "/admin/manage-instructor/reports/pending-instructor-reports",
    name: "BackOffice/Admin/ManageInstructor/ReportsandReviews/PendingInstructorReports",
  },
  {
    path: "/admin/manage-instructor/reports/previous-instructor-reports",
    name: "BackOffice/Admin/ManageInstructor/ReportsandReviews/PreviousInstructorReports",
  },
  {
    path: "/admin/manage-instructor/reports/reviews-on-instructor-profile",
    name: "BackOffice/Admin/ManageInstructor/ReportsandReviews/ReviewsonInstructorProfile",
  },
  {
    path: "/admin/manage-instructor/reports/satisfaction-survey",
    name: "BackOffice/Admin/ManageInstructor/ReportsandReviews/SatisfactionSurvey",
  },
  {
    path: "/admin/manage-instructor/reports/lesson-review-received",
    name: "BackOffice/Admin/ManageInstructor/ReportsandReviews/LessonReviewsReceived",
  },
  {
    path: "/admin/manage-instructor/reports/recommendation-letter-request",
    name: "BackOffice/Admin/ManageInstructor/ReportsandReviews/RecommendationLetterRequest",
  },
  {
    path: "/admin/manage-instructor/settings/financial-ratios",
    name: "BackOffice/Admin/ManageInstructor/Settings/FinancialRatios",
  },
  {
    path: "/admin/manage-instructor/settings/webpage-privilages",
    name: "BackOffice/Admin/ManageInstructor/Settings/WebpagePrivilages",
  },
  {
    path: "/admin/manage-instructor/manage-subject-page/expectations",
    name: "BackOffice/Admin/ManageInstructor/ManageSubjectPage/Expectations",
  },
  {
    path: "/admin/manage-instructor/manage-subject-page/instructor-profiles",
    name: "BackOffice/Admin/ManageInstructor/ManageSubjectPage/InstructorProfiles",
  },
  {
    path: "/admin/manage-instructor/manage-subject-page/introduction",
    name: "BackOffice/Admin/ManageInstructor/ManageSubjectPage/Introduction",
  },
  {
    path: "/admin/manage-instructor/manage-subject-page/major-topics",
    name: "BackOffice/Admin/ManageInstructor/ManageSubjectPage/MajorTopics",
  },
  {
    path: "/admin/manage-instructor/manage-subject-page/testimonials-and-reviews",
    name: "BackOffice/Admin/ManageInstructor/ManageSubjectPage/TestimonialsandReviews",
  },
  {
    path: "/admin/manage-instructor/manage-subject-page/tips-and-tricks",
    name: "BackOffice/Admin/ManageInstructor/ManageSubjectPage/TipsandTricks",
  },
  {
    path: "/admin/manage-instructor/manage-subject-page/who-should-take-this-subject",
    name: "BackOffice/Admin/ManageInstructor/ManageSubjectPage/WhoShouldTakeThisSubject",
  },

  //ManageConsultant///////////////////////////////////////////////////////////
  {
    path: "/admin/manage-consultant/assign-student",
    name: "BackOffice/Admin/ManageConsultant/AssignStudent",
  },
  {
    path: "/admin/manage-consultant/overview-of-my-consultant",
    name: "BackOffice/Admin/ManageConsultant/OverviewofMyConsultant",
  },
  {
    path: "/admin/manage-consultant/select-user",
    name: "BackOffice/Admin/ManageConsultant/SelectUser",
  },
  {
    path: "/admin/manage-consultant/profile/personal-info",
    name: "BackOffice/Admin/ManageConsultant/Profile/PersonalInfo",
  },
  {
    path: "/admin/manage-consultant/profile/introduction",
    name: "BackOffice/Admin/ManageConsultant/Profile/Introduction",
  },
  {
    path: "/admin/manage-consultant/profile/education",
    name: "BackOffice/Admin/ManageConsultant/Profile/Education",
  },
  {
    path: "/admin/manage-consultant/profile/professional-experience",
    name: "BackOffice/Admin/ManageConsultant/Profile/ProfExp",
  },
  {
    path: "/admin/manage-consultant/profile/skills",
    name: "BackOffice/Admin/ManageConsultant/Profile/Skills",
  },
  {
    path: "/admin/manage-consultant/profile/additional-qualifications",
    name: "BackOffice/Admin/ManageConsultant/Profile/AdditionalQualifi",
  },
  {
    path: "/admin/manage-consultant/profile/awards-and-honors",
    name: "BackOffice/Admin/ManageConsultant/Profile/AwardsandHonors",
  },
  {
    path: "/admin/manage-consultant/profile/languages",
    name: "BackOffice/Admin/ManageConsultant/Profile/Languages",
  },
  {
    path: "/admin/manage-consultant/profile/notes",
    name: "BackOffice/Admin/ManageConsultant/Profile/Notes",
  },
  {
    path: "/admin/manage-consultant/profile/publication",
    name: "BackOffice/Admin/ManageConsultant/Profile/Publication",
  },
  {
    path: "/admin/manage-consultant/profile/reviews",
    name: "BackOffice/Admin/ManageConsultant/Profile/Reviews",
  },
  {
    path: "/admin/manage-consultant/profile/seo-keywords",
    name: "BackOffice/Admin/ManageConsultant/Profile/SEOKeywords",
  },
  {
    path: "/admin/manage-consultant/profile/consultant-philosophy",
    name: "BackOffice/Admin/ManageConsultant/Profile/ConsultantPhilosophy",
  },
  {
    path: "/admin/manage-consultant/profile/test-taken",
    name: "BackOffice/Admin/ManageConsultant/Profile/TestTaken",
  },
  {
    path: "/admin/manage-consultant/profile/track-record",
    name: "BackOffice/Admin/ManageConsultant/Profile/TrackRecord",
  },
  {
    path: "/admin/manage-consultant/profile/past-placements",
    name: "BackOffice/Admin/ManageConsultant/Profile/PastPlacements",
  },
  {
    path: "/admin/manage-consultant/calendar/view-consultant-calendar",
    name: "BackOffice/Admin/ManageConsultant/Calendar/ViewConsultantCalendar",
  },
  {
    path: "/admin/manage-consultant/performance/financial-performance",
    name: "BackOffice/Admin/ManageConsultant/Performance/FinancialPerformance",
  },
  {
    path: "/admin/manage-consultant/performance/view-interactions",
    name: "BackOffice/Admin/ManageConsultant/Performance/ViewInteractions",
  },
  {
    path: "/admin/manage-consultant/complaints/view-complaints",
    name: "BackOffice/Admin/ManageConsultant/Complaints/ViewComplaints",
  },
  {
    path: "/admin/manage-consultant/complaints/complaints-history",
    name: "BackOffice/Admin/ManageConsultant/Complaints/ComplaintsHistory",
  },
  {
    path: "/admin/manage-consultant/reports/entire-consultant-period-review-received",
    name: "BackOffice/Admin/ManageConsultant/ReportsandReviews/EntireConsultantPeriodReviewReceived",
  },
  {
    path: "/admin/manage-consultant/reports/pending-consulting-reports",
    name: "BackOffice/Admin/ManageConsultant/ReportsandReviews/PendingConsultantReports",
  },
  {
    path: "/admin/manage-consultant/reports/previous-consulting-reports",
    name: "BackOffice/Admin/ManageConsultant/ReportsandReviews/PreviousConsultantReports",
  },
  {
    path: "/admin/manage-consultant/reports/reviews-on-consultant-profile",
    name: "BackOffice/Admin/ManageConsultant/ReportsandReviews/ReviewsonConsultantProfile",
  },
  {
    path: "/admin/manage-consultant/reports/satisfaction-survey",
    name: "BackOffice/Admin/ManageConsultant/ReportsandReviews/SatisfactionSurvey",
  },
  {
    path: "/admin/manage-consultant/reports/session-review-received",
    name: "BackOffice/Admin/ManageConsultant/ReportsandReviews/SessionReviewReceived",
  },
  {
    path: "/admin/manage-consultant/settings/financial-ratios",
    name: "BackOffice/Admin/ManageConsultant/Settings/FinancialRatios",
  },
  {
    path: "/admin/manage-consultant/settings/webpage-privilages",
    name: "BackOffice/Admin/ManageConsultant/Settings/WebpagePrivilages",
  },

  //ManageSupervisor///////////////////////////////////////////////////////////
  {
    path: "/admin/manage-supervisor/assign-coach",
    name: "BackOffice/Admin/ManageSupervisor/AssignCoach",
  },
  {
    path: "/admin/manage-supervisor/overview-of-my-supervisors",
    name: "BackOffice/Admin/ManageSupervisor/OverviewofMySupervisors",
  },
  {
    path: "/admin/manage-supervisor/select-user",
    name: "BackOffice/Admin/ManageSupervisor/SelectUser",
  },
  {
    path: "/admin/manage-supervisor/manage-working-days-and-hours",
    name: "BackOffice/Admin/ManageSupervisor/ManageWorkingDaysandHours",
  },
  {
    path: "/admin/manage-supervisor/profile/personal-info",
    name: "BackOffice/Admin/ManageSupervisor/Profile/PersonalInfo",
  },
  {
    path: "/admin/manage-supervisor/profile/introduction",
    name: "BackOffice/Admin/ManageSupervisor/Profile/Introduction",
  },
  {
    path: "/admin/manage-supervisor/profile/education",
    name: "BackOffice/Admin/ManageSupervisor/Profile/Education",
  },
  {
    path: "/admin/manage-supervisor/profile/professional-experience",
    name: "BackOffice/Admin/ManageSupervisor/Profile/ProfExp",
  },
  {
    path: "/admin/manage-supervisor/profile/skills",
    name: "BackOffice/Admin/ManageSupervisor/Profile/Skills",
  },
  {
    path: "/admin/manage-supervisor/profile/additional-qualifications",
    name: "BackOffice/Admin/ManageSupervisor/Profile/AdditionalQualifi",
  },
  {
    path: "/admin/manage-supervisor/profile/awards-and-honors",
    name: "BackOffice/Admin/ManageSupervisor/Profile/AwardsandHonors",
  },
  {
    path: "/admin/manage-supervisor/profile/languages",
    name: "BackOffice/Admin/ManageSupervisor/Profile/Languages",
  },
  {
    path: "/admin/manage-supervisor/profile/notes",
    name: "BackOffice/Admin/ManageSupervisor/Profile/Notes",
  },
  {
    path: "/admin/manage-supervisor/profile/publication",
    name: "BackOffice/Admin/ManageSupervisor/Profile/Publication",
  },
  {
    path: "/admin/manage-supervisor/profile/tbu-satisfaction-survey",
    name: "BackOffice/Admin/ManageSupervisor/Profile/TBUSatisfactionSurvey",
  },
  {
    path: "/admin/manage-supervisor/profile/test-taken",
    name: "BackOffice/Admin/ManageSupervisor/Profile/TestTaken",
  },
  {
    path: "/admin/manage-supervisor/calendar/view-supervisor-calendar",
    name: "BackOffice/Admin/ManageSupervisor/Calendar/ViewSupervisorCalendar",
  },
  {
    path: "/admin/manage-supervisor/performance/financial-performance",
    name: "BackOffice/Admin/ManageSupervisor/Performance/FinancialPerformance",
  },
  {
    path: "/admin/manage-supervisor/supervisor-performance/coaches-assigned",
    name: "BackOffice/Admin/ManageSupervisor/SupervisorPerformance/CoachesAssigned",
  },
  {
    path: "/admin/manage-supervisor/supervisor-performance/financial-performance",
    name: "BackOffice/Admin/ManageSupervisor/SupervisorPerformance/FinancialPerformance",
  },
  {
    path: "/admin/manage-supervisor/supervisor-performance/missed-calls",
    name: "BackOffice/Admin/ManageSupervisor/SupervisorPerformance/MissedCalls",
  },
  {
    path: "/admin/manage-supervisor/supervisor-performance/missed-calls-report",
    name: "BackOffice/Admin/ManageSupervisor/SupervisorPerformance/MissedCallsReport",
  },
  {
    path: "/admin/manage-supervisor/supervisor-performance/registrations",
    name: "BackOffice/Admin/ManageSupervisor/SupervisorPerformance/Registrations",
  },
  {
    path: "/admin/manage-supervisor/supervisor-performance/view-interactions",
    name: "BackOffice/Admin/ManageSupervisor/SupervisorPerformance/ViewInteractions",
  },
  {
    path: "/admin/manage-supervisor/complaints/view-complaints",
    name: "BackOffice/Admin/ManageSupervisor/Complaints/ViewComplaints",
  },
  {
    path: "/admin/manage-supervisor/complaints/complaints-history",
    name: "BackOffice/Admin/ManageSupervisor/Complaints/ComplaintsHistory",
  },
  {
    path: "/admin/manage-supervisor/reports/satisfaction-survey-results",
    name: "BackOffice/Admin/ManageSupervisor/ReportsandReviews/SatisfactionSurveyResults",
  },
  {
    path: "/admin/manage-supervisor/reports/pending-reports",
    name: "BackOffice/Admin/ManageSupervisor/ReportsandReviews/PendingReports",
  },
  {
    path: "/admin/manage-supervisor/reports/previous-reports",
    name: "BackOffice/Admin/ManageSupervisor/ReportsandReviews/PreviousReports",
  },
  {
    path: "/admin/manage-supervisor/reports/request-satisfaction-survey",
    name: "BackOffice/Admin/ManageSupervisor/ReportsandReviews/RequestSatisfactionSurvey",
  },
  {
    path: "/admin/manage-supervisor/reports/satisfaction-survey",
    name: "BackOffice/Admin/ManageSupervisor/ReportsandReviews/SatisfactionSurvey",
  },
  {
    path: "/admin/manage-supervisor/settings/financial-ratios",
    name: "BackOffice/Admin/ManageSupervisor/Settings/FinancialRatios",
  },
  {
    path: "/admin/manage-supervisor/settings/webpage-privilages",
    name: "BackOffice/Admin/ManageSupervisor/Settings/WebpagePrivilages",
  },
  {
    path: "/admin/student-progress-report-card/grades-and-scores/ap",
    name: "BackOffice/Admin/StudentProgressReportCard/GradesandScores/AP",
  },
  {
    path: "/admin/student-progress-report-card/grades-and-scores/toefl",
    name: "BackOffice/Admin/StudentProgressReportCard/GradesandScores/TOEFL",
  },
  {
    path: "/admin/student-progress-report-card/grades-and-scores/mathematics",
    name: "BackOffice/Admin/StudentProgressReportCard/GradesandScores/Mathematics",
  },
  {
    path: "/admin/student-progress-report-card/grades-and-scores/microeconomics",
    name: "BackOffice/Admin/StudentProgressReportCard/GradesandScores/Microeconomics",
  },
  {
    path: "/admin/student-progress-report-card/new-actual-score",
    name: "BackOffice/Admin/StudentProgressReportCard/NewActualScore",
  },
  {
    path: "/admin/student-progress-report-card/new-predicted-score",
    name: "BackOffice/Admin/StudentProgressReportCard/NewPredictedScore",
  },
  {
    path: "/admin/student-progress-report-card/overview-of-my-instructors",
    name: "BackOffice/Admin/StudentProgressReportCard/OverviewofMyInstructors",
  },
  {
    path: "/admin/student-progress-report-card/select-student",
    name: "BackOffice/Admin/StudentProgressReportCard/SelectStudent",
  },
  {
    path: "/admin/financials-tbu/balance-sheet",
    name: "BackOffice/Admin/FinancialsTBU/BalanceSheet",
  },
  {
    path: "/admin/financials-tbu/cashflow-statement",
    name: "BackOffice/Admin/FinancialsTBU/CashFlowStatement",
  },
  {
    path: "/admin/financials-tbu/income-statement",
    name: "BackOffice/Admin/FinancialsTBU/IncomeStatement",
  },
  {
    path: "/admin/financials-tbu/payments-received",
    name: "BackOffice/Admin/FinancialsTBU/PaymentsReceived",
  },
  {
    path: "/admin/financials-tbu/payroll",
    name: "BackOffice/Admin/FinancialsTBU/Payroll",
  },
  {
    path: "/admin/financials-tbu/performance",
    name: "BackOffice/Admin/FinancialsTBU/Performance",
  },
  {
    path: "/admin/financials-tbu/settings",
    name: "BackOffice/Admin/FinancialsTBU/Settings",
  },
  {
    path: "/admin/financials-tbu/tax-filings",
    name: "BackOffice/Admin/FinancialsTBU/TaxFilings",
  },
  {
    path: "/admin/manage-frontend/home",
    name: "BackOffice/Admin/ManageFrontend/LandPage",
  },
  {
    path: "/admin/manage-frontend/home/FAQ",
    name: "BackOffice/Admin/ManageFrontend/FAQ",
  },
  {
    path: "/admin/manage-frontend/home/about-us/about-us",
    name: "BackOffice/Admin/ManageFrontend/MainNav/AboutUs",
  },
  {
    path: "/admin/manage-frontend/home/about-us/mission",
    name: "BackOffice/Admin/ManageFrontend/MainNav/Mission",
  },
  {
    path: "/admin/manage-frontend/home/about-us/vision",
    name: "BackOffice/Admin/ManageFrontend/MainNav/Vision",
  },
  {
    path: "/admin/manage-frontend/home/about-us/honor-code",
    name: "BackOffice/Admin/ManageFrontend/MainNav/HonorCode",
  },
  {
    path: "/admin/manage-frontend/home/about-us/reviews-and-testimonials",
    name: "BackOffice/Admin/ManageFrontend/MainNav/ReviewsandTestimonials",
  },
  {
    path: "/admin/manage-frontend/home/about-us/why-us",
    name: "BackOffice/Admin/ManageFrontend/MainNav/WhyUs",
  },
  {
    path: "/admin/manage-frontend/home/about-us/careers",
    name: "BackOffice/Admin/ManageFrontend/MainNav/Careers",
  },
  {
    path: "/admin/manage-frontend/home/about-us/locations",
    name: "BackOffice/Admin/ManageFrontend/MainNav/Locations",
  },
  {
    path: "/admin/manage-frontend/home/about-us/contact-us",
    name: "BackOffice/Admin/ManageFrontend/MainNav/ContactUs",
  },
  {
    path: "/admin/manage-frontend/home/about-us/tutoring-consulting-job",
    name: "BackOffice/Admin/ManageFrontend/MainNav/TutoringConsultingJob",
  },
  {
    path: "/admin/manage-frontend/home/about-us/our-system",
    name: "BackOffice/Admin/ManageFrontend/MainNav/OurSystem",
  },
  {
    path: "/admin/manage-frontend/home/private-tutoring/IB",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Tutoring/IB",
  },
  {
    path: "/admin/manage-frontend/home/private-tutoring/AP",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Tutoring/AP",
  },
  {
    path: "/admin/manage-frontend/home/private-tutoring/A-level",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Tutoring/ALevel",
  },
  {
    path: "/admin/manage-frontend/home/private-tutoring/AS-level",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Tutoring/ASLevel",
  },
  {
    path: "/admin/manage-frontend/home/private-tutoring/IGCSE",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Tutoring/IGCSE",
  },
  {
    path: "/admin/manage-frontend/home/test-preparation/SAT",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Testprep/SAT",
  },
  {
    path: "/admin/manage-frontend/home/test-preparation/ACT",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Testprep/ACT",
  },
  {
    path: "/admin/manage-frontend/home/test-preparation/TOEFL",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Testprep/TOEFL",
  },
  {
    path: "/admin/manage-frontend/home/test-preparation/IELTS",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Testprep/IELTS",
  },
  {
    path: "/admin/manage-frontend/home/consulting",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Consulting",
  },
  {
    path: "/admin/manage-frontend/home/test-preparation",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/TestPrep",
  },
  {
    path: "/admin/manage-frontend/home/private-tutoring",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Tutoring",
  },
  {
    path: "/admin/manage-frontend/home/private-tutoring/IB/economics",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Tutoring/IB/Economics",
  },
  {
    path: "/admin/manage-frontend/home/private-tutoring/IB/mathematics",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Tutoring/IB/Mathematics",
  },
  {
    path: "/admin/manage-frontend/home/private-tutoring/AP/microeconomics",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Tutoring/AP/Microeconomics",
  },
  {
    path: "/admin/manage-frontend/home/private-tutoring/AP/macroeconomics",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Tutoring/AP/Macroeconomics",
  },
  {
    path: "/admin/manage-frontend/home/private-tutoring/A-Level/economics",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Tutoring/ALevel/Economics",
  },
  {
    path: "/admin/manage-frontend/home/private-tutoring/AS-Level/economics",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Tutoring/ASLevel/Economics",
  },
  {
    path: "/admin/manage-frontend/home/private-tutoring/IGCSE/economics",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Tutoring/IGCSE/Economics",
  },
  {
    path: "/admin/manage-frontend/home/test-preparation/ACT/mathematics",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Testprep/ACT/Mathematics",
  },
  {
    path: "/admin/manage-frontend/home/consulting/academic-coaching",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Consulting/AcademicCoaching",
  },
  {
    path: "/admin/manage-frontend/home/consulting/education-consulting",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Consulting/EducationConsulting",
  },
  {
    path: "/admin/manage-frontend/home/consulting/college-counseling",
    name: "BackOffice/Admin/ManageFrontend/ServicesPages/Consulting/CollegeCounseling",
  },
  {
    path: "/admin/manage-frontend/footer",
    name: "BackOffice/Admin/ManageFrontend/Footer",
  },
  {
    path: "/admin/manage-frontend/settings",
    name: "BackOffice/Admin/ManageFrontend/Settings",
  },
  {
    path: "/admin/video-chat/phone-video-chat-calendar",
    name: "BackOffice/Admin/VideoChat/PhoneVideoChatCalender",
  },
  {
    path: "/admin/video-chat/select-user",
    name: "BackOffice/Admin/VideoChat/SelectUser",
  },
  {
    path: "/admin/video-chat/student-assigned",
    name: "BackOffice/Admin/VideoChat/StudentAssigned",
  },
  {
    path: "/admin/video-chat/phone-video-chat-handling",
    name: "BackOffice/Admin/VideoChat/PhoneVideoChatHandling",
  },
  {
    path: "/admin/video-chat/registrations",
    name: "BackOffice/Admin/VideoChat/Registrations",
  },
  {
    path: "/admin/video-chat/financial-performance",
    name: "BackOffice/Admin/VideoChat/FinancialPerformance",
  },
  {
    path: "/admin/video-chat/missed-calls",
    name: "BackOffice/Admin/VideoChat/MissedCalls",
  },
  {
    path: "/admin/video-chat/missed-calls-reports",
    name: "BackOffice/Admin/VideoChat/MissedCallsReports",
  },
  {
    path: "/admin/handle-complaints/open-complaints",
    name: "BackOffice/Admin/HandleComplaints/OpenComplaints",
  },
  {
    path: "/admin/handle-complaints/complaint-history",
    name: "BackOffice/Admin/HandleComplaints/ComplaintHistory",
  },
  {
    path: "/admin/handle-complaints/escalated-to-supervisor",
    name: "BackOffice/Admin/HandleComplaints/EscalatedToSupervisor",
  },
  {
    path: "/admin/handle-complaints/escalated-to-admin",
    name: "BackOffice/Admin/HandleComplaints/EscalatedToAdmin",
  },
  {
    path: "/admin/tech-support/cases-for-TBU/select",
    name: "BackOffice/Admin/TechSupport/CasesForTBU/Select",
  },
  {
    path: "/admin/tech-support/cases-for-TBU/performance",
    name: "BackOffice/Admin/TechSupport/CasesForTBU/Performance",
  },
  {
    path: "/admin/tech-support/cases-for-TBU/open-cases",
    name: "BackOffice/Admin/TechSupport/CasesForTBU/OpenCases",
  },
  {
    path: "/admin/tech-support/cases-for-TBU/closed-cases",
    name: "BackOffice/Admin/TechSupport/CasesForTBU/ClosedCases",
  },
  {
    path: "/admin/tech-support/cases-for-admin/closed-cases",
    name: "BackOffice/Admin/TechSupport/CasesForAdmin/ClosedCases",
  },
  {
    path: "/admin/tech-support/cases-for-admin/new-case",
    name: "BackOffice/Admin/TechSupport/CasesForAdmin/NewCase",
  },
  {
    path: "/admin/tech-support/cases-for-admin/existing-cases",
    name: "BackOffice/Admin/TechSupport/CasesForAdmin/ExistingCases",
  },
  {
    path: "/admin/tech-support/search-faq",
    name: "BackOffice/Admin/TechSupport/SearchFAQ",
  },
  {
    path: "/admin/tech-support/search-knowledge-base",
    name: "BackOffice/Admin/TechSupport/SearchKnowledgeBase",
  },
  {
    path: "/admin/application-package-content/application-package-content",
    name: "BackOffice/Admin/APC/APC",
  },
  {
    path: "/admin/application-package-content/education",
    name: "BackOffice/Admin/APC/Education",
  },
  {
    path: "/admin/application-package-content/recommendation-letters",
    name: "BackOffice/Admin/APC/RecommendationLetters",
  },
  {
    path: "/admin/application-package-content/test-scores",
    name: "BackOffice/Admin/APC/TestScores",
  },
  {
    path: "/admin/application-package-content/financial-docs",
    name: "BackOffice/Admin/APC/FinancialDocs",
  },
  {
    path: "/admin/application-package-content/financial-docs",
    name: "BackOffice/Admin/APC/FinancialDocs",
  },
  {
    path: "/admin/application-package-content/custom-elements/harvard",
    name: "BackOffice/Admin/APC/CustomElements/Harvard",
  },
  {
    path: "/admin/application-package-content/custom-elements/introduction",
    name: "BackOffice/Admin/APC/CustomElements/Introduction",
  },
  {
    path: "/admin/application-package-content/custom-elements/cv",
    name: "BackOffice/Admin/APC/CustomElements/CV",
  },
  {
    path: "/admin/application-package-content/custom-elements/essays/essay-1",
    name: "BackOffice/Admin/APC/CustomElements/Essays/Essay1",
  },
  {
    path: "/admin/application-package-content/custom-elements/essays/essay-2",
    name: "BackOffice/Admin/APC/CustomElements/Essays/Essay2",
  },
  {
    path: "/admin/application-package-content/custom-elements/scholarship-essays/essay-1",
    name: "BackOffice/Admin/APC/CustomElements/ScholarshipEssays/Essay1",
  },
  {
    path: "/admin/application-package-content/custom-elements/scholarship-essays/essay-2",
    name: "BackOffice/Admin/APC/CustomElements/ScholarshipEssays/Essay2",
  },

  {
    path: "/admin/application-package-content/custom-elements/misc-docs",
    name: "BackOffice/Admin/APC/CustomElements/MiscDocs",
  },
  {
    path: "/admin/application-package-content/custom-elements/diary",
    name: "BackOffice/Admin/APC/CustomElements/Diary",
  },
  {
    path: "/admin/application-package-content/custom-elements/notes-for-this-school/MIT",
    name: "BackOffice/Admin/APC/CustomElements/NotesForThisSchool/MIT",
  },
  {
    path: "/admin/application-package-content/custom-elements/notes-for-this-school/stanford",
    name: "BackOffice/Admin/APC/CustomElements/NotesForThisSchool/Stanford",
  },
  {
    path: "/admin/application-package-content/custom-elements/school-applications/applied",
    name: "BackOffice/Admin/APC/CustomElements/SchoolApplications/Applied",
  },
  {
    path: "/admin/application-package-content/custom-elements/school-applications/under-review",
    name: "BackOffice/Admin/APC/CustomElements/SchoolApplications/UnderReview",
  },
  {
    path: "/admin/application-package-content/custom-elements/school-applications/offers",
    name: "BackOffice/Admin/APC/CustomElements/SchoolApplications/Offers",
  },
  {
    path: "/admin/application-package-content/custom-elements/school-applications/placed",
    name: "BackOffice/Admin/APC/CustomElements/SchoolApplications/Placed",
  },
  {
    path: "/admin/application-package-content/custom-elements/school-applications/notes",
    name: "BackOffice/Admin/APC/CustomElements/SchoolApplications/Notes",
  },
  {
    path: "/admin/application-package-content/past-packages",
    name: "BackOffice/Admin/APC/PastPackages",
  },

  //BackOffice Admin routes endpoint ----------------------------------------------------------------------------------------------

  //BackOffice Instructors routes startpoint ----------------------------------------------------------------
  {
    path: "/instructor",
    name: "BackOffice/Instructor/Dashboard",
  },
  {
    path: "/instructor/profile/personal-info",
    name: "BackOffice/Instructor/Profile_Instructor/PersonalInfo",
  },
  {
    path: "/instructor/profile/introduction",
    name: "BackOffice/Instructor/Profile_Instructor/Introduction",
  },
  {
    path: "/instructor/profile/education",
    name: "BackOffice/Instructor/Profile_Instructor/Education",
  },
  {
    path: "/instructor/profile/professional-experience",
    name: "BackOffice/Instructor/Profile_Instructor/ProfExp",
  },
  {
    path: "/instructor/profile/teaching-skills",
    name: "BackOffice/Instructor/Profile_Instructor/TeachingSkills",
  },
  {
    path: "/instructor/profile/additional-qualifications",
    name: "BackOffice/Instructor/Profile_Instructor/AdditionalQualifi",
  },
  {
    path: "/instructor/profile/awards-and-honors",
    name: "BackOffice/Instructor/Profile_Instructor/AwardsandHonors",
  },
  {
    path: "/instructor/profile/languages",
    name: "BackOffice/Instructor/Profile_Instructor/Languages",
  },
  {
    path: "/instructor/profile/notes",
    name: "BackOffice/Instructor/Profile_Instructor/Notes",
  },
  {
    path: "/instructor/profile/publication",
    name: "BackOffice/Instructor/Profile_Instructor/Publication",
  },
  {
    path: "/instructor/profile/reviews",
    name: "BackOffice/Instructor/Profile_Instructor/Reviews",
  },
  {
    path: "/instructor/profile/seo-keywords",
    name: "BackOffice/Instructor/Profile_Instructor/SEOKeywords",
  },
  {
    path: "/instructor/profile/teaching-philosophy",
    name: "BackOffice/Instructor/Profile_Instructor/TeachingPhilosophy",
  },
  {
    path: "/instructor/profile/tests-taken",
    name: "BackOffice/Instructor/Profile_Instructor/TestTaken",
  },
  {
    path: "/instructor/profile/track-record",
    name: "BackOffice/Instructor/Profile_Instructor/TrackRecord",
  },
  {
    path: "/instructor/profile/subject-expertise",
    name: "BackOffice/Instructor/Profile_Instructor/SubjectExpertise",
  },
  {
    path: "/instructor/profile/past-placements",
    name: "BackOffice/Instructor/Profile_Instructor/PastPlacements",
  },
  {
    path: "/instructor/live-meeting/new-meeting",
    name: "BackOffice/Instructor/LiveMeeting/NewMeeting",
  },
  {
    path: "/instructor/live-meeting/settings",
    name: "BackOffice/Instructor/LiveMeeting/Settings",
  },
  {
    path: "/instructor/messages/inbox",
    name: "BackOffice/Instructor/Messages_Instructor/Inbox",
  },
  {
    path: "/instructor/messages/create-message",
    name: "BackOffice/Instructor/Messages_Instructor/CreateMessage",
  },
  {
    path: "/instructor/messages/drafts",
    name: "BackOffice/Instructor/Messages_Instructor/Drafts",
  },
  {
    path: "/instructor/messages/message-settings",
    name: "BackOffice/Instructor/Messages_Instructor/Message_Settings",
  },
  {
    path: "/instructor/schedule/my-schedule",
    name: "BackOffice/Instructor/Schedule/MySchedule",
  },
  {
    path: "/instructor/student-progress-report-card/grades-and-scores/ap",
    name: "BackOffice/Instructor/StudentProgressReportCard/GradesandScores/AP",
  },
  {
    path: "/instructor/student-progress-report-card/grades-and-scores/toefl",
    name: "BackOffice/Instructor/StudentProgressReportCard/GradesandScores/TOEFL",
  },
  {
    path: "/instructor/student-progress-report-card/grades-and-scores/mathematics",
    name: "BackOffice/Instructor/StudentProgressReportCard/GradesandScores/Mathematics",
  },
  {
    path: "/instructor/student-progress-report-card/grades-and-scores/microeconomics",
    name: "BackOffice/Instructor/StudentProgressReportCard/GradesandScores/Microeconomics",
  },
  {
    path: "/instructor/student-progress-report-card/new-actual-score",
    name: "BackOffice/Instructor/StudentProgressReportCard/NewActualScore",
  },
  {
    path: "/instructor/student-progress-report-card/new-predicted-score",
    name: "BackOffice/Instructor/StudentProgressReportCard/NewPredictedScore",
  },
  {
    path: "/instructor/student-progress-report-card/select-student",
    name: "BackOffice/Instructor/StudentProgressReportCard/SelectStudent",
  },
  {
    path: "/instructor/financials/coin-balance",
    name: "BackOffice/Instructor/Financials/CoinBalance",
  },
  {
    path: "/instructor/financials/payments-received",
    name: "BackOffice/Instructor/Financials/PaymentsReceived",
  },
  {
    path: "/instructor/financials/performance",
    name: "BackOffice/Instructor/Financials/Performance",
  },
  {
    path: "/instructor/financials/settings",
    name: "BackOffice/Instructor/Financials/Settings",
  },
  {
    path: "/instructor/financials/tax-filings",
    name: "BackOffice/Instructor/Financials/TaxFilings",
  },
  {
    path: "/instructor/complaints-and-suggestions/open-complaints",
    name: "BackOffice/Instructor/ComplaintsandSuggestions/OpenComplaints",
  },
  {
    path: "/instructor/complaints-and-suggestions/complaints-history",
    name: "BackOffice/Instructor/ComplaintsandSuggestions/ComplaintsHistory",
  },
  {
    path: "/instructor/complaints-and-suggestions/new-complaint",
    name: "BackOffice/Instructor/ComplaintsandSuggestions/NewComplaint",
  },
  {
    path: "/instructor/complaints-and-suggestions/new-suggestion",
    name: "BackOffice/Instructor/ComplaintsandSuggestions/NewSuggestion",
  },
  {
    path: "/instructor/tech-support/closed-cases",
    name: "BackOffice/Instructor/TechSupport/ClosedCases",
  },
  {
    path: "/instructor/tech-support/new-case",
    name: "BackOffice/Instructor/TechSupport/NewCase",
  },
  {
    path: "/instructor/tech-support/existing-cases",
    name: "BackOffice/Instructor/TechSupport/ExistingCases",
  },
  {
    path: "/instructor/tech-support/search-FAQ",
    name: "BackOffice/Instructor/TechSupport/SearchFAQ",
  },
  {
    path: "/instructor/tech-support/search-knowledge-base",
    name: "BackOffice/Instructor/TechSupport/SearchKnowledgeBase",
  },
  {
    path: "/instructor/manage-subject-page/expectations",
    name: "BackOffice/Instructor/ManageSubjectPage/Expectations",
  },
  {
    path: "/instructor/manage-subject-page/instructor-profiles",
    name: "BackOffice/Instructor/ManageSubjectPage/InstructorProfiles",
  },
  {
    path: "/instructor/manage-subject-page/introduction",
    name: "BackOffice/Instructor/ManageSubjectPage/Introduction",
  },
  {
    path: "/instructor/manage-subject-page/major-topics",
    name: "BackOffice/Instructor/ManageSubjectPage/MajorTopics",
  },
  {
    path: "/instructor/manage-subject-page/testimonials-and-reviews",
    name: "BackOffice/Instructor/ManageSubjectPage/TestimonialsandReviews",
  },
  {
    path: "/instructor/manage-subject-page/tips-and-tricks",
    name: "BackOffice/Instructor/ManageSubjectPage/TipsandTricks",
  },
  {
    path: "/instructor/manage-subject-page/who-should-take-this-subject",
    name: "BackOffice/Instructor/ManageSubjectPage/WhoShouldTakeThisSubject",
  },
  {
    path: "/instructor/reports/course-reviews-requests",
    name: "BackOffice/Instructor/ReportsandReviews/CourseReviewRequests",
  },
  {
    path: "/instructor/reports/pending-review-requests",
    name: "BackOffice/Instructor/ReportsandReviews/PendingReviewRequests",
  },
  {
    path: "/instructor/reports/previous-review-requests",
    name: "BackOffice/Instructor/ReportsandReviews/PreviousLessonReviews",
  },
  {
    path: "/instructor/reports/reviews-on-instructor-profile",
    name: "BackOffice/Instructor/ReportsandReviews/ReviewsonInstructorProfile",
  },
  {
    path: "/instructor/reports/satisfaction-survey",
    name: "BackOffice/Instructor/ReportsandReviews/SatisfactionSurvey",
  },
  {
    path: "/instructor/reports/lesson-reports-received",
    name: "BackOffice/Instructor/ReportsandReviews/LessonReportsReceived",
  },
  {
    path: "/instructor/reports/recommendation-letter-request",
    name: "BackOffice/Instructor/ReportsandReviews/RecommendationLetterRequest",
  },

  
  //BackOffice Instructor routes endpoint ----------------------------------------------------------------------------------------------


  //BackOffice Student routes startpoint ----------------------------------------------------------------
  {
    path: "/student",
    name: "BackOffice/Student/Dashboard",
  },
  {
    path: "/student/profile/personal-info",
    name: "BackOffice/Student/Profile_Student/PersonalInfo",
  },
  {
    path: "/student/profile/introduction",
    name: "BackOffice/Student/Profile_Student/Introduction",
  },
  {
    path: "/student/profile/education",
    name: "BackOffice/Student/Profile_Student/Education",
  },
  {
    path: "/student/profile/professional-experience",
    name: "BackOffice/Student/Profile_Student/ProfExp",
  },
  {
    path: "/student/profile/skills",
    name: "BackOffice/Student/Profile_Student/Skills",
  },
  {
    path: "/student/profile/additional-qualifications",
    name: "BackOffice/Student/Profile_Student/AdditionalQualifi",
  },
  {
    path: "/student/profile/awards-and-honors",
    name: "BackOffice/Student/Profile_Student/AwardsandHonors",
  },
  {
    path: "/student/profile/languages",
    name: "BackOffice/Student/Profile_Student/Languages",
  },
  {
    path: "/student/profile/notes",
    name: "BackOffice/Student/Profile_Student/Notes",
  },
  {
    path: "/student/profile/publication",
    name: "BackOffice/Student/Profile_Student/Publication",
  },
  {
    path: "/student/profile/reviews",
    name: "BackOffice/Student/Profile_Student/Reviews",
  },
  {
    path: "/student/profile/seo-keywords",
    name: "BackOffice/Student/Profile_Student/SEOKeywords",
  },
  {
    path: "/student/profile/teaching-philosophy",
    name: "BackOffice/Student/Profile_Student/TeachingPhilosophy",
  },
  {
    path: "/student/profile/tests-taken",
    name: "BackOffice/Student/Profile_Student/TestTaken",
  },
  {
    path: "/student/profile/track-record",
    name: "BackOffice/Student/Profile_Student/TrackRecord",
  },
  {
    path: "/student/profile/subject-expertise",
    name: "BackOffice/Student/Profile_Student/SubjectExpertise",
  },
  {
    path: "/student/live-meeting/new-meeting",
    name: "BackOffice/Student/LiveMeeting/NewMeeting",
  },
  {
    path: "/student/live-meeting/settings",
    name: "BackOffice/Student/LiveMeeting/Settings",
  },
  {
    path: "/student/messages/inbox",
    name: "BackOffice/Student/Messages_Student/Inbox",
  },
  {
    path: "/student/messages/create-message",
    name: "BackOffice/Student/Messages_Student/CreateMessage",
  },
  {
    path: "/student/messages/drafts",
    name: "BackOffice/Student/Messages_Student/Drafts",
  },
  {
    path: "/student/messages/message-settings",
    name: "BackOffice/Student/Messages_Student/Message_Settings",
  },
  {
    path: "/student/schedule/my-schedule",
    name: "BackOffice/Student/Schedule/MySchedule",
  },
  {
    path: "/student/student-progress-report-card/grades-and-scores/ap",
    name: "BackOffice/Student/StudentProgressReportCard/GradesandScores/AP",
  },
  {
    path: "/student/student-progress-report-card/grades-and-scores/toefl",
    name: "BackOffice/Student/StudentProgressReportCard/GradesandScores/TOEFL",
  },
  {
    path: "/student/student-progress-report-card/grades-and-scores/mathematics",
    name: "BackOffice/Student/StudentProgressReportCard/GradesandScores/Mathematics",
  },
  {
    path: "/student/student-progress-report-card/grades-and-scores/microeconomics",
    name: "BackOffice/Student/StudentProgressReportCard/GradesandScores/Microeconomics",
  },
  {
    path: "/student/student-progress-report-card/new-actual-score",
    name: "BackOffice/Student/StudentProgressReportCard/NewActualScore",
  },
  {
    path: "/student/student-progress-report-card/new-predicted-score",
    name: "BackOffice/Student/StudentProgressReportCard/NewPredictedScore",
  },
  {
    path: "/student/student-progress-report-card/select-student",
    name: "BackOffice/Student/StudentProgressReportCard/SelectStudent",
  },
  {
    path: "/student/financials/coin-balance",
    name: "BackOffice/Student/Financials/CoinBalance",
  },
  {
    path: "/student/complaints-and-suggestions/open-complaints",
    name: "BackOffice/Student/ComplaintsandSuggestions/OpenComplaints",
  },
  {
    path: "/student/complaints-and-suggestions/complaints-history",
    name: "BackOffice/Student/ComplaintsandSuggestions/ComplaintsHistory",
  },
  {
    path: "/student/complaints-and-suggestions/new-complaint",
    name: "BackOffice/Student/ComplaintsandSuggestions/NewComplaint",
  },
  {
    path: "/student/complaints-and-suggestions/new-suggestion",
    name: "BackOffice/Student/ComplaintsandSuggestions/NewSuggestion",
  },
  {
    path: "/student/tech-support/closed-cases",
    name: "BackOffice/Student/TechSupport/ClosedCases",
  },
  {
    path: "/student/tech-support/new-case",
    name: "BackOffice/Student/TechSupport/NewCase",
  },
  {
    path: "/student/tech-support/existing-cases",
    name: "BackOffice/Student/TechSupport/ExistingCases",
  },
  {
    path: "/student/tech-support/search-faq",
    name: "BackOffice/Student/TechSupport/SearchFAQ",
  },
  {
    path: "/student/tech-support/search-knowledge-base",
    name: "BackOffice/Student/TechSupport/SearchKnowledgeBase",
  },
  {
    path: "/student/reports/course-reviews-requests",
    name: "BackOffice/Student/ReportsandReviews/CourseReviewsRequests",
  },
  {
    path: "/student/reports/pending-review-requests",
    name: "BackOffice/Student/ReportsandReviews/PendingReviewRequests",
  },
  {
    path: "/student/reports/previous-lesson-reviews",
    name: "BackOffice/Student/ReportsandReviews/PreviousLessonReviews",
  },
  {
    path: "/student/reports/satisfaction-survey",
    name: "BackOffice/Student/ReportsandReviews/SatisfactionSurvey",
  },
  {
    path: "/student/reports/lesson-reports-received",
    name: "BackOffice/Student/ReportsandReviews/LessonReportsReceived",
  },
  {
    path: "/student/reports/recommendation-letter-request",
    name: "BackOffice/Student/ReportsandReviews/RecommendationLetterRequests",
  },

  
  //BackOffice Student routes endpoint ----------------------------------------------------------------------------------------------


  //BackOffice Parent routes startpoint ---------------------------------------------------------------------------------------------

  {
    path: "/parent",
    name: "BackOffice/Parent/Dashboard",
  },
  {
    path: "/parent/profile/personal-info",
    name: "BackOffice/Parent/Profile/PersonalInfo",
  },
  {
    path: "/parent/profile/education",
    name: "BackOffice/Parent/Profile/Education",
  },
  {
    path: "/parent/profile/languages",
    name: "BackOffice/Parent/Profile/Languages",
  },
  {
    path: "/parent/profile/notes",
    name: "BackOffice/Parent/Profile/Notes",
  },
  {
    path: "/parent/profile/target-countries",
    name: "BackOffice/Parent/Profile/TargetCountries",
  },
  {
    path: "/parent/profile/target-schools",
    name: "BackOffice/Parent/Profile/TargetSchools",
  },
  {
    path: "/parent/profile/target-programs",
    name: "BackOffice/Parent/Profile/TargetPrograms",
  },
  {
    path: "/parent/profile/financial-aid",
    name: "BackOffice/Parent/Profile/FinancialAid",
  },
  {
    path: "/parent/profile/scholarship-requests",
    name: "BackOffice/Parent/Profile/ScholarshipRequests",
  },
  {
    path: "/parent/live-meeting/new-meeting",
    name: "BackOffice/Parent/LiveMeeting/NewMeeting",
  },
  {
    path: "/parent/live-meeting/settings",
    name: "BackOffice/Parent/LiveMeeting/Settings",
  },
  {
    path: "/parent/messages/inbox",
    name: "BackOffice/Parent/Messages/Inbox",
  },
  {
    path: "/parent/messages/create-message",
    name: "BackOffice/Parent/Messages/CreateMessage",
  },
  {
    path: "/parent/messages/drafts",
    name: "BackOffice/Parent/Messages/Drafts",
  },
  {
    path: "/parent/messages/message-settings",
    name: "BackOffice/Parent/Messages/Message_Settings",
  },
  {
    path: "/parent/schedule/my-schedule",
    name: "BackOffice/Parent/Schedule/MySchedule",
  },
  {
    path: "/parent/student-progress-report-card/grades-and-scores/ap",
    name: "BackOffice/Parent/StudentProgressReportCard/GradesandScores/AP",
  },
  {
    path: "/parent/student-progress-report-card/grades-and-scores/toefl",
    name: "BackOffice/Parent/StudentProgressReportCard/GradesandScores/TOEFL",
  },
  {
    path: "/parent/student-progress-report-card/grades-and-scores/mathematics",
    name: "BackOffice/Parent/StudentProgressReportCard/GradesandScores/Mathematics",
  },
  {
    path: "/parent/student-progress-report-card/grades-and-scores/microeconomics",
    name: "BackOffice/Parent/StudentProgressReportCard/GradesandScores/Microeconomics",
  },
  {
    path: "/parent/student-progress-report-card/new-actual-score",
    name: "BackOffice/Parent/StudentProgressReportCard/NewActualScore",
  },
  {
    path: "/parent/student-progress-report-card/new-predicted-score",
    name: "BackOffice/Parent/StudentProgressReportCard/NewPredictedScore",
  },
  {
    path: "/parent/financials/coin-balance",
    name: "BackOffice/Parent/Financials/CoinBalance",
  },
  {
    path: "/parent/financials/invoices",
    name: "BackOffice/Parent/Financials/Invoices",
  },
  {
    path: "/parent/financials/settings",
    name: "BackOffice/Parent/Financials/Settings",
  },
  {
    path: "/parent/financials/payments-made",
    name: "BackOffice/Parent/Financials/PaymentsMade",
  },
  {
    path: "/parent/complaints-and-suggestions/open-complaints-from-parent",
    name: "BackOffice/Parent/ComplaintsandSuggestions/OpenComplaintsParent",
  },
  {
    path: "/parent/complaints-and-suggestions/open-complaints-from-student",
    name: "BackOffice/Parent/ComplaintsandSuggestions/OpenComplaintsStudent",
  },
  {
    path: "/parent/complaints-and-suggestions/complaints-history-of-the-parent",
    name: "BackOffice/Parent/ComplaintsandSuggestions/ComplaintsHistoryParent",
  },
  {
    path: "/parent/complaints-and-suggestions/complaints-history-of-the-student",
    name: "BackOffice/Parent/ComplaintsandSuggestions/ComplaintsHistoryStudent",
  },
  {
    path: "/parent/complaints-and-suggestions/new-complaint-by-parent",
    name: "BackOffice/Parent/ComplaintsandSuggestions/NewComplaint",
  },
  {
    path: "/parent/complaints-and-suggestions/new-suggestion-by-parent",
    name: "BackOffice/Parent/ComplaintsandSuggestions/NewSuggestion",
  },
  {
    path: "/parent/tech-support/closed-cases-from-parent",
    name: "BackOffice/Parent/TechSupport/ClosedCasesParent",
  },
  {
    path: "/parent/tech-support/closed-cases-from-student",
    name: "BackOffice/Parent/TechSupport/ClosedCasesStudent",
  },
  {
    path: "/parent/tech-support/new-case-from-parent",
    name: "BackOffice/Parent/TechSupport/NewCaseParent",
  },
  {
    path: "/parent/tech-support/existing-cases-from-parent",
    name: "BackOffice/Parent/TechSupport/ExistingCasesParent",
  },
  {
    path: "/parent/tech-support/existing-cases-from-student",
    name: "BackOffice/Parent/TechSupport/ExistingCasesStudent",
  },
  {
    path: "/parent/tech-support/search-faq",
    name: "BackOffice/Parent/TechSupport/SearchFAQ",
  },
  {
    path: "/parent/tech-support/search-knowledge-base",
    name: "BackOffice/Parent/TechSupport/SearchKnowledgeBase",
  },
  {
    path: "/parent/reports/course-reviews-requests",
    name: "BackOffice/Parent/ReportsandReviews/CourseReviewsRequests",
  },
  {
    path: "/parent/reports/pending-review-requests",
    name: "BackOffice/Parent/ReportsandReviews/PendingReviewRequests",
  },
  {
    path: "/parent/reports/previous-lesson-reviews",
    name: "BackOffice/Parent/ReportsandReviews/PreviousLessonReviews",
  },
  {
    path: "/parent/reports/satisfaction-survey",
    name: "BackOffice/Parent/ReportsandReviews/SatisfactionSurvey",
  },
  {
    path: "/parent/reports/lesson-reports-received",
    name: "BackOffice/Parent/ReportsandReviews/LessonReportsReceived",
  },
  {
    path: "/parent/reports/recommendation-letter-requests",
    name: "BackOffice/Parent/ReportsandReviews/RecommendationLetterRequests",
  },


  //Supervisor routes start -------------------------------------------------------------------------------------------

  {
    path: "/supervisor",
    name: "BackOffice/Supervisor/Dashboard",
  },
  {
    path: "/supervisor/profile/personal-info",
    name: "BackOffice/Supervisor/Profile/PersonalInfo",
  },
  {
    path: "/supervisor/profile/introduction",
    name: "BackOffice/Supervisor/Profile/Introduction",
  },
  {
    path: "/supervisor/profile/education",
    name: "BackOffice/Supervisor/Profile/Education",
  },
  {
    path: "/supervisor/profile/professional-experience",
    name: "BackOffice/Supervisor/Profile/ProfExp",
  },
  {
    path: "/supervisor/profile/skills",
    name: "BackOffice/Supervisor/Profile/Skills",
  },
  {
    path: "/supervisor/profile/additional-qualifications",
    name: "BackOffice/Supervisor/Profile/AdditionalQualifi",
  },
  {
    path: "/supervisor/profile/awards-and-honors",
    name: "BackOffice/Supervisor/Profile/AwardsandHonors",
  },
  {
    path: "/supervisor/profile/languages",
    name: "BackOffice/Supervisor/Profile/Languages",
  },
  {
    path: "/supervisor/profile/notes",
    name: "BackOffice/Supervisor/Profile/Notes",
  },
  {
    path: "/supervisor/profile/publication",
    name: "BackOffice/Supervisor/Profile/Publication",
  },
  {
    path: "/supervisor/profile/tests-taken",
    name: "BackOffice/Supervisor/Profile/TestTaken",
  },
  {
    path: "/supervisor/profile/tbu-satisfaction-survey-results",
    name: "BackOffice/Supervisor/Profile/TBUSatisSurveyResults",
  },
  {
    path: "/supervisor/live-meeting/new-meeting",
    name: "BackOffice/Supervisor/LiveMeeting/NewMeeting",
  },
  {
    path: "/supervisor/live-meeting/settings",
    name: "BackOffice/Supervisor/LiveMeeting/Settings",
  },
  {
    path: "/supervisor/messages/inbox",
    name: "BackOffice/Supervisor/Messages/Inbox",
  },
  {
    path: "/supervisor/messages/create-message",
    name: "BackOffice/Supervisor/Messages/CreateMessage",
  },
  {
    path: "/supervisor/messages/drafts",
    name: "BackOffice/Supervisor/Messages/Drafts",
  },
  {
    path: "/supervisor/messages/message-settings",
    name: "BackOffice/Supervisor/Messages/Message_Settings",
  },
  {
    path: "/supervisor/schedule/my-schedule",
    name: "BackOffice/Supervisor/Schedule/MySchedule",
  },
  {
    path: "/supervisor/select-user",
    name: "BackOffice/Supervisor/SelectUser",
  },
  {
    path: "/supervisor/registration/user-registration",
    name: "BackOffice/Supervisor/Registration/UserRegistration",
  },
  {
    path: "/supervisor/registration/add-new-user",
    name: "BackOffice/Supervisor/Registration/AddNewUser",
  },
  {
    path: "/supervisor/profile-student/personal-info",
    name: "BackOffice/Supervisor/ProfileStudent/PersonalInfo",
  },
  {
    path: "/supervisor/profile-student/introduction",
    name: "BackOffice/Supervisor/ProfileStudent/Introduction",
  },
  {
    path: "/supervisor/profile-student/education",
    name: "BackOffice/Supervisor/ProfileStudent/Education",
  },
  {
    path: "/supervisor/profile-student/professional-experience",
    name: "BackOffice/Supervisor/ProfileStudent/ProfExp",
  },
  {
    path: "/supervisor/profile-student/skills",
    name: "BackOffice/Supervisor/ProfileStudent/Skills",
  },
  {
    path: "/supervisor/profile-student/additional-qualifications",
    name: "BackOffice/Supervisor/ProfileStudent/AdditionalQualifi",
  },
  {
    path: "/supervisor/profile-student/awards-and-honors",
    name: "BackOffice/Supervisor/ProfileStudent/AwardsandHonors",
  },
  {
    path: "/supervisor/profile-student/languages",
    name: "BackOffice/Supervisor/ProfileStudent/Languages",
  },
  {
    path: "/supervisor/profile-student/notes",
    name: "BackOffice/Supervisor/ProfileStudent/Notes",
  },
  {
    path: "/supervisor/profile-student/publication",
    name: "BackOffice/Supervisor/ProfileStudent/Publication",
  },
  {
    path: "/supervisor/profile-student/reviews",
    name: "BackOffice/Supervisor/ProfileStudent/Reviews",
  },
  {
    path: "/supervisor/profile-student/seo-keywords",
    name: "BackOffice/Supervisor/ProfileStudent/SEOKeywords",
  },
  {
    path: "/supervisor/profile-student/teaching-philosophy",
    name: "BackOffice/Supervisor/ProfileStudent/TeachingPhilosophy",
  },
  {
    path: "/supervisor/profile-student/tests-taken",
    name: "BackOffice/Supervisor/ProfileStudent/TestTaken",
  },
  {
    path: "/supervisor/profile-student/track-record",
    name: "BackOffice/Supervisor/ProfileStudent/TrackRecord",
  },
  {
    path: "/supervisor/profile-student/subject-expertise",
    name: "BackOffice/Supervisor/ProfileStudent/SubjectExpertise",
  },
  {
    path: "/supervisor/profile-parent/personal-info",
    name: "BackOffice/Supervisor/ProfileParent/PersonalInfo",
  },
  {
    path: "/supervisor/profile-parent/education",
    name: "BackOffice/Supervisor/ProfileParent/Education",
  },
  {
    path: "/supervisor/profile-parent/languages",
    name: "BackOffice/Supervisor/ProfileParent/Languages",
  },
  {
    path: "/supervisor/profile-parent/notes",
    name: "BackOffice/Supervisor/ProfileParent/Notes",
  },
  {
    path: "/supervisor/profile-parent/target-countries",
    name: "BackOffice/Supervisor/ProfileParent/TargetCountries",
  },
  {
    path: "/supervisor/profile-parent/target-schools",
    name: "BackOffice/Supervisor/ProfileParent/TargetSchools",
  },
  {
    path: "/supervisor/profile-parent/target-programs",
    name: "BackOffice/Supervisor/ProfileParent/TargetPrograms",
  },
  {
    path: "/supervisor/profile-parent/financial-aid",
    name: "BackOffice/Supervisor/ProfileParent/FinancialAid",
  },
  {
    path: "/supervisor/profile-parent/scholarship-requests",
    name: "BackOffice/Supervisor/ProfileParent/ScholarshipRequests",
  },
  {
    path: "/supervisor/profile-instructor/personal-info",
    name: "BackOffice/Supervisor/ProfileInstructor/PersonalInfo",
  },
  {
    path: "/supervisor/profile-instructor/introduction",
    name: "BackOffice/Supervisor/ProfileInstructor/Introduction",
  },
  {
    path: "/supervisor/profile-instructor/education",
    name: "BackOffice/Supervisor/ProfileInstructor/Education",
  },
  {
    path: "/supervisor/profile-instructor/professional-experience",
    name: "BackOffice/Supervisor/ProfileInstructor/ProfExp",
  },
  {
    path: "/supervisor/profile-instructor/teaching-skills",
    name: "BackOffice/Supervisor/ProfileInstructor/TeachingSkills",
  },
  {
    path: "/supervisor/profile-instructor/additional-qualifications",
    name: "BackOffice/Supervisor/ProfileInstructor/AdditionalQualifi",
  },
  {
    path: "/supervisor/profile-instructor/awards-and-honors",
    name: "BackOffice/Supervisor/ProfileInstructor/AwardsandHonors",
  },
  {
    path: "/supervisor/profile-instructor/languages",
    name: "BackOffice/Supervisor/ProfileInstructor/Languages",
  },
  {
    path: "/supervisor/profile-instructor/notes",
    name: "BackOffice/Supervisor/ProfileInstructor/Notes",
  },
  {
    path: "/supervisor/profile-instructor/publication",
    name: "BackOffice/Supervisor/ProfileInstructor/Publication",
  },
  {
    path: "/supervisor/profile-instructor/reviews",
    name: "BackOffice/Supervisor/ProfileInstructor/Reviews",
  },
  {
    path: "/supervisor/profile-instructor/seo-keywords",
    name: "BackOffice/Supervisor/ProfileInstructor/SEOKeywords",
  },
  {
    path: "/supervisor/profile-instructor/teaching-philosophy",
    name: "BackOffice/Supervisor/ProfileInstructor/TeachingPhilosophy",
  },
  {
    path: "/supervisor/profile-instructor/tests-taken",
    name: "BackOffice/Supervisor/ProfileInstructor/TestTaken",
  },
  {
    path: "/supervisor/profile-instructor/track-record",
    name: "BackOffice/Supervisor/ProfileInstructor/TrackRecord",
  },
  {
    path: "/supervisor/profile-instructor/subject-expertise",
    name: "BackOffice/Supervisor/ProfileInstructor/SubjectExpertise",
  },
  {
    path: "/supervisor/profile-instructor/past-placements",
    name: "BackOffice/Supervisor/ProfileInstructor/PastPlacements",
  },
  {
    path: "/supervisor/profile-coach/personal-info",
    name: "BackOffice/Supervisor/ProfileCoach/PersonalInfo",
  },
  {
    path: "/supervisor/profile-coach/introduction",
    name: "BackOffice/Supervisor/ProfileCoach/Introduction",
  },
  {
    path: "/supervisor/profile-coach/education",
    name: "BackOffice/Supervisor/ProfileCoach/Education",
  },
  {
    path: "/supervisor/profile-coach/professional-experience",
    name: "BackOffice/Supervisor/ProfileCoach/ProfExp",
  },
  {
    path: "/supervisor/profile-coach/skills",
    name: "BackOffice/Supervisor/ProfileCoach/Skills",
  },
  {
    path: "/supervisor/profile-coach/additional-qualifications",
    name: "BackOffice/Supervisor/ProfileCoach/AdditionalQualifi",
  },
  {
    path: "/supervisor/profile-coach/awards-and-honors",
    name: "BackOffice/Supervisor/ProfileCoach/AwardsandHonors",
  },
  {
    path: "/supervisor/profile-coach/languages",
    name: "BackOffice/Supervisor/ProfileCoach/Languages",
  },
  {
    path: "/supervisor/profile-coach/notes",
    name: "BackOffice/Supervisor/ProfileCoach/Notes",
  },
  {
    path: "/supervisor/profile-coach/publication",
    name: "BackOffice/Supervisor/ProfileCoach/Publication",
  },
  {
    path: "/supervisor/profile-coach/tests-taken",
    name: "BackOffice/Supervisor/ProfileCoach/TestTaken",
  },
  {
    path: "/supervisor/profile-coach/coaching-philosophy",
    name: "BackOffice/Supervisor/ProfileCoach/CoachingPhilosophy",
  },
  {
    path: "/supervisor/profile-coach/track-record",
    name: "BackOffice/Supervisor/ProfileCoach/TrackRecord",
  },
  {
    path: "/supervisor/profile-coach/seo-keywords",
    name: "BackOffice/Supervisor/ProfileCoach/SEOKeywords",
  },
  {
    path: "/supervisor/profile-coach/past-placements",
    name: "BackOffice/Supervisor/ProfileCoach/PastPlacements",
  },
  {
    path: "/supervisor/profile-coach/reviews",
    name: "BackOffice/Supervisor/ProfileCoach/Reviews",
  },
  {
    path: "/supervisor/profile-consultant/personal-info",
    name: "BackOffice/Supervisor/ProfileConsultant/PersonalInfo",
  },
  {
    path: "/supervisor/profile-consultant/introduction",
    name: "BackOffice/Supervisor/ProfileConsultant/Introduction",
  },
  {
    path: "/supervisor/profile-consultant/education",
    name: "BackOffice/Supervisor/ProfileConsultant/Education",
  },
  {
    path: "/supervisor/profile-consultant/professional-experience",
    name: "BackOffice/Supervisor/ProfileConsultant/ProfExp",
  },
  {
    path: "/supervisor/profile-consultant/skills",
    name: "BackOffice/Supervisor/ProfileConsultant/Skills",
  },
  {
    path: "/supervisor/profile-consultant/additional-qualifications",
    name: "BackOffice/Supervisor/ProfileConsultant/AdditionalQualifi",
  },
  {
    path: "/supervisor/profile-consultant/awards-and-honors",
    name: "BackOffice/Supervisor/ProfileConsultant/AwardsandHonors",
  },
  {
    path: "/supervisor/profile-consultant/languages",
    name: "BackOffice/Supervisor/ProfileConsultant/Languages",
  },
  {
    path: "/supervisor/profile-consultant/notes",
    name: "BackOffice/Supervisor/ProfileConsultant/Notes",
  },
  {
    path: "/supervisor/profile-consultant/publication",
    name: "BackOffice/Supervisor/ProfileConsultant/Publication",
  },
  {
    path: "/supervisor/profile-consultant/reviews",
    name: "BackOffice/Supervisor/ProfileConsultant/Reviews",
  },
  {
    path: "/supervisor/profile-consultant/seo-keywords",
    name: "BackOffice/Supervisor/ProfileConsultant/SEOKeywords",
  },
  {
    path: "/supervisor/profile-consultant/consulting-philosophy",
    name: "BackOffice/Supervisor/ProfileConsultant/ConsultingPhilosophy",
  },
  {
    path: "/supervisor/profile-consultant/tests-taken",
    name: "BackOffice/Supervisor/ProfileConsultant/TestTaken",
  },
  {
    path: "/supervisor/profile-consultant/track-record",
    name: "BackOffice/Supervisor/ProfileConsultant/TrackRecord",
  },
  {
    path: "/supervisor/profile-consultant/subject-expertise",
    name: "BackOffice/Supervisor/ProfileConsultant/SubjectExpertise",
  },
  {
    path: "/supervisor/profile-consultant/past-placements",
    name: "BackOffice/Supervisor/ProfileConsultant/PastPlacements",
  },
  {
    path: "/supervisor/receivables/overview-of-parents",
    name: "BackOffice/Supervisor/Receivables/OverviewofParents",
  },
  {
    path: "/supervisor/receivables/coin-balance",
    name: "BackOffice/Supervisor/Receivables/CoinBalance",
  },
  {
    path: "/supervisor/receivables/payments-made",
    name: "BackOffice/Supervisor/Receivables/PaymentsMade",
  },
  {
    path: "/supervisor/receivables/settings",
    name: "BackOffice/Supervisor/Receivables/ReceivablesSettings",
  },
  {
    path: "/supervisor/receivables/invoices",
    name: "BackOffice/Supervisor/Receivables/Invoices",
  },
  {
    path: "/supervisor/receivables/select-user",
    name: "BackOffice/Supervisor/Receivables/ReceivablesSelectUser",
  },
  {
    path: "/supervisor/payables/overview-of-coworkers",
    name: "BackOffice/Supervisor/Payables/OverviewofCoworkers",
  },
  {
    path: "/supervisor/payables/select-user",
    name: "BackOffice/Supervisor/Payables/PayablesSelectUser",
  },
  {
    path: "/supervisor/payables/financials/settings",
    name: "BackOffice/Supervisor/Payables/Financials/Settings",
  },
  {
    path: "/supervisor/payables/financials/coin-balance",
    name: "BackOffice/Supervisor/Payables/Financials/CoinBalance",
  },
  {
    path: "/supervisor/payables/financials/bank-payments-received",
    name: "BackOffice/Supervisor/Payables/Financials/BankPaymentsReceived",
  },
  {
    path: "/supervisor/payables/financials/performance",
    name: "BackOffice/Supervisor/Payables/Financials/Performance",
  },
  {
    path: "/supervisor/payables/financials/send-payroll",
    name: "BackOffice/Supervisor/Payables/Financials/SendPayroll",
  },
  {
    path: "/supervisor/payables/financials/tax-forms",
    name: "BackOffice/Supervisor/Payables/Financials/TaxForms",
  },
  {
    path: "/supervisor/manage-student/overview-of-my-students",
    name: "BackOffice/Supervisor/ManageStudent/OverviewofStudents",
  },
  {
    path: "/supervisor/manage-student/select-user",
    name: "BackOffice/Supervisor/ManageStudent/SelectUser",
  },
  {
    path: "/supervisor/manage-student/calendar/view-student-calendar",
    name: "BackOffice/Supervisor/ManageStudent/CalenderStudent/ViewStudentCalender",
  },
  {
    path: "/supervisor/manage-student/complaints-and-tech-cases/complaints-history",
    name: "BackOffice/Supervisor/ManageStudent/ComplaintsandTechCases/ComplaintsHistory",
  },
  {
    path: "/supervisor/manage-student/complaints-and-tech-cases/view-complaints",
    name: "BackOffice/Supervisor/ManageStudent/ComplaintsandTechCases/ViewComplaints",
  },
  {
    path: "/supervisor/manage-student/complaints-and-tech-cases/tech-cases-history",
    name: "BackOffice/Supervisor/ManageStudent/ComplaintsandTechCases/TechCasesHistory",
  },
  {
    path: "/supervisor/manage-student/complaints-and-tech-cases/view-tech-cases",
    name: "BackOffice/Supervisor/ManageStudent/ComplaintsandTechCases/ViewTechCases",
  },
  {
    path: "/supervisor/manage-student/profile-student/education",
    name: "BackOffice/Supervisor/ManageStudent/ProfileStudent/Education",
  },
  {
    path: "/supervisor/manage-student/profile-student/future-courses",
    name: "BackOffice/Supervisor/ManageStudent/ProfileStudent/FutureCourses",
  },
  {
    path: "/supervisor/manage-student/profile-student/ongoing-courses",
    name: "BackOffice/Supervisor/ManageStudent/ProfileStudent/OngoingCourses",
  },
  {
    path: "/supervisor/manage-student/profile-student/personal-info",
    name: "BackOffice/Supervisor/ManageStudent/ProfileStudent/PersonalInfo",
  },
  {
    path: "/supervisor/manage-student/profile-student/target-grades",
    name: "BackOffice/Supervisor/ManageStudent/ProfileStudent/TargetGrades",
  },
  {
    path: "/supervisor/manage-student/student-performance/coach-overall-report",
    name: "BackOffice/Supervisor/ManageStudent/StudentPerformance/CoachOverallReport",
  },
  {
    path: "/supervisor/manage-student/student-performance/lesson-reports",
    name: "BackOffice/Supervisor/ManageStudent/StudentPerformance/LessonReports",
  },
  {
    path: "/supervisor/manage-student/student-performance/lesson-reviews",
    name: "BackOffice/Supervisor/ManageStudent/StudentPerformance/LessonReviews",
  },
  {
    path: "/supervisor/manage-student/student-performance/request-course-review",
    name: "BackOffice/Supervisor/ManageStudent/StudentPerformance/RequestCourseReview",
  },
  {
    path: "/supervisor/manage-student/student-performance/request-satisfaction-survey",
    name: "BackOffice/Supervisor/ManageStudent/StudentPerformance/RequestSatisfactionSurvey",
  },
  {
    path: "/supervisor/manage-student/student-performance/send-lesson-reminder",
    name: "BackOffice/Supervisor/ManageStudent/StudentPerformance/SendLessonReminder",
  },
  {
    path: "/supervisor/manage-student/student-performance/view-interactions",
    name: "BackOffice/Supervisor/ManageStudent/StudentPerformance/ViewInteractions",
  },

  {
    path: "/supervisor/manage-parent/overview-of-my-parents",
    name: "BackOffice/Supervisor/ManageParent/OverviewofParents",
  },
  {
    path: "/supervisor/manage-parent/select-user",
    name: "BackOffice/Supervisor/ManageParent/SelectUser",
  },
  {
    path: "/supervisor/manage-parent/calendar/view-parent-calender",
    name: "BackOffice/Supervisor/ManageParent/Calender/ViewParentCalender",
  },
  {
    path: "/supervisor/manage-parent/complaints-and-tech-cases/complaints-history",
    name: "BackOffice/Supervisor/ManageParent/ComplaintsandTechCases/ComplaintsHistory",
  },
  {
    path: "/supervisor/manage-parent/complaints-and-tech-cases/view-complaints",
    name: "BackOffice/Supervisor/ManageParent/ComplaintsandTechCases/ViewComplaints",
  },
  {
    path: "/supervisor/manage-parent/complaints-and-tech-cases/tech-cases-history",
    name: "BackOffice/Supervisor/ManageParent/ComplaintsandTechCases/TechCasesHistory",
  },
  {
    path: "/supervisor/manage-parent/complaints-and-tech-cases/view-tech-cases",
    name: "BackOffice/Supervisor/ManageParent/ComplaintsandTechCases/ViewTechCases",
  },
  {
    path: "/supervisor/manage-parent/profile/education",
    name: "BackOffice/Supervisor/ManageParent/Profile/Education",
  },
  {
    path: "/supervisor/manage-parent/profile/personal-info",
    name: "BackOffice/Supervisor/ManageParent/Profile/PersonalInfo",
  },
  {
    path: "/supervisor/manage-parent/performance/coach-overall-report",
    name: "BackOffice/Supervisor/ManageParent/Performance/CoachOverallReport",
  },
  {
    path: "/supervisor/manage-parent/performance/lesson-reports",
    name: "BackOffice/Supervisor/ManageParent/Performance/LessonReports",
  },
  {
    path: "/supervisor/manage-parent/performance/view-interactions",
    name: "BackOffice/Supervisor/ManageParent/Performance/ViewInteractions",
  },
  {
    path: "/supervisor/manage-parent/reviews-and-satisfaction/request-course-review",
    name: "BackOffice/Supervisor/ManageParent/ReviewsandSatisfaction/RequestCourseReview",
  },
  {
    path: "/supervisor/manage-parent/reviews-and-satisfaction/request-satisfaction-survey",
    name: "BackOffice/Supervisor/ManageParent/ReviewsandSatisfaction/RequestSatisfactionSurvey",
  },
  {
    path: "/supervisor/manage-parent/reviews-and-satisfaction/reviews-and-ratings",
    name: "BackOffice/Supervisor/ManageParent/ReviewsandSatisfaction/ReviewsandRatings",
  },
  {
    path: "/supervisor/manage-parent/reviews-and-satisfaction/request-review",
    name: "BackOffice/Supervisor/ManageParent/ReviewsandSatisfaction/RequestReview",
  },
  {
    path: "/supervisor/manage-parent/financials/settings",
    name: "BackOffice/Supervisor/ManageParent/Financials/Settings",
  },
  {
    path: "/supervisor/manage-parent/financials/payments-made",
    name: "BackOffice/Supervisor/ManageParent/Financials/PaymentsMade",
  },
  {
    path: "/supervisor/manage-parent/financials/coin-balance",
    name: "BackOffice/Supervisor/ManageParent/Financials/CoinBalance",
  },
  {
    path: "/supervisor/manage-parent/financials/invoices",
    name: "BackOffice/Supervisor/ManageParent/Financials/Invoices",
  },

  //ManageCoach///////////////////////////////////////////////////////////
  {
    path: "/supervisor/manage-coach/assign-student",
    name: "BackOffice/Supervisor/ManageCoach/AssignStudent",
  },
  {
    path: "/supervisor/manage-coach/overview-of-my-coaches",
    name: "BackOffice/Supervisor/ManageCoach/OverviewofMyCoaches",
  },
  {
    path: "/supervisor/manage-coach/select-user",
    name: "BackOffice/Supervisor/ManageCoach/SelectUser",
  },
  {
    path: "/supervisor/manage-coach/profile/personal-info",
    name: "BackOffice/Supervisor/ManageCoach/Profile/PersonalInfo",
  },
  {
    path: "/supervisor/manage-coach/profile/introduction",
    name: "BackOffice/Supervisor/ManageCoach/Profile/Introduction",
  },
  {
    path: "/supervisor/manage-coach/profile/education",
    name: "BackOffice/Supervisor/ManageCoach/Profile/Education",
  },
  {
    path: "/supervisor/manage-coach/profile/professional-experience",
    name: "BackOffice/Supervisor/ManageCoach/Profile/ProfExp",
  },
  {
    path: "/supervisor/manage-coach/profile/skills",
    name: "BackOffice/Supervisor/ManageCoach/Profile/Skills",
  },
  {
    path: "/supervisor/manage-coach/profile/additional-qualifications",
    name: "BackOffice/Supervisor/ManageCoach/Profile/AdditionalQualifi",
  },
  {
    path: "/supervisor/manage-coach/profile/awards-and-honors",
    name: "BackOffice/Supervisor/ManageCoach/Profile/AwardsandHonors",
  },
  {
    path: "/supervisor/manage-coach/profile/languages",
    name: "BackOffice/Supervisor/ManageCoach/Profile/Languages",
  },
  {
    path: "/supervisor/manage-coach/profile/notes",
    name: "BackOffice/Supervisor/ManageCoach/Profile/Notes",
  },
  {
    path: "/supervisor/manage-coach/profile/publication",
    name: "BackOffice/Supervisor/ManageCoach/Profile/Publication",
  },
  {
    path: "/supervisor/manage-coach/profile/reviews",
    name: "BackOffice/Supervisor/ManageCoach/Profile/Reviews",
  },
  {
    path: "/supervisor/manage-coach/profile/seo-keywords",
    name: "BackOffice/Supervisor/ManageCoach/Profile/SEOKeywords",
  },
  {
    path: "/supervisor/manage-coach/profile/coaching-philosophy",
    name: "BackOffice/Supervisor/ManageCoach/Profile/CoachingPhilosophy",
  },
  {
    path: "/supervisor/manage-coach/profile/test-taken",
    name: "BackOffice/Supervisor/ManageCoach/Profile/TestTaken",
  },
  {
    path: "/supervisor/manage-coach/profile/track-record",
    name: "BackOffice/Supervisor/ManageCoach/Profile/TrackRecord",
  },
  {
    path: "/supervisor/manage-coach/profile/past-placements",
    name: "BackOffice/Supervisor/ManageCoach/Profile/PastPlacements",
  },
  {
    path: "/supervisor/manage-coach/calendar/view-coach-calendar",
    name: "BackOffice/Supervisor/ManageCoach/Calender/ViewCoachCalender",
  },
  {
    path: "/supervisor/manage-coach/performance/financial-performance",
    name: "BackOffice/Supervisor/ManageCoach/Performance/FinancialPerformance",
  },
  {
    path: "/supervisor/manage-coach/performance/missed-calls",
    name: "BackOffice/Supervisor/ManageCoach/Performance/MissedCalls",
  },
  {
    path: "/supervisor/manage-coach/performance/missed-calls-report",
    name: "BackOffice/Supervisor/ManageCoach/Performance/MissedCallsReport",
  },
  {
    path: "/supervisor/manage-coach/performance/registrations",
    name: "BackOffice/Supervisor/ManageCoach/Performance/Registrations",
  },
  {
    path: "/supervisor/manage-coach/performance/students-assigned",
    name: "BackOffice/Supervisor/ManageCoach/Performance/StudentsAssigned",
  },
  {
    path: "/supervisor/manage-coach/performance/view-interactions",
    name: "BackOffice/Supervisor/ManageCoach/Performance/ViewInteractions",
  },
  {
    path: "/supervisor/manage-coach/complaints/view-complaints",
    name: "BackOffice/Supervisor/ManageCoach/Complaints/ViewComplaints",
  },
  {
    path: "/supervisor/manage-coach/complaints/complaints-history",
    name: "BackOffice/Supervisor/ManageCoach/Complaints/ComplaintsHistory",
  },
  {
    path: "/supervisor/manage-coach/financials/financial-ratios",
    name: "BackOffice/Supervisor/ManageCoach/Financials/FinancialRatios",
  },
  {
    path: "/supervisor/manage-coach/manage-working-days-and-hours/phone-handling-schedule",
    name: "BackOffice/Supervisor/ManageCoach/ManageWorkingDaysandHours/PhoneHandlingSchedule",
  },
  {
    path: "/supervisor/manage-coach/reports/entire-coaching-period-review-received",
    name: "BackOffice/Supervisor/ManageCoach/ReportsandReviews/EntireCoachingPeriodReviewReceived",
  },
  {
    path: "/supervisor/manage-coach/reports/pending-coaching-reports",
    name: "BackOffice/Supervisor/ManageCoach/ReportsandReviews/PendingCoachingReports",
  },
  {
    path: "/supervisor/manage-coach/reports/previous-coaching-reports",
    name: "BackOffice/Supervisor/ManageCoach/ReportsandReviews/PreviousCoachingReports",
  },
  {
    path: "/supervisor/manage-coach/reports/reviews-on-coach-profile",
    name: "BackOffice/Supervisor/ManageCoach/ReportsandReviews/ReviewsonCoachProfile",
  },
  {
    path: "/supervisor/manage-coach/reports/satisfaction-survey",
    name: "BackOffice/Supervisor/ManageCoach/ReportsandReviews/SatisfactionSurvey",
  },
  {
    path: "/supervisor/manage-coach/reports/session-review-received",
    name: "BackOffice/Supervisor/ManageCoach/ReportsandReviews/SessionReviewReceived",
  },

  //ManageInstructor///////////////////////////////////////////////////////////
  {
    path: "/supervisor/manage-instructor/assign-student",
    name: "BackOffice/Supervisor/ManageInstructor/AssignStudent",
  },
  {
    path: "/supervisor/manage-instructor/overview-of-my-instructors",
    name: "BackOffice/Supervisor/ManageInstructor/OverviewofMyInstructors",
  },
  {
    path: "/supervisor/manage-instructor/select-user",
    name: "BackOffice/Supervisor/ManageInstructor/SelectUser",
  },
  {
    path: "/supervisor/manage-instructor/profile/personal-info",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/PersonalInfo",
  },
  {
    path: "/supervisor/manage-instructor/profile/introduction",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/Introduction",
  },
  {
    path: "/supervisor/manage-instructor/profile/education",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/Education",
  },
  {
    path: "/supervisor/manage-instructor/profile/professional-experience",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/ProfExp",
  },
  {
    path: "/supervisor/manage-instructor/profile/teaching-skills",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/TeachingSkills",
  },
  {
    path: "/supervisor/manage-instructor/profile/additional-qualifications",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/AdditionalQualifi",
  },
  {
    path: "/supervisor/manage-instructor/profile/awards-and-honors",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/AwardsandHonors",
  },
  {
    path: "/supervisor/manage-instructor/profile/languages",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/Languages",
  },
  {
    path: "/supervisor/manage-instructor/profile/notes",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/Notes",
  },
  {
    path: "/supervisor/manage-instructor/profile/publication",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/Publication",
  },
  {
    path: "/supervisor/manage-instructor/profile/reviews",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/Reviews",
  },
  {
    path: "/supervisor/manage-instructor/profile/seo-keywords",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/SEOKeywords",
  },
  {
    path: "/supervisor/manage-instructor/profile/teaching-philosophy",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/TeachingPhilosophy",
  },
  {
    path: "/supervisor/manage-instructor/profile/test-taken",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/TestTaken",
  },
  {
    path: "/supervisor/manage-instructor/profile/track-record",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/TrackRecord",
  },
  {
    path: "/supervisor/manage-instructor/profile/past-placements",
    name: "BackOffice/Supervisor/ManageInstructor/Profile/PastPlacements",
  },
  {
    path: "/supervisor/manage-instructor/calendar/view-instructor-calendar",
    name: "BackOffice/Supervisor/ManageInstructor/Calender/ViewInstructorCalender",
  },
  {
    path: "/supervisor/manage-instructor/performance/financial-performance",
    name: "BackOffice/Supervisor/ManageInstructor/Performance/FinancialPerformance",
  },
  {
    path: "/supervisor/manage-instructor/performance/view-interactions",
    name: "BackOffice/Supervisor/ManageInstructor/Performance/ViewInteractions",
  },
  {
    path: "/supervisor/manage-instructor/complaints/view-complaints",
    name: "BackOffice/Supervisor/ManageInstructor/Complaints/ViewComplaints",
  },
  {
    path: "/supervisor/manage-instructor/complaints/complaints-history",
    name: "BackOffice/Supervisor/ManageInstructor/Complaints/ComplaintsHistory",
  },
  {
    path: "/supervisor/manage-instructor/financials/settings",
    name: "BackOffice/Supervisor/ManageInstructor/Financials/Settings",
  },
  {
    path: "/supervisor/manage-instructor/financials/coin-balance",
    name: "BackOffice/Supervisor/ManageInstructor/Financials/CoinBalance",
  },
  {
    path: "/supervisor/manage-instructor/financials/performance",
    name: "BackOffice/Supervisor/ManageInstructor/Financials/Performance",
  },
  {
    path: "/supervisor/manage-instructor/financials/tax-forms",
    name: "BackOffice/Supervisor/ManageInstructor/Financials/TaxForms",
  },
  {
    path: "/supervisor/manage-instructor/financials/send-payroll",
    name: "BackOffice/Supervisor/ManageInstructor/Financials/SendPayroll",
  },
  {
    path: "/supervisor/manage-instructor/financials/bank-payments-received",
    name: "BackOffice/Supervisor/ManageInstructor/Financials/BankPaymentsReceived",
  },
  {
    path: "/supervisor/manage-instructor/reports/course-reviews-received",
    name: "BackOffice/Supervisor/ManageInstructor/ReportsandReviews/CourseReviewsReceived",
  },
  {
    path: "/supervisor/manage-instructor/reports/pending-instructor-reports",
    name: "BackOffice/Supervisor/ManageInstructor/ReportsandReviews/PendingInstructorReports",
  },
  {
    path: "/supervisor/manage-instructor/reports/previous-instructor-reports",
    name: "BackOffice/Supervisor/ManageInstructor/ReportsandReviews/PreviousInstructorReports",
  },
  {
    path: "/supervisor/manage-instructor/reports/reviews-on-instructor-profile",
    name: "BackOffice/Supervisor/ManageInstructor/ReportsandReviews/ReviewsonInstructorProfile",
  },
  {
    path: "/supervisor/manage-instructor/reports/satisfaction-survey",
    name: "BackOffice/Supervisor/ManageInstructor/ReportsandReviews/SatisfactionSurvey",
  },
  {
    path: "/supervisor/manage-instructor/reports/lesson-review-received",
    name: "BackOffice/Supervisor/ManageInstructor/ReportsandReviews/LessonReviewsReceived",
  },
  {
    path: "/supervisor/manage-instructor/reports/recommendation-letter-request",
    name: "BackOffice/Supervisor/ManageInstructor/ReportsandReviews/RecommendationLetterRequest",
  },
  {
    path: "/supervisor/manage-instructor/settings/financial-ratios",
    name: "BackOffice/Supervisor/ManageInstructor/Settings/FinancialRatios",
  },
  {
    path: "/supervisor/manage-instructor/settings/webpage-privilages",
    name: "BackOffice/Supervisor/ManageInstructor/Settings/WebpagePrivilages",
  },
  {
    path: "/supervisor/manage-instructor/manage-subject-page/expectations",
    name: "BackOffice/Supervisor/ManageInstructor/ManageSubjectPage/Expectations",
  },
  {
    path: "/supervisor/manage-instructor/manage-subject-page/instructor-profiles",
    name: "BackOffice/Supervisor/ManageInstructor/ManageSubjectPage/InstructorProfiles",
  },
  {
    path: "/supervisor/manage-instructor/manage-subject-page/introduction",
    name: "BackOffice/Supervisor/ManageInstructor/ManageSubjectPage/Introduction",
  },
  {
    path: "/supervisor/manage-instructor/manage-subject-page/major-topics",
    name: "BackOffice/Supervisor/ManageInstructor/ManageSubjectPage/MajorTopics",
  },
  {
    path: "/supervisor/manage-instructor/manage-subject-page/testimonials-and-reviews",
    name: "BackOffice/Supervisor/ManageInstructor/ManageSubjectPage/TestimonialsandReviews",
  },
  {
    path: "/supervisor/manage-instructor/manage-subject-page/tips-and-tricks",
    name: "BackOffice/Supervisor/ManageInstructor/ManageSubjectPage/TipsandTricks",
  },
  {
    path: "/supervisor/manage-instructor/manage-subject-page/who-should-take-this-subject",
    name: "BackOffice/Supervisor/ManageInstructor/ManageSubjectPage/WhoShouldTakeThisSubject",
  },

  //ManageConsultant///////////////////////////////////////////////////////////
  {
    path: "/supervisor/manage-consultant/assign-student",
    name: "BackOffice/Supervisor/ManageConsultant/AssignStudent",
  },
  {
    path: "/supervisor/manage-consultant/overview-of-my-consultant",
    name: "BackOffice/Supervisor/ManageConsultant/OverviewofMyConsultant",
  },
  {
    path: "/supervisor/manage-consultant/select-user",
    name: "BackOffice/Supervisor/ManageConsultant/SelectUser",
  },
  {
    path: "/supervisor/manage-consultant/profile/personal-info",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/PersonalInfo",
  },
  {
    path: "/supervisor/manage-consultant/profile/introduction",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/Introduction",
  },
  {
    path: "/supervisor/manage-consultant/profile/education",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/Education",
  },
  {
    path: "/supervisor/manage-consultant/profile/professional-experience",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/ProfExp",
  },
  {
    path: "/supervisor/manage-consultant/profile/skills",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/Skills",
  },
  {
    path: "/supervisor/manage-consultant/profile/additional-qualifications",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/AdditionalQualifi",
  },
  {
    path: "/supervisor/manage-consultant/profile/awards-and-honors",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/AwardsandHonors",
  },
  {
    path: "/supervisor/manage-consultant/profile/languages",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/Languages",
  },
  {
    path: "/supervisor/manage-consultant/profile/notes",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/Notes",
  },
  {
    path: "/supervisor/manage-consultant/profile/publication",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/Publication",
  },
  {
    path: "/supervisor/manage-consultant/profile/reviews",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/Reviews",
  },
  {
    path: "/supervisor/manage-consultant/profile/seo-keywords",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/SEOKeywords",
  },
  {
    path: "/supervisor/manage-consultant/profile/consultant-philosophy",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/ConsultantPhilosophy",
  },
  {
    path: "/supervisor/manage-consultant/profile/test-taken",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/TestTaken",
  },
  {
    path: "/supervisor/manage-consultant/profile/track-record",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/TrackRecord",
  },
  {
    path: "/supervisor/manage-consultant/profile/past-placements",
    name: "BackOffice/Supervisor/ManageConsultant/Profile/PastPlacements",
  },
  {
    path: "/supervisor/manage-consultant/calendar/view-consultant-calendar",
    name: "BackOffice/Supervisor/ManageConsultant/Calender/ViewConsultantCalender",
  },
  {
    path: "/supervisor/manage-consultant/performance/financial-performance",
    name: "BackOffice/Supervisor/ManageConsultant/Performance/FinancialPerformance",
  },
  {
    path: "/supervisor/manage-consultant/performance/view-interactions",
    name: "BackOffice/Supervisor/ManageConsultant/Performance/ViewInteractions",
  },
  {
    path: "/supervisor/manage-consultant/complaints/view-complaints",
    name: "BackOffice/Supervisor/ManageConsultant/Complaints/ViewComplaints",
  },
  {
    path: "/supervisor/manage-consultant/complaints/complaints-history",
    name: "BackOffice/Supervisor/ManageConsultant/Complaints/ComplaintsHistory",
  },
  {
    path: "/supervisor/manage-consultant/reports/entire-consultant-period-review-received",
    name: "BackOffice/Supervisor/ManageConsultant/ReportsandReviews/EntireConsultantPeriodReviewReceived",
  },
  {
    path: "/supervisor/manage-consultant/reports/pending-consultant-reports",
    name: "BackOffice/Supervisor/ManageConsultant/ReportsandReviews/PendingConsultantReports",
  },
  {
    path: "/supervisor/manage-consultant/reports/previous-consultant-reports",
    name: "BackOffice/Supervisor/ManageConsultant/ReportsandReviews/PreviousConsultantReports",
  },
  {
    path: "/supervisor/manage-consultant/reports/reviews-on-consultant-profile",
    name: "BackOffice/Supervisor/ManageConsultant/ReportsandReviews/ReviewsonConsultantProfile",
  },
  {
    path: "/supervisor/manage-consultant/reports/satisfaction-survey",
    name: "BackOffice/Supervisor/ManageConsultant/ReportsandReviews/SatisfactionSurvey",
  },
  {
    path: "/supervisor/manage-consultant/reports/session-review-received",
    name: "BackOffice/Supervisor/ManageConsultant/ReportsandReviews/SessionReviewReceived",
  },
  {
    path: "/supervisor/manage-consultant/settings/financial-ratios",
    name: "BackOffice/Supervisor/ManageConsultant/Settings/FinancialRatios",
  },
  {
    path: "/supervisor/manage-consultant/settings/webpage-privilages",
    name: "BackOffice/Supervisor/ManageConsultant/Settings/WebpagePrivilages",
  },

  {
    path: "/supervisor/student-progress-report-card/grades-and-scores/ap",
    name: "BackOffice/Supervisor/StudentProgressReportCard/GradesandScores/AP",
  },
  {
    path: "/supervisor/student-progress-report-card/grades-and-scores/toefl",
    name: "BackOffice/Supervisor/StudentProgressReportCard/GradesandScores/TOEFL",
  },
  {
    path: "/supervisor/student-progress-report-card/grades-and-scores/mathematics",
    name: "BackOffice/Supervisor/StudentProgressReportCard/GradesandScores/Mathematics",
  },
  {
    path: "/supervisor/student-progress-report-card/grades-and-scores/microeconomics",
    name: "BackOffice/Supervisor/StudentProgressReportCard/GradesandScores/Microeconomics",
  },
  {
    path: "/supervisor/student-progress-report-card/new-actual-score",
    name: "BackOffice/Supervisor/StudentProgressReportCard/NewActualScore",
  },
  {
    path: "/supervisor/student-progress-report-card/new-predicted-score",
    name: "BackOffice/Supervisor/StudentProgressReportCard/NewPredictedScore",
  },
  {
    path: "/supervisor/student-progress-report-card/overview-of-my-instructors",
    name: "BackOffice/Supervisor/StudentProgressReportCard/OverviewofMyInstructors",
  },
  {
    path: "/supervisor/student-progress-report-card/select-student",
    name: "BackOffice/Supervisor/StudentProgressReportCard/SelectStudent",
  },
  {
    path: "/supervisor/financials-tbu/balance-sheet",
    name: "BackOffice/Supervisor/FinancialsTBU/BalanceSheet",
  },
  {
    path: "/supervisor/financials-tbu/cashflow-statement",
    name: "BackOffice/Supervisor/FinancialsTBU/CashFlowStatement",
  },
  {
    path: "/supervisor/financials-tbu/income-statement",
    name: "BackOffice/Supervisor/FinancialsTBU/IncomeStatement",
  },
  {
    path: "/supervisor/financials-tbu/payments-received",
    name: "BackOffice/Supervisor/FinancialsTBU/PaymentsReceived",
  },
  {
    path: "/supervisor/financials-tbu/payroll",
    name: "BackOffice/Supervisor/FinancialsTBU/Payroll",
  },
  {
    path: "/supervisor/financials-tbu/performance",
    name: "BackOffice/Supervisor/FinancialsTBU/Performance",
  },
  {
    path: "/supervisor/financials-tbu/settings",
    name: "BackOffice/Supervisor/FinancialsTBU/Settings",
  },
  {
    path: "/supervisor/financials-tbu/tax-filings",
    name: "BackOffice/Supervisor/FinancialsTBU/TaxFilings",
  },
  {
    path: "/supervisor/video-chat/phone-video-chat-calendar",
    name: "BackOffice/Supervisor/VideoChat/PhoneVideoChatCalender",
  },
  {
    path: "/supervisor/video-chat/select-user",
    name: "BackOffice/Supervisor/VideoChat/SelectUser",
  },
  {
    path: "/supervisor/video-chat/student-assigned",
    name: "BackOffice/Supervisor/VideoChat/StudentAssigned",
  },
  {
    path: "/supervisor/video-chat/phone-video-chat-handling",
    name: "BackOffice/Supervisor/VideoChat/PhoneVideoChatHandling",
  },
  {
    path: "/supervisor/video-chat/registrations",
    name: "BackOffice/Supervisor/VideoChat/Registrations",
  },
  {
    path: "/supervisor/video-chat/financial-performance",
    name: "BackOffice/Supervisor/VideoChat/FinancialPerformance",
  },
  {
    path: "/supervisor/video-chat/missed-calls",
    name: "BackOffice/Supervisor/VideoChat/MissedCalls",
  },
  {
    path: "/supervisor/video-chat/missed-calls-reports",
    name: "BackOffice/Supervisor/VideoChat/MissedCallsReports",
  },
  {
    path: "/supervisor/handle-complaints/open-complaints",
    name: "BackOffice/Supervisor/HandleComplaints/OpenComplaints",
  },
  {
    path: "/supervisor/handle-complaints/complaint-history",
    name: "BackOffice/Supervisor/HandleComplaints/ComplaintHistory",
  },
  {
    path: "/supervisor/handle-complaints/escalated-to-supervisor",
    name: "BackOffice/Supervisor/HandleComplaints/EscalatedToSupervisor",
  },
  {
    path: "/supervisor/handle-complaints/escalated-to-admin",
    name: "BackOffice/Supervisor/HandleComplaints/EscalatedToAdmin",
  },
  {
    path: "/supervisor/tech-support/closed-cases",
    name: "BackOffice/Supervisor/TechSupport/ClosedCases",
  },
  {
    path: "/supervisor/tech-support/new-case",
    name: "BackOffice/Supervisor/TechSupport/NewCase",
  },
  {
    path: "/supervisor/tech-support/existing-cases",
    name: "BackOffice/Supervisor/TechSupport/ExistingCases",
  },
  {
    path: "/supervisor/tech-support/search-faq",
    name: "BackOffice/Supervisor/TechSupport/SearchFAQ",
  },
  {
    path: "/supervisor/tech-support/search-knowledge-base",
    name: "BackOffice/Supervisor/TechSupport/SearchKnowledgeBase",
  },
  {
    path: "/supervisor/application-package-content/application-package-content",
    name: "BackOffice/Supervisor/APC/APC",
  },
  {
    path: "/supervisor/application-package-content/education",
    name: "BackOffice/Supervisor/APC/Education",
  },
  {
    path: "/supervisor/application-package-content/recommendation-letters",
    name: "BackOffice/Supervisor/APC/RecommendationLetters",
  },
  {
    path: "/supervisor/application-package-content/test-scores",
    name: "BackOffice/Supervisor/APC/TestScores",
  },
  {
    path: "/supervisor/application-package-content/financial-docs",
    name: "BackOffice/Supervisor/APC/FinancialDocs",
  },
  {
    path: "/supervisor/application-package-content/financial-docs",
    name: "BackOffice/Supervisor/APC/FinancialDocs",
  },
  {
    path: "/supervisor/application-package-content/custom-elements/harvard",
    name: "BackOffice/Supervisor/APC/CustomElements/Harvard",
  },
  {
    path: "/supervisor/application-package-content/custom-elements/introduction",
    name: "BackOffice/Supervisor/APC/CustomElements/Introduction",
  },
  {
    path: "/supervisor/application-package-content/custom-elements/cv",
    name: "BackOffice/Supervisor/APC/CustomElements/CV",
  },
  {
    path: "/supervisor/application-package-content/custom-elements/essays/essay-1",
    name: "BackOffice/Supervisor/APC/CustomElements/Essays/Essay1",
  },
  {
    path: "/supervisor/application-package-content/custom-elements/essays/essay-2",
    name: "BackOffice/Supervisor/APC/CustomElements/Essays/Essay2",
  },
  {
    path: "/supervisor/application-package-content/custom-elements/scholarship-essays/essay-1",
    name: "BackOffice/Supervisor/APC/CustomElements/ScholarshipEssays/Essay1",
  },
  {
    path: "/supervisor/application-package-content/custom-elements/scholarship-essays/essay-2",
    name: "BackOffice/Supervisor/APC/CustomElements/ScholarshipEssays/Essay2",
  },

  {
    path: "/supervisor/application-package-content/custom-elements/misc-docs",
    name: "BackOffice/Supervisor/APC/CustomElements/MiscDocs",
  },
  {
    path: "/supervisor/application-package-content/custom-elements/diary",
    name: "BackOffice/Supervisor/APC/CustomElements/Diary",
  },
  {
    path: "/supervisor/application-package-content/custom-elements/notes-for-this-school/MIT",
    name: "BackOffice/Supervisor/APC/CustomElements/NotesForThisSchool/MIT",
  },
  {
    path: "/supervisor/application-package-content/custom-elements/notes-for-this-school/stanford",
    name: "BackOffice/Supervisor/APC/CustomElements/NotesForThisSchool/Stanford",
  },
  {
    path: "/supervisor/application-package-content/custom-elements/school-applications/applied",
    name: "BackOffice/Supervisor/APC/CustomElements/SchoolApplications/Applied",
  },
  {
    path: "/supervisor/application-package-content/custom-elements/school-applications/under-review",
    name: "BackOffice/Supervisor/APC/CustomElements/SchoolApplications/UnderReview",
  },
  {
    path: "/supervisor/application-package-content/custom-elements/school-applications/offers",
    name: "BackOffice/Supervisor/APC/CustomElements/SchoolApplications/Offers",
  },
  {
    path: "/supervisor/application-package-content/custom-elements/school-applications/placed",
    name: "BackOffice/Supervisor/APC/CustomElements/SchoolApplications/Placed",
  },
  {
    path: "/supervisor/application-package-content/custom-elements/school-applications/notes",
    name: "BackOffice/Supervisor/APC/CustomElements/SchoolApplications/Notes",
  },
  {
    path: "/supervisor/application-package-content/past-packages",
    name: "BackOffice/Supervisor/APC/PastPackages",
  },

  //BackOffice Supervisor routes endpoint ----------------------------------------------------------------------------------------------


 //Coach routes start -------------------------------------------------------------------------------------------

 {
  path: "/coach",
  name: "BackOffice/Coach/Dashboard",
},
{
  path: "/coach/profile/personal-info",
  name: "BackOffice/Coach/Profile/PersonalInfo",
},
{
  path: "/coach/profile/introduction",
  name: "BackOffice/Coach/Profile/Introduction",
},
{
  path: "/coach/profile/education",
  name: "BackOffice/Coach/Profile/Education",
},
{
  path: "/coach/profile/professional-experience",
  name: "BackOffice/Coach/Profile/ProfExp",
},
{
  path: "/coach/profile/skills",
  name: "BackOffice/Coach/Profile/Skills",
},
{
  path: "/coach/profile/additional-qualifications",
  name: "BackOffice/Coach/Profile/AdditionalQualifi",
},
{
  path: "/coach/profile/awards-and-honors",
  name: "BackOffice/Coach/Profile/AwardsandHonors",
},
{
  path: "/coach/profile/languages",
  name: "BackOffice/Coach/Profile/Languages",
},
{
  path: "/coach/profile/notes",
  name: "BackOffice/Coach/Profile/Notes",
},
{
  path: "/coach/profile/publication",
  name: "BackOffice/Coach/Profile/Publication",
},
{
  path: "/coach/profile/tests-taken",
  name: "BackOffice/Coach/Profile/TestTaken",
},
{
  path: "/coach/profile/coaching-philosophy",
  name: "BackOffice/Coach/Profile/CoachingPhilosophy",
},
{
  path: "/coach/profile/track-record",
  name: "BackOffice/Coach/Profile/TrackRecord",
},
{
  path: "/coach/profile/seo-keywords",
  name: "BackOffice/Coach/Profile/SEOKeywords",
},
{
  path: "/coach/profile/past-placements",
  name: "BackOffice/Coach/Profile/PastPlacements",
},
{
  path: "/coach/profile/reviews",
  name: "BackOffice/Coach/Profile/Reviews",
},
{
  path: "/coach/live-meeting/new-meeting",
  name: "BackOffice/Coach/LiveMeeting/NewMeeting",
},
{
  path: "/coach/live-meeting/settings",
  name: "BackOffice/Coach/LiveMeeting/Settings",
},
{
  path: "/coach/messages/inbox",
  name: "BackOffice/Coach/Messages/Inbox",
},
{
  path: "/coach/messages/create-message",
  name: "BackOffice/Coach/Messages/CreateMessage",
},
{
  path: "/coach/messages/drafts",
  name: "BackOffice/Coach/Messages/Drafts",
},
{
  path: "/coach/messages/message-settings",
  name: "BackOffice/Coach/Messages/Message_Settings",
},
{
  path: "/coach/schedule/my-schedule",
  name: "BackOffice/Coach/Schedule/MySchedule",
},
{
  path: "/coach/select-user",
  name: "BackOffice/Coach/SelectUser",
},
{
  path: "/coach/registration/user-registration",
  name: "BackOffice/Coach/Registration/UserRegistration",
},
{
  path: "/coach/registration/add-new-user",
  name: "BackOffice/Coach/Registration/AddNewUser",
},
{
  path: "/coach/profile-student/personal-info",
  name: "BackOffice/Coach/ProfileStudent/PersonalInfo",
},
{
  path: "/coach/profile-student/introduction",
  name: "BackOffice/Coach/ProfileStudent/Introduction",
},
{
  path: "/coach/profile-student/education",
  name: "BackOffice/Coach/ProfileStudent/Education",
},
{
  path: "/coach/profile-student/professional-experience",
  name: "BackOffice/Coach/ProfileStudent/ProfExp",
},
{
  path: "/coach/profile-student/skills",
  name: "BackOffice/Coach/ProfileStudent/Skills",
},
{
  path: "/coach/profile-student/additional-qualifications",
  name: "BackOffice/Coach/ProfileStudent/AdditionalQualifi",
},
{
  path: "/coach/profile-student/awards-and-honors",
  name: "BackOffice/Coach/ProfileStudent/AwardsandHonors",
},
{
  path: "/coach/profile-student/languages",
  name: "BackOffice/Coach/ProfileStudent/Languages",
},
{
  path: "/coach/profile-student/notes",
  name: "BackOffice/Coach/ProfileStudent/Notes",
},
{
  path: "/coach/profile-student/publication",
  name: "BackOffice/Coach/ProfileStudent/Publication",
},
{
  path: "/coach/profile-student/reviews",
  name: "BackOffice/Coach/ProfileStudent/Reviews",
},
{
  path: "/coach/profile-student/seo-keywords",
  name: "BackOffice/Coach/ProfileStudent/SEOKeywords",
},
{
  path: "/coach/profile-student/teaching-philosophy",
  name: "BackOffice/Coach/ProfileStudent/TeachingPhilosophy",
},
{
  path: "/coach/profile-student/tests-taken",
  name: "BackOffice/Coach/ProfileStudent/TestTaken",
},
{
  path: "/coach/profile-student/track-record",
  name: "BackOffice/Coach/ProfileStudent/TrackRecord",
},
{
  path: "/coach/profile-student/subject-expertise",
  name: "BackOffice/Coach/ProfileStudent/SubjectExpertise",
},
{
  path: "/coach/profile-parent/personal-info",
  name: "BackOffice/Coach/ProfileParent/PersonalInfo",
},
{
  path: "/coach/profile-parent/education",
  name: "BackOffice/Coach/ProfileParent/Education",
},
{
  path: "/coach/profile-parent/languages",
  name: "BackOffice/Coach/ProfileParent/Languages",
},
{
  path: "/coach/profile-parent/notes",
  name: "BackOffice/Coach/ProfileParent/Notes",
},
{
  path: "/coach/profile-parent/target-countries",
  name: "BackOffice/Coach/ProfileParent/TargetCountries",
},
{
  path: "/coach/profile-parent/target-schools",
  name: "BackOffice/Coach/ProfileParent/TargetSchools",
},
{
  path: "/coach/profile-parent/target-programs",
  name: "BackOffice/Coach/ProfileParent/TargetPrograms",
},
{
  path: "/coach/profile-parent/financial-aid",
  name: "BackOffice/Coach/ProfileParent/FinancialAid",
},
{
  path: "/coach/profile-parent/scholarship-requests",
  name: "BackOffice/Coach/ProfileParent/ScholarshipRequests",
},
{
  path: "/coach/financials/coin-balance",
  name: "BackOffice/Instructor/Financials/CoinBalance",
},
{
  path: "/coach/financials/payments-received",
  name: "BackOffice/Instructor/Financials/PaymentsReceived",
},
{
  path: "/coach/financials/performance",
  name: "BackOffice/Instructor/Financials/Performance",
},
{
  path: "/coach/financials/settings",
  name: "BackOffice/Instructor/Financials/Settings",
},
{
  path: "/coach/financials/tax-filings",
  name: "BackOffice/Instructor/Financials/TaxFilings",
},
{
  path: "/coach/complaints-and-suggestions/open-complaints",
  name: "BackOffice/Instructor/ComplaintsandSuggestions/OpenComplaints",
},
{
  path: "/coach/complaints-and-suggestions/complaints-history",
  name: "BackOffice/Instructor/ComplaintsandSuggestions/ComplaintsHistory",
},
{
  path: "/coach/complaints-and-suggestions/new-complaint",
  name: "BackOffice/Instructor/ComplaintsandSuggestions/NewComplaint",
},
{
  path: "/coach/complaints-and-suggestions/new-suggestion",
  name: "BackOffice/Instructor/ComplaintsandSuggestions/NewSuggestion",
},
{
  path: "/coach/reports/entire-coaching-period-reviews-received",
  name: "BackOffice/Instructor/ReportsandReviews/EntirePeriodReviewsReceived",
},
{
  path: "/coach/reports/pending-coaching-reports",
  name: "BackOffice/Instructor/ReportsandReviews/PendingCoachingReports",
},
{
  path: "/coach/reports/previous-coaching-reports",
  name: "BackOffice/Instructor/ReportsandReviews/PreviousCoachingReports",
},
{
  path: "/coach/reports/reviews-on-coach-profile",
  name: "BackOffice/Instructor/ReportsandReviews/ReviewsonCoachProfile",
},
{
  path: "/coach/reports/satisfaction-survey",
  name: "BackOffice/Instructor/ReportsandReviews/SatisfactionSurvey",
},
{
  path: "/coach/reports/coaching-session-reviews-received",
  name: "BackOffice/Instructor/ReportsandReviews/CoachingSessionReviewsReceived",
},
{
  path: "/coach/reports/recommendation-letter-request",
  name: "BackOffice/Instructor/ReportsandReviews/RecommendationLetterRequest",
},
{
  path: "/coach/manage-student/overview-of-my-students",
  name: "BackOffice/Coach/ManageStudent/OverviewofStudents",
},
{
  path: "/coach/manage-student/select-user",
  name: "BackOffice/Coach/ManageStudent/SelectUser",
},
{
  path: "/coach/manage-student/calendar/view-student-calendar",
  name: "BackOffice/Coach/ManageStudent/CalenderStudent/ViewStudentCalender",
},
{
  path: "/coach/manage-student/complaints-and-tech-cases/complaints-history",
  name: "BackOffice/Coach/ManageStudent/ComplaintsandTechCases/ComplaintsHistory",
},
{
  path: "/coach/manage-student/complaints-and-tech-cases/view-complaints",
  name: "BackOffice/Coach/ManageStudent/ComplaintsandTechCases/ViewComplaints",
},
{
  path: "/coach/manage-student/complaints-and-tech-cases/tech-cases-history",
  name: "BackOffice/Coach/ManageStudent/ComplaintsandTechCases/TechCasesHistory",
},
{
  path: "/coach/manage-student/complaints-and-tech-cases/view-tech-cases",
  name: "BackOffice/Coach/ManageStudent/ComplaintsandTechCases/ViewTechCases",
},
{
  path: "/coach/manage-student/profile-student/education",
  name: "BackOffice/Coach/ManageStudent/ProfileStudent/Education",
},
{
  path: "/coach/manage-student/profile-student/future-courses",
  name: "BackOffice/Coach/ManageStudent/ProfileStudent/FutureCourses",
},
{
  path: "/coach/manage-student/profile-student/ongoing-courses",
  name: "BackOffice/Coach/ManageStudent/ProfileStudent/OngoingCourses",
},
{
  path: "/coach/manage-student/profile-student/personal-info",
  name: "BackOffice/Coach/ManageStudent/ProfileStudent/PersonalInfo",
},
{
  path: "/coach/manage-student/profile-student/target-grades",
  name: "BackOffice/Coach/ManageStudent/ProfileStudent/TargetGrades",
},
{
  path: "/coach/manage-student/student-performance/coach-overall-report",
  name: "BackOffice/Coach/ManageStudent/StudentPerformance/CoachOverallReport",
},
{
  path: "/coach/manage-student/student-performance/lesson-reports",
  name: "BackOffice/Coach/ManageStudent/StudentPerformance/LessonReports",
},
{
  path: "/coach/manage-student/student-performance/lesson-reviews",
  name: "BackOffice/Coach/ManageStudent/StudentPerformance/LessonReviews",
},
{
  path: "/coach/manage-student/student-performance/request-course-review",
  name: "BackOffice/Coach/ManageStudent/StudentPerformance/RequestCourseReview",
},
{
  path: "/coach/manage-student/student-performance/request-satisfaction-survey",
  name: "BackOffice/Coach/ManageStudent/StudentPerformance/RequestSatisfactionSurvey",
},
{
  path: "/coach/manage-student/student-performance/send-lesson-reminder",
  name: "BackOffice/Coach/ManageStudent/StudentPerformance/SendLessonReminder",
},
{
  path: "/coach/manage-student/student-performance/view-interactions",
  name: "BackOffice/Coach/ManageStudent/StudentPerformance/ViewInteractions",
},

{
  path: "/coach/manage-parent/overview-of-my-parents",
  name: "BackOffice/Coach/ManageParent/OverviewofParents",
},
{
  path: "/coach/manage-parent/select-user",
  name: "BackOffice/Coach/ManageParent/SelectUser",
},
{
  path: "/coach/manage-parent/calendar/view-parent-calender",
  name: "BackOffice/Coach/ManageParent/Calender/ViewParentCalender",
},
{
  path: "/coach/manage-parent/complaints-and-tech-cases/complaints-history",
  name: "BackOffice/Coach/ManageParent/ComplaintsandTechCases/ComplaintsHistory",
},
{
  path: "/coach/manage-parent/complaints-and-tech-cases/view-complaints",
  name: "BackOffice/Coach/ManageParent/ComplaintsandTechCases/ViewComplaints",
},
{
  path: "/coach/manage-parent/complaints-and-tech-cases/tech-cases-history",
  name: "BackOffice/Coach/ManageParent/ComplaintsandTechCases/TechCasesHistory",
},
{
  path: "/coach/manage-parent/complaints-and-tech-cases/view-tech-cases",
  name: "BackOffice/Coach/ManageParent/ComplaintsandTechCases/ViewTechCases",
},
{
  path: "/coach/manage-parent/profile/education",
  name: "BackOffice/Coach/ManageParent/Profile/Education",
},
{
  path: "/coach/manage-parent/profile/personal-info",
  name: "BackOffice/Coach/ManageParent/Profile/PersonalInfo",
},
{
  path: "/coach/manage-parent/performance/coach-overall-report",
  name: "BackOffice/Coach/ManageParent/Performance/CoachOverallReport",
},
{
  path: "/coach/manage-parent/performance/lesson-reports",
  name: "BackOffice/Coach/ManageParent/Performance/LessonReports",
},
{
  path: "/coach/manage-parent/performance/view-interactions",
  name: "BackOffice/Coach/ManageParent/Performance/ViewInteractions",
},
{
  path: "/coach/manage-parent/reviews-and-satisfaction/request-course-review",
  name: "BackOffice/Coach/ManageParent/ReviewsandSatisfaction/RequestCourseReview",
},
{
  path: "/coach/manage-parent/reviews-and-satisfaction/request-satisfaction-survey",
  name: "BackOffice/Coach/ManageParent/ReviewsandSatisfaction/RequestSatisfactionSurvey",
},
{
  path: "/coach/manage-parent/reviews-and-satisfaction/reviews-and-ratings",
  name: "BackOffice/Coach/ManageParent/ReviewsandSatisfaction/ReviewsandRatings",
},
{
  path: "/coach/manage-parent/reviews-and-satisfaction/request-review",
  name: "BackOffice/Coach/ManageParent/ReviewsandSatisfaction/RequestReview",
},
{
  path: "/coach/manage-parent/financials/settings",
  name: "BackOffice/Coach/ManageParent/Financials/Settings",
},
{
  path: "/coach/manage-parent/financials/payments-made",
  name: "BackOffice/Coach/ManageParent/Financials/PaymentsMade",
},
{
  path: "/coach/manage-parent/financials/coin-balance",
  name: "BackOffice/Coach/ManageParent/Financials/CoinBalance",
},
{
  path: "/coach/manage-parent/financials/invoices",
  name: "BackOffice/Coach/ManageParent/Financials/Invoices",
},

//ManageInstructor///////////////////////////////////////////////////////////
{
  path: "/coach/manage-instructor/assign-student",
  name: "BackOffice/Coach/ManageInstructor/AssignStudent",
},
{
  path: "/coach/manage-instructor/overview-of-my-instructors",
  name: "BackOffice/Coach/ManageInstructor/OverviewofMyInstructors",
},
{
  path: "/coach/manage-instructor/select-user",
  name: "BackOffice/Coach/ManageInstructor/SelectUser",
},
{
  path: "/coach/manage-instructor/profile/personal-info",
  name: "BackOffice/Coach/ManageInstructor/Profile/PersonalInfo",
},
{
  path: "/coach/manage-instructor/profile/introduction",
  name: "BackOffice/Coach/ManageInstructor/Profile/Introduction",
},
{
  path: "/coach/manage-instructor/profile/education",
  name: "BackOffice/Coach/ManageInstructor/Profile/Education",
},
{
  path: "/coach/manage-instructor/profile/professional-experience",
  name: "BackOffice/Coach/ManageInstructor/Profile/ProfExp",
},
{
  path: "/coach/manage-instructor/profile/teaching-skills",
  name: "BackOffice/Coach/ManageInstructor/Profile/TeachingSkills",
},
{
  path: "/coach/manage-instructor/profile/additional-qualifications",
  name: "BackOffice/Coach/ManageInstructor/Profile/AdditionalQualifi",
},
{
  path: "/coach/manage-instructor/profile/awards-and-honors",
  name: "BackOffice/Coach/ManageInstructor/Profile/AwardsandHonors",
},
{
  path: "/coach/manage-instructor/profile/languages",
  name: "BackOffice/Coach/ManageInstructor/Profile/Languages",
},
{
  path: "/coach/manage-instructor/profile/notes",
  name: "BackOffice/Coach/ManageInstructor/Profile/Notes",
},
{
  path: "/coach/manage-instructor/profile/publication",
  name: "BackOffice/Coach/ManageInstructor/Profile/Publication",
},
{
  path: "/coach/manage-instructor/profile/reviews",
  name: "BackOffice/Coach/ManageInstructor/Profile/Reviews",
},
{
  path: "/coach/manage-instructor/profile/seo-keywords",
  name: "BackOffice/Coach/ManageInstructor/Profile/SEOKeywords",
},
{
  path: "/coach/manage-instructor/profile/teaching-philosophy",
  name: "BackOffice/Coach/ManageInstructor/Profile/TeachingPhilosophy",
},
{
  path: "/coach/manage-instructor/profile/test-taken",
  name: "BackOffice/Coach/ManageInstructor/Profile/TestTaken",
},
{
  path: "/coach/manage-instructor/profile/track-record",
  name: "BackOffice/Coach/ManageInstructor/Profile/TrackRecord",
},
{
  path: "/coach/manage-instructor/profile/past-placements",
  name: "BackOffice/Coach/ManageInstructor/Profile/PastPlacements",
},
{
  path: "/coach/manage-instructor/calendar/view-instructor-calendar",
  name: "BackOffice/Coach/ManageInstructor/Calender/ViewInstructorCalender",
},
{
  path: "/coach/manage-instructor/performance/financial-performance",
  name: "BackOffice/Coach/ManageInstructor/Performance/FinancialPerformance",
},
{
  path: "/coach/manage-instructor/performance/view-interactions",
  name: "BackOffice/Coach/ManageInstructor/Performance/ViewInteractions",
},
{
  path: "/coach/manage-instructor/complaints/view-complaints",
  name: "BackOffice/Coach/ManageInstructor/Complaints/ViewComplaints",
},
{
  path: "/coach/manage-instructor/complaints/complaints-history",
  name: "BackOffice/Coach/ManageInstructor/Complaints/ComplaintsHistory",
},
{
  path: "/coach/manage-instructor/financials/settings",
  name: "BackOffice/Coach/ManageInstructor/Financials/Settings",
},
{
  path: "/coach/manage-instructor/financials/coin-balance",
  name: "BackOffice/Coach/ManageInstructor/Financials/CoinBalance",
},
{
  path: "/coach/manage-instructor/financials/performance",
  name: "BackOffice/Coach/ManageInstructor/Financials/Performance",
},
{
  path: "/coach/manage-instructor/financials/tax-forms",
  name: "BackOffice/Coach/ManageInstructor/Financials/TaxForms",
},
{
  path: "/coach/manage-instructor/financials/send-payroll",
  name: "BackOffice/Coach/ManageInstructor/Financials/SendPayroll",
},
{
  path: "/coach/manage-instructor/financials/bank-payments-received",
  name: "BackOffice/Coach/ManageInstructor/Financials/BankPaymentsReceived",
},
{
  path: "/coach/manage-instructor/reports/course-reviews-received",
  name: "BackOffice/Coach/ManageInstructor/ReportsandReviews/CourseReviewsReceived",
},
{
  path: "/coach/manage-instructor/reports/pending-instructor-reports",
  name: "BackOffice/Coach/ManageInstructor/ReportsandReviews/PendingInstructorReports",
},
{
  path: "/coach/manage-instructor/reports/previous-instructor-reports",
  name: "BackOffice/Coach/ManageInstructor/ReportsandReviews/PreviousInstructorReports",
},
{
  path: "/coach/manage-instructor/reports/reviews-on-instructor-profile",
  name: "BackOffice/Coach/ManageInstructor/ReportsandReviews/ReviewsonInstructorProfile",
},
{
  path: "/coach/manage-instructor/reports/satisfaction-survey",
  name: "BackOffice/Coach/ManageInstructor/ReportsandReviews/SatisfactionSurvey",
},
{
  path: "/coach/manage-instructor/reports/lesson-review-received",
  name: "BackOffice/Coach/ManageInstructor/ReportsandReviews/LessonReviewsReceived",
},
{
  path: "/coach/manage-instructor/reports/recommendation-letter-request",
  name: "BackOffice/Coach/ManageInstructor/ReportsandReviews/RecommendationLetterRequest",
},
{
  path: "/coach/manage-instructor/settings/financial-ratios",
  name: "BackOffice/Coach/ManageInstructor/Settings/FinancialRatios",
},
{
  path: "/coach/manage-instructor/settings/webpage-privilages",
  name: "BackOffice/Coach/ManageInstructor/Settings/WebpagePrivilages",
},
{
  path: "/coach/manage-instructor/manage-subject-page/expectations",
  name: "BackOffice/Coach/ManageInstructor/ManageSubjectPage/Expectations",
},
{
  path: "/coach/manage-instructor/manage-subject-page/instructor-profiles",
  name: "BackOffice/Coach/ManageInstructor/ManageSubjectPage/InstructorProfiles",
},
{
  path: "/coach/manage-instructor/manage-subject-page/introduction",
  name: "BackOffice/Coach/ManageInstructor/ManageSubjectPage/Introduction",
},
{
  path: "/coach/manage-instructor/manage-subject-page/major-topics",
  name: "BackOffice/Coach/ManageInstructor/ManageSubjectPage/MajorTopics",
},
{
  path: "/coach/manage-instructor/manage-subject-page/testimonials-and-reviews",
  name: "BackOffice/Coach/ManageInstructor/ManageSubjectPage/TestimonialsandReviews",
},
{
  path: "/coach/manage-instructor/manage-subject-page/tips-and-tricks",
  name: "BackOffice/Coach/ManageInstructor/ManageSubjectPage/TipsandTricks",
},
{
  path: "/coach/manage-instructor/manage-subject-page/who-should-take-this-subject",
  name: "BackOffice/Coach/ManageInstructor/ManageSubjectPage/WhoShouldTakeThisSubject",
},

//ManageConsultant///////////////////////////////////////////////////////////
{
  path: "/coach/manage-consultant/assign-student",
  name: "BackOffice/Coach/ManageConsultant/AssignStudent",
},
{
  path: "/coach/manage-consultant/overview-of-my-consultant",
  name: "BackOffice/Coach/ManageConsultant/OverviewofMyConsultant",
},
{
  path: "/coach/manage-consultant/select-user",
  name: "BackOffice/Coach/ManageConsultant/SelectUser",
},
{
  path: "/coach/manage-consultant/profile/personal-info",
  name: "BackOffice/Coach/ManageConsultant/Profile/PersonalInfo",
},
{
  path: "/coach/manage-consultant/profile/introduction",
  name: "BackOffice/Coach/ManageConsultant/Profile/Introduction",
},
{
  path: "/coach/manage-consultant/profile/education",
  name: "BackOffice/Coach/ManageConsultant/Profile/Education",
},
{
  path: "/coach/manage-consultant/profile/professional-experience",
  name: "BackOffice/Coach/ManageConsultant/Profile/ProfExp",
},
{
  path: "/coach/manage-consultant/profile/skills",
  name: "BackOffice/Coach/ManageConsultant/Profile/Skills",
},
{
  path: "/coach/manage-consultant/profile/additional-qualifications",
  name: "BackOffice/Coach/ManageConsultant/Profile/AdditionalQualifi",
},
{
  path: "/coach/manage-consultant/profile/awards-and-honors",
  name: "BackOffice/Coach/ManageConsultant/Profile/AwardsandHonors",
},
{
  path: "/coach/manage-consultant/profile/languages",
  name: "BackOffice/Coach/ManageConsultant/Profile/Languages",
},
{
  path: "/coach/manage-consultant/profile/notes",
  name: "BackOffice/Coach/ManageConsultant/Profile/Notes",
},
{
  path: "/coach/manage-consultant/profile/publication",
  name: "BackOffice/Coach/ManageConsultant/Profile/Publication",
},
{
  path: "/coach/manage-consultant/profile/reviews",
  name: "BackOffice/Coach/ManageConsultant/Profile/Reviews",
},
{
  path: "/coach/manage-consultant/profile/seo-keywords",
  name: "BackOffice/Coach/ManageConsultant/Profile/SEOKeywords",
},
{
  path: "/coach/manage-consultant/profile/consultant-philosophy",
  name: "BackOffice/Coach/ManageConsultant/Profile/ConsultantPhilosophy",
},
{
  path: "/coach/manage-consultant/profile/test-taken",
  name: "BackOffice/Coach/ManageConsultant/Profile/TestTaken",
},
{
  path: "/coach/manage-consultant/profile/track-record",
  name: "BackOffice/Coach/ManageConsultant/Profile/TrackRecord",
},
{
  path: "/coach/manage-consultant/profile/past-placements",
  name: "BackOffice/Coach/ManageConsultant/Profile/PastPlacements",
},
{
  path: "/coach/manage-consultant/calendar/view-consultant-calendar",
  name: "BackOffice/Coach/ManageConsultant/Calender/ViewConsultantCalender",
},
{
  path: "/coach/manage-consultant/performance/financial-performance",
  name: "BackOffice/Coach/ManageConsultant/Performance/FinancialPerformance",
},
{
  path: "/coach/manage-consultant/performance/view-interactions",
  name: "BackOffice/Coach/ManageConsultant/Performance/ViewInteractions",
},
{
  path: "/coach/manage-consultant/complaints/view-complaints",
  name: "BackOffice/Coach/ManageConsultant/Complaints/ViewComplaints",
},
{
  path: "/coach/manage-consultant/complaints/complaints-history",
  name: "BackOffice/Coach/ManageConsultant/Complaints/ComplaintsHistory",
},
{
  path: "/coach/manage-consultant/reports/entire-consultant-period-review-received",
  name: "BackOffice/Coach/ManageConsultant/ReportsandReviews/EntireConsultantPeriodReviewReceived",
},
{
  path: "/coach/manage-consultant/reports/pending-consultant-reports",
  name: "BackOffice/Coach/ManageConsultant/ReportsandReviews/PendingConsultantReports",
},
{
  path: "/coach/manage-consultant/reports/previous-consultant-reports",
  name: "BackOffice/Coach/ManageConsultant/ReportsandReviews/PreviousConsultantReports",
},
{
  path: "/coach/manage-consultant/reports/reviews-on-consultant-profile",
  name: "BackOffice/Coach/ManageConsultant/ReportsandReviews/ReviewsonConsultantProfile",
},
{
  path: "/coach/manage-consultant/reports/satisfaction-survey",
  name: "BackOffice/Coach/ManageConsultant/ReportsandReviews/SatisfactionSurvey",
},
{
  path: "/coach/manage-consultant/reports/session-review-received",
  name: "BackOffice/Coach/ManageConsultant/ReportsandReviews/SessionReviewReceived",
},
{
  path: "/coach/manage-consultant/settings/financial-ratios",
  name: "BackOffice/Coach/ManageConsultant/Settings/FinancialRatios",
},
{
  path: "/coach/manage-consultant/settings/webpage-privilages",
  name: "BackOffice/Coach/ManageConsultant/Settings/WebpagePrivilages",
},

{
  path: "/coach/student-progress-report-card/grades-and-scores/ap",
  name: "BackOffice/Coach/StudentProgressReportCard/GradesandScores/AP",
},
{
  path: "/coach/student-progress-report-card/grades-and-scores/toefl",
  name: "BackOffice/Coach/StudentProgressReportCard/GradesandScores/TOEFL",
},
{
  path: "/coach/student-progress-report-card/grades-and-scores/mathematics",
  name: "BackOffice/Coach/StudentProgressReportCard/GradesandScores/Mathematics",
},
{
  path: "/coach/student-progress-report-card/grades-and-scores/microeconomics",
  name: "BackOffice/Coach/StudentProgressReportCard/GradesandScores/Microeconomics",
},
{
  path: "/coach/student-progress-report-card/new-actual-score",
  name: "BackOffice/Coach/StudentProgressReportCard/NewActualScore",
},
{
  path: "/coach/student-progress-report-card/new-predicted-score",
  name: "BackOffice/Coach/StudentProgressReportCard/NewPredictedScore",
},
{
  path: "/coach/student-progress-report-card/overview-of-my-instructors",
  name: "BackOffice/Coach/StudentProgressReportCard/OverviewofMyInstructors",
},
{
  path: "/coach/student-progress-report-card/select-student",
  name: "BackOffice/Coach/StudentProgressReportCard/SelectStudent",
},
{
  path: "/coach/video-chat/phone-video-chat-calendar",
  name: "BackOffice/Coach/VideoChat/PhoneVideoChatCalender",
},
{
  path: "/coach/video-chat/select-user",
  name: "BackOffice/Coach/VideoChat/SelectUser",
},
{
  path: "/coach/video-chat/student-assigned",
  name: "BackOffice/Coach/VideoChat/StudentAssigned",
},
{
  path: "/coach/video-chat/phone-video-chat-handling",
  name: "BackOffice/Coach/VideoChat/PhoneVideoChatHandling",
},
{
  path: "/coach/video-chat/registrations",
  name: "BackOffice/Coach/VideoChat/Registrations",
},
{
  path: "/coach/video-chat/financial-performance",
  name: "BackOffice/Coach/VideoChat/FinancialPerformance",
},
{
  path: "/coach/video-chat/missed-calls",
  name: "BackOffice/Coach/VideoChat/MissedCalls",
},
{
  path: "/coach/video-chat/missed-calls-reports",
  name: "BackOffice/Coach/VideoChat/MissedCallsReports",
},
{
  path: "/coach/handle-complaints/open-complaints",
  name: "BackOffice/Coach/HandleComplaints/OpenComplaints",
},
{
  path: "/coach/handle-complaints/complaint-history",
  name: "BackOffice/Coach/HandleComplaints/ComplaintHistory",
},
{
  path: "/coach/handle-complaints/escalated-to-supervisor",
  name: "BackOffice/Coach/HandleComplaints/EscalatedToSupervisor",
},
{
  path: "/coach/handle-complaints/escalated-to-admin",
  name: "BackOffice/Coach/HandleComplaints/EscalatedToAdmin",
},
{
  path: "/coach/tech-support/closed-cases",
  name: "BackOffice/Coach/TechSupport/CasesForAdmin/ClosedCases",
},
{
  path: "/coach/tech-support/new-case",
  name: "BackOffice/Coach/TechSupport/CasesForAdmin/NewCase",
},
{
  path: "/coach/tech-support/existing-cases",
  name: "BackOffice/Coach/TechSupport/CasesForAdmin/ExistingCases",
},
{
  path: "/coach/tech-support/search-faq",
  name: "BackOffice/Coach/TechSupport/SearchFAQ",
},
{
  path: "/coach/tech-support/search-knowledge-base",
  name: "BackOffice/Coach/TechSupport/SearchKnowledgeBase",
},
{
  path: "/coach/application-package-content/application-package-content",
  name: "BackOffice/Coach/APC/APC",
},
{
  path: "/coach/application-package-content/education",
  name: "BackOffice/Coach/APC/Education",
},
{
  path: "/coach/application-package-content/recommendation-letters",
  name: "BackOffice/Coach/APC/RecommendationLetters",
},
{
  path: "/coach/application-package-content/test-scores",
  name: "BackOffice/Coach/APC/TestScores",
},
{
  path: "/coach/application-package-content/financial-docs",
  name: "BackOffice/Coach/APC/FinancialDocs",
},
{
  path: "/coach/application-package-content/financial-docs",
  name: "BackOffice/Coach/APC/FinancialDocs",
},
{
  path: "/coach/application-package-content/custom-elements/harvard",
  name: "BackOffice/Coach/APC/CustomElements/Harvard",
},
{
  path: "/coach/application-package-content/custom-elements/introduction",
  name: "BackOffice/Coach/APC/CustomElements/Introduction",
},
{
  path: "/coach/application-package-content/custom-elements/cv",
  name: "BackOffice/Coach/APC/CustomElements/CV",
},
{
  path: "/coach/application-package-content/custom-elements/essays/essay-1",
  name: "BackOffice/Coach/APC/CustomElements/Essays/Essay1",
},
{
  path: "/coach/application-package-content/custom-elements/essays/essay-2",
  name: "BackOffice/Coach/APC/CustomElements/Essays/Essay2",
},
{
  path: "/coach/application-package-content/custom-elements/scholarship-essays/essay-1",
  name: "BackOffice/Coach/APC/CustomElements/ScholarshipEssays/Essay1",
},
{
  path: "/coach/application-package-content/custom-elements/scholarship-essays/essay-2",
  name: "BackOffice/Coach/APC/CustomElements/ScholarshipEssays/Essay2",
},

{
  path: "/coach/application-package-content/custom-elements/misc-docs",
  name: "BackOffice/Coach/APC/CustomElements/MiscDocs",
},
{
  path: "/coach/application-package-content/custom-elements/diary",
  name: "BackOffice/Coach/APC/CustomElements/Diary",
},
{
  path: "/coach/application-package-content/custom-elements/notes-for-this-school/MIT",
  name: "BackOffice/Coach/APC/CustomElements/NotesForThisSchool/MIT",
},
{
  path: "/coach/application-package-content/custom-elements/notes-for-this-school/stanford",
  name: "BackOffice/Coach/APC/CustomElements/NotesForThisSchool/Stanford",
},
{
  path: "/coach/application-package-content/custom-elements/school-applications/applied",
  name: "BackOffice/Coach/APC/CustomElements/SchoolApplications/Applied",
},
{
  path: "/coach/application-package-content/custom-elements/school-applications/under-review",
  name: "BackOffice/Coach/APC/CustomElements/SchoolApplications/UnderReview",
},
{
  path: "/coach/application-package-content/custom-elements/school-applications/offers",
  name: "BackOffice/Coach/APC/CustomElements/SchoolApplications/Offers",
},
{
  path: "/coach/application-package-content/custom-elements/school-applications/placed",
  name: "BackOffice/Coach/APC/CustomElements/SchoolApplications/Placed",
},
{
  path: "/coach/application-package-content/custom-elements/school-applications/notes",
  name: "BackOffice/Coach/APC/CustomElements/SchoolApplications/Notes",
},
{
  path: "/coach/application-package-content/past-packages",
  name: "BackOffice/Coach/APC/PastPackages",
},

//BackOffice Coach routes endpoint ----------------------------------------------------------------------------------------------

//BackOffice Academic Coach routes starts -----------------------------------------------------------------------------------

{
  path: "/academic-coach",
  name: "BackOffice/Coach/Dashboard",
},
{
  path: "/academic-coach/profile/personal-info",
  name: "BackOffice/Coach/Profile/PersonalInfo",
},
{
  path: "/academic-coach/profile/introduction",
  name: "BackOffice/Coach/Profile/Introduction",
},
{
  path: "/academic-coach/profile/education",
  name: "BackOffice/Coach/Profile/Education",
},
{
  path: "/academic-coach/profile/professional-experience",
  name: "BackOffice/Coach/Profile/ProfExp",
},
{
  path: "/academic-coach/profile/skills",
  name: "BackOffice/Coach/Profile/Skills",
},
{
  path: "/academic-coach/profile/additional-qualifications",
  name: "BackOffice/Coach/Profile/AdditionalQualifi",
},
{
  path: "/academic-coach/profile/awards-and-honors",
  name: "BackOffice/Coach/Profile/AwardsandHonors",
},
{
  path: "/academic-coach/profile/languages",
  name: "BackOffice/Coach/Profile/Languages",
},
{
  path: "/academic-coach/profile/notes",
  name: "BackOffice/Coach/Profile/Notes",
},
{
  path: "/academic-coach/profile/publication",
  name: "BackOffice/Coach/Profile/Publication",
},
{
  path: "/academic-coach/profile/tests-taken",
  name: "BackOffice/Coach/Profile/TestTaken",
},
{
  path: "/academic-coach/profile/coaching-philosophy",
  name: "BackOffice/Coach/Profile/CoachingPhilosophy",
},
{
  path: "/academic-coach/profile/track-record",
  name: "BackOffice/Coach/Profile/TrackRecord",
},
{
  path: "/academic-coach/profile/seo-keywords",
  name: "BackOffice/Coach/Profile/SEOKeywords",
},
{
  path: "/academic-coach/profile/past-placements",
  name: "BackOffice/Coach/Profile/PastPlacements",
},
{
  path: "/academic-coach/profile/reviews",
  name: "BackOffice/Coach/Profile/Reviews",
},
{
  path: "/academic-coach/live-meeting/new-meeting",
  name: "BackOffice/Coach/LiveMeeting/NewMeeting",
},
{
  path: "/academic-coach/live-meeting/settings",
  name: "BackOffice/Coach/LiveMeeting/Settings",
},
{
  path: "/academic-coach/messages/inbox",
  name: "BackOffice/Coach/Messages/Inbox",
},
{
  path: "/academic-coach/messages/create-message",
  name: "BackOffice/Coach/Messages/CreateMessage",
},
{
  path: "/academic-coach/messages/drafts",
  name: "BackOffice/Coach/Messages/Drafts",
},
{
  path: "/academic-coach/messages/message-settings",
  name: "BackOffice/Coach/Messages/Message_Settings",
},
{
  path: "/academic-coach/schedule/my-schedule",
  name: "BackOffice/Coach/Schedule/MySchedule",
},
{
  path: "/academic-coach/select-user",
  name: "BackOffice/Coach/SelectUser",
},
{
  path: "/academic-coach/registration/user-registration",
  name: "BackOffice/Coach/Registration/UserRegistration",
},
{
  path: "/academic-coach/registration/add-new-user",
  name: "BackOffice/Coach/Registration/AddNewUser",
},
{
  path: "/academic-coach/profile-student/personal-info",
  name: "BackOffice/Coach/ProfileStudent/PersonalInfo",
},
{
  path: "/academic-coach/profile-student/introduction",
  name: "BackOffice/Coach/ProfileStudent/Introduction",
},
{
  path: "/academic-coach/profile-student/education",
  name: "BackOffice/Coach/ProfileStudent/Education",
},
{
  path: "/academic-coach/profile-student/professional-experience",
  name: "BackOffice/Coach/ProfileStudent/ProfExp",
},
{
  path: "/academic-coach/profile-student/skills",
  name: "BackOffice/Coach/ProfileStudent/Skills",
},
{
  path: "/academic-coach/profile-student/additional-qualifications",
  name: "BackOffice/Coach/ProfileStudent/AdditionalQualifi",
},
{
  path: "/academic-coach/profile-student/awards-and-honors",
  name: "BackOffice/Coach/ProfileStudent/AwardsandHonors",
},
{
  path: "/academic-coach/profile-student/languages",
  name: "BackOffice/Coach/ProfileStudent/Languages",
},
{
  path: "/academic-coach/profile-student/notes",
  name: "BackOffice/Coach/ProfileStudent/Notes",
},
{
  path: "/academic-coach/profile-student/publication",
  name: "BackOffice/Coach/ProfileStudent/Publication",
},
{
  path: "/academic-coach/profile-student/reviews",
  name: "BackOffice/Coach/ProfileStudent/Reviews",
},
{
  path: "/academic-coach/profile-student/seo-keywords",
  name: "BackOffice/Coach/ProfileStudent/SEOKeywords",
},
{
  path: "/academic-coach/profile-student/teaching-philosophy",
  name: "BackOffice/Coach/ProfileStudent/TeachingPhilosophy",
},
{
  path: "/academic-coach/profile-student/tests-taken",
  name: "BackOffice/Coach/ProfileStudent/TestTaken",
},
{
  path: "/academic-coach/profile-student/track-record",
  name: "BackOffice/Coach/ProfileStudent/TrackRecord",
},
{
  path: "/academic-coach/profile-student/subject-expertise",
  name: "BackOffice/Coach/ProfileStudent/SubjectExpertise",
},
{
  path: "/academic-coach/profile-parent/personal-info",
  name: "BackOffice/Coach/ProfileParent/PersonalInfo",
},
{
  path: "/academic-coach/profile-parent/education",
  name: "BackOffice/Coach/ProfileParent/Education",
},
{
  path: "/academic-coach/profile-parent/languages",
  name: "BackOffice/Coach/ProfileParent/Languages",
},
{
  path: "/academic-coach/profile-parent/notes",
  name: "BackOffice/Coach/ProfileParent/Notes",
},
{
  path: "/academic-coach/profile-parent/target-countries",
  name: "BackOffice/Coach/ProfileParent/TargetCountries",
},
{
  path: "/academic-coach/profile-parent/target-schools",
  name: "BackOffice/Coach/ProfileParent/TargetSchools",
},
{
  path: "/academic-coach/profile-parent/target-programs",
  name: "BackOffice/Coach/ProfileParent/TargetPrograms",
},
{
  path: "/academic-coach/profile-parent/financial-aid",
  name: "BackOffice/Coach/ProfileParent/FinancialAid",
},
{
  path: "/academic-coach/profile-parent/scholarship-requests",
  name: "BackOffice/Coach/ProfileParent/ScholarshipRequests",
},
{
  path: "/academic-coach/financials/coin-balance",
  name: "BackOffice/Instructor/Financials/CoinBalance",
},
{
  path: "/academic-coach/financials/payments-received",
  name: "BackOffice/Instructor/Financials/PaymentsReceived",
},
{
  path: "/academic-coach/financials/performance",
  name: "BackOffice/Instructor/Financials/Performance",
},
{
  path: "/academic-coach/financials/settings",
  name: "BackOffice/Instructor/Financials/Settings",
},
{
  path: "/academic-coach/financials/tax-filings",
  name: "BackOffice/Instructor/Financials/TaxFilings",
},
{
  path: "/academic-coach/complaints-and-suggestions/open-complaints",
  name: "BackOffice/Instructor/ComplaintsandSuggestions/OpenComplaints",
},
{
  path: "/academic-coach/complaints-and-suggestions/complaints-history",
  name: "BackOffice/Instructor/ComplaintsandSuggestions/ComplaintsHistory",
},
{
  path: "/academic-coach/complaints-and-suggestions/new-complaint",
  name: "BackOffice/Instructor/ComplaintsandSuggestions/NewComplaint",
},
{
  path: "/academic-coach/complaints-and-suggestions/new-suggestion",
  name: "BackOffice/Instructor/ComplaintsandSuggestions/NewSuggestion",
},
{
  path: "/academic-coach/reports/entire-coaching-period-reviews-received",
  name: "BackOffice/Instructor/ReportsandReviews/EntirePeriodReviewsReceived",
},
{
  path: "/academic-coach/reports/pending-coaching-reports",
  name: "BackOffice/Instructor/ReportsandReviews/PendingCoachingReports",
},
{
  path: "/academic-coach/reports/previous-coaching-reports",
  name: "BackOffice/Instructor/ReportsandReviews/PreviousCoachingReports",
},
{
  path: "/academic-coach/reports/reviews-on-coach-profile",
  name: "BackOffice/Instructor/ReportsandReviews/ReviewsonCoachProfile",
},
{
  path: "/academic-coach/reports/satisfaction-survey",
  name: "BackOffice/Instructor/ReportsandReviews/SatisfactionSurvey",
},
{
  path: "/academic-coach/reports/coaching-session-reviews-received",
  name: "BackOffice/Instructor/ReportsandReviews/CoachingSessionReviewsReceived",
},
{
  path: "/academic-coach/reports/recommendation-letter-request",
  name: "BackOffice/Instructor/ReportsandReviews/RecommendationLetterRequest",
},
{
  path: "/academic-coach/manage-student/overview-of-my-students",
  name: "BackOffice/Coach/ManageStudent/OverviewofStudents",
},
{
  path: "/academic-coach/manage-student/select-user",
  name: "BackOffice/Coach/ManageStudent/SelectUser",
},
{
  path: "/academic-coach/manage-student/calendar/view-student-calendar",
  name: "BackOffice/Coach/ManageStudent/CalenderStudent/ViewStudentCalender",
},
{
  path: "/academic-coach/manage-student/complaints-and-tech-cases/complaints-history",
  name: "BackOffice/Coach/ManageStudent/ComplaintsandTechCases/ComplaintsHistory",
},
{
  path: "/academic-coach/manage-student/complaints-and-tech-cases/view-complaints",
  name: "BackOffice/Coach/ManageStudent/ComplaintsandTechCases/ViewComplaints",
},
{
  path: "/academic-coach/manage-student/complaints-and-tech-cases/tech-cases-history",
  name: "BackOffice/Coach/ManageStudent/ComplaintsandTechCases/TechCasesHistory",
},
{
  path: "/academic-coach/manage-student/complaints-and-tech-cases/view-tech-cases",
  name: "BackOffice/Coach/ManageStudent/ComplaintsandTechCases/ViewTechCases",
},
{
  path: "/academic-coach/manage-student/profile-student/education",
  name: "BackOffice/Coach/ManageStudent/ProfileStudent/Education",
},
{
  path: "/academic-coach/manage-student/profile-student/future-courses",
  name: "BackOffice/Coach/ManageStudent/ProfileStudent/FutureCourses",
},
{
  path: "/academic-coach/manage-student/profile-student/ongoing-courses",
  name: "BackOffice/Coach/ManageStudent/ProfileStudent/OngoingCourses",
},
{
  path: "/academic-coach/manage-student/profile-student/personal-info",
  name: "BackOffice/Coach/ManageStudent/ProfileStudent/PersonalInfo",
},
{
  path: "/academic-coach/manage-student/profile-student/target-grades",
  name: "BackOffice/Coach/ManageStudent/ProfileStudent/TargetGrades",
},
{
  path: "/academic-coach/manage-student/student-performance/coach-overall-report",
  name: "BackOffice/Coach/ManageStudent/StudentPerformance/CoachOverallReport",
},
{
  path: "/academic-coach/manage-student/student-performance/lesson-reports",
  name: "BackOffice/Coach/ManageStudent/StudentPerformance/LessonReports",
},
{
  path: "/academic-coach/manage-student/student-performance/lesson-reviews",
  name: "BackOffice/Coach/ManageStudent/StudentPerformance/LessonReviews",
},
{
  path: "/academic-coach/manage-student/student-performance/request-course-review",
  name: "BackOffice/Coach/ManageStudent/StudentPerformance/RequestCourseReview",
},
{
  path: "/academic-coach/manage-student/student-performance/request-satisfaction-survey",
  name: "BackOffice/Coach/ManageStudent/StudentPerformance/RequestSatisfactionSurvey",
},
{
  path: "/academic-coach/manage-student/student-performance/send-lesson-reminder",
  name: "BackOffice/Coach/ManageStudent/StudentPerformance/SendLessonReminder",
},
{
  path: "/academic-coach/manage-student/student-performance/view-interactions",
  name: "BackOffice/Coach/ManageStudent/StudentPerformance/ViewInteractions",
},

{
  path: "/academic-coach/manage-parent/overview-of-my-parents",
  name: "BackOffice/Coach/ManageParent/OverviewofParents",
},
{
  path: "/academic-coach/manage-parent/select-user",
  name: "BackOffice/Coach/ManageParent/SelectUser",
},
{
  path: "/academic-coach/manage-parent/calendar/view-parent-calender",
  name: "BackOffice/Coach/ManageParent/Calender/ViewParentCalender",
},
{
  path: "/academic-coach/manage-parent/complaints-and-tech-cases/complaints-history",
  name: "BackOffice/Coach/ManageParent/ComplaintsandTechCases/ComplaintsHistory",
},
{
  path: "/academic-coach/manage-parent/complaints-and-tech-cases/view-complaints",
  name: "BackOffice/Coach/ManageParent/ComplaintsandTechCases/ViewComplaints",
},
{
  path: "/academic-coach/manage-parent/complaints-and-tech-cases/tech-cases-history",
  name: "BackOffice/Coach/ManageParent/ComplaintsandTechCases/TechCasesHistory",
},
{
  path: "/academic-coach/manage-parent/complaints-and-tech-cases/view-tech-cases",
  name: "BackOffice/Coach/ManageParent/ComplaintsandTechCases/ViewTechCases",
},
{
  path: "/academic-coach/manage-parent/profile/education",
  name: "BackOffice/Coach/ManageParent/Profile/Education",
},
{
  path: "/academic-coach/manage-parent/profile/personal-info",
  name: "BackOffice/Coach/ManageParent/Profile/PersonalInfo",
},
{
  path: "/academic-coach/manage-parent/performance/coach-overall-report",
  name: "BackOffice/Coach/ManageParent/Performance/CoachOverallReport",
},
{
  path: "/academic-coach/manage-parent/performance/lesson-reports",
  name: "BackOffice/Coach/ManageParent/Performance/LessonReports",
},
{
  path: "/academic-coach/manage-parent/performance/view-interactions",
  name: "BackOffice/Coach/ManageParent/Performance/ViewInteractions",
},
{
  path: "/academic-coach/manage-parent/reviews-and-satisfaction/request-course-review",
  name: "BackOffice/Coach/ManageParent/ReviewsandSatisfaction/RequestCourseReview",
},
{
  path: "/academic-coach/manage-parent/reviews-and-satisfaction/request-satisfaction-survey",
  name: "BackOffice/Coach/ManageParent/ReviewsandSatisfaction/RequestSatisfactionSurvey",
},
{
  path: "/academic-coach/manage-parent/reviews-and-satisfaction/reviews-and-ratings",
  name: "BackOffice/Coach/ManageParent/ReviewsandSatisfaction/ReviewsandRatings",
},
{
  path: "/academic-coach/manage-parent/reviews-and-satisfaction/request-review",
  name: "BackOffice/Coach/ManageParent/ReviewsandSatisfaction/RequestReview",
},
{
  path: "/academic-coach/manage-parent/financials/settings",
  name: "BackOffice/Coach/ManageParent/Financials/Settings",
},
{
  path: "/academic-coach/manage-parent/financials/payments-made",
  name: "BackOffice/Coach/ManageParent/Financials/PaymentsMade",
},
{
  path: "/academic-coach/manage-parent/financials/coin-balance",
  name: "BackOffice/Coach/ManageParent/Financials/CoinBalance",
},
{
  path: "/academic-coach/manage-parent/financials/invoices",
  name: "BackOffice/Coach/ManageParent/Financials/Invoices",
},

//ManageInstructor///////////////////////////////////////////////////////////
{
  path: "/academic-coach/manage-instructor/assign-student",
  name: "BackOffice/Coach/ManageInstructor/AssignStudent",
},
{
  path: "/academic-coach/manage-instructor/overview-of-my-instructors",
  name: "BackOffice/Coach/ManageInstructor/OverviewofMyInstructors",
},
{
  path: "/academic-coach/manage-instructor/select-user",
  name: "BackOffice/Coach/ManageInstructor/SelectUser",
},
{
  path: "/academic-coach/manage-instructor/profile/personal-info",
  name: "BackOffice/Coach/ManageInstructor/Profile/PersonalInfo",
},
{
  path: "/academic-coach/manage-instructor/profile/introduction",
  name: "BackOffice/Coach/ManageInstructor/Profile/Introduction",
},
{
  path: "/academic-coach/manage-instructor/profile/education",
  name: "BackOffice/Coach/ManageInstructor/Profile/Education",
},
{
  path: "/academic-coach/manage-instructor/profile/professional-experience",
  name: "BackOffice/Coach/ManageInstructor/Profile/ProfExp",
},
{
  path: "/academic-coach/manage-instructor/profile/teaching-skills",
  name: "BackOffice/Coach/ManageInstructor/Profile/TeachingSkills",
},
{
  path: "/academic-coach/manage-instructor/profile/additional-qualifications",
  name: "BackOffice/Coach/ManageInstructor/Profile/AdditionalQualifi",
},
{
  path: "/academic-coach/manage-instructor/profile/awards-and-honors",
  name: "BackOffice/Coach/ManageInstructor/Profile/AwardsandHonors",
},
{
  path: "/academic-coach/manage-instructor/profile/languages",
  name: "BackOffice/Coach/ManageInstructor/Profile/Languages",
},
{
  path: "/academic-coach/manage-instructor/profile/notes",
  name: "BackOffice/Coach/ManageInstructor/Profile/Notes",
},
{
  path: "/academic-coach/manage-instructor/profile/publication",
  name: "BackOffice/Coach/ManageInstructor/Profile/Publication",
},
{
  path: "/academic-coach/manage-instructor/profile/reviews",
  name: "BackOffice/Coach/ManageInstructor/Profile/Reviews",
},
{
  path: "/academic-coach/manage-instructor/profile/seo-keywords",
  name: "BackOffice/Coach/ManageInstructor/Profile/SEOKeywords",
},
{
  path: "/academic-coach/manage-instructor/profile/teaching-philosophy",
  name: "BackOffice/Coach/ManageInstructor/Profile/TeachingPhilosophy",
},
{
  path: "/academic-coach/manage-instructor/profile/test-taken",
  name: "BackOffice/Coach/ManageInstructor/Profile/TestTaken",
},
{
  path: "/academic-coach/manage-instructor/profile/track-record",
  name: "BackOffice/Coach/ManageInstructor/Profile/TrackRecord",
},
{
  path: "/academic-coach/manage-instructor/profile/past-placements",
  name: "BackOffice/Coach/ManageInstructor/Profile/PastPlacements",
},
{
  path: "/academic-coach/manage-instructor/calendar/view-instructor-calendar",
  name: "BackOffice/Coach/ManageInstructor/Calender/ViewInstructorCalender",
},
{
  path: "/academic-coach/manage-instructor/performance/financial-performance",
  name: "BackOffice/Coach/ManageInstructor/Performance/FinancialPerformance",
},
{
  path: "/academic-coach/manage-instructor/performance/view-interactions",
  name: "BackOffice/Coach/ManageInstructor/Performance/ViewInteractions",
},
{
  path: "/academic-coach/manage-instructor/complaints/view-complaints",
  name: "BackOffice/Coach/ManageInstructor/Complaints/ViewComplaints",
},
{
  path: "/academic-coach/manage-instructor/complaints/complaints-history",
  name: "BackOffice/Coach/ManageInstructor/Complaints/ComplaintsHistory",
},
{
  path: "/academic-coach/manage-instructor/financials/settings",
  name: "BackOffice/Coach/ManageInstructor/Financials/Settings",
},
{
  path: "/academic-coach/manage-instructor/financials/coin-balance",
  name: "BackOffice/Coach/ManageInstructor/Financials/CoinBalance",
},
{
  path: "/academic-coach/manage-instructor/financials/performance",
  name: "BackOffice/Coach/ManageInstructor/Financials/Performance",
},
{
  path: "/academic-coach/manage-instructor/financials/tax-forms",
  name: "BackOffice/Coach/ManageInstructor/Financials/TaxForms",
},
{
  path: "/academic-coach/manage-instructor/financials/send-payroll",
  name: "BackOffice/Coach/ManageInstructor/Financials/SendPayroll",
},
{
  path: "/academic-coach/manage-instructor/financials/bank-payments-received",
  name: "BackOffice/Coach/ManageInstructor/Financials/BankPaymentsReceived",
},
{
  path: "/academic-coach/manage-instructor/reports/course-reviews-received",
  name: "BackOffice/Coach/ManageInstructor/ReportsandReviews/CourseReviewsReceived",
},
{
  path: "/academic-coach/manage-instructor/reports/pending-instructor-reports",
  name: "BackOffice/Coach/ManageInstructor/ReportsandReviews/PendingInstructorReports",
},
{
  path: "/academic-coach/manage-instructor/reports/previous-instructor-reports",
  name: "BackOffice/Coach/ManageInstructor/ReportsandReviews/PreviousInstructorReports",
},
{
  path: "/academic-coach/manage-instructor/reports/reviews-on-instructor-profile",
  name: "BackOffice/Coach/ManageInstructor/ReportsandReviews/ReviewsonInstructorProfile",
},
{
  path: "/academic-coach/manage-instructor/reports/satisfaction-survey",
  name: "BackOffice/Coach/ManageInstructor/ReportsandReviews/SatisfactionSurvey",
},
{
  path: "/academic-coach/manage-instructor/reports/lesson-review-received",
  name: "BackOffice/Coach/ManageInstructor/ReportsandReviews/LessonReviewsReceived",
},
{
  path: "/academic-coach/manage-instructor/reports/recommendation-letter-request",
  name: "BackOffice/Coach/ManageInstructor/ReportsandReviews/RecommendationLetterRequest",
},
{
  path: "/academic-coach/manage-instructor/settings/financial-ratios",
  name: "BackOffice/Coach/ManageInstructor/Settings/FinancialRatios",
},
{
  path: "/academic-coach/manage-instructor/settings/webpage-privilages",
  name: "BackOffice/Coach/ManageInstructor/Settings/WebpagePrivilages",
},
{
  path: "/academic-coach/manage-instructor/manage-subject-page/expectations",
  name: "BackOffice/Coach/ManageInstructor/ManageSubjectPage/Expectations",
},
{
  path: "/academic-coach/manage-instructor/manage-subject-page/instructor-profiles",
  name: "BackOffice/Coach/ManageInstructor/ManageSubjectPage/InstructorProfiles",
},
{
  path: "/academic-coach/manage-instructor/manage-subject-page/introduction",
  name: "BackOffice/Coach/ManageInstructor/ManageSubjectPage/Introduction",
},
{
  path: "/academic-coach/manage-instructor/manage-subject-page/major-topics",
  name: "BackOffice/Coach/ManageInstructor/ManageSubjectPage/MajorTopics",
},
{
  path: "/academic-coach/manage-instructor/manage-subject-page/testimonials-and-reviews",
  name: "BackOffice/Coach/ManageInstructor/ManageSubjectPage/TestimonialsandReviews",
},
{
  path: "/academic-coach/manage-instructor/manage-subject-page/tips-and-tricks",
  name: "BackOffice/Coach/ManageInstructor/ManageSubjectPage/TipsandTricks",
},
{
  path: "/academic-coach/manage-instructor/manage-subject-page/who-should-take-this-subject",
  name: "BackOffice/Coach/ManageInstructor/ManageSubjectPage/WhoShouldTakeThisSubject",
},

//ManageConsultant///////////////////////////////////////////////////////////
{
  path: "/academic-coach/manage-consultant/assign-student",
  name: "BackOffice/Coach/ManageConsultant/AssignStudent",
},
{
  path: "/academic-coach/manage-consultant/overview-of-my-consultant",
  name: "BackOffice/Coach/ManageConsultant/OverviewofMyConsultant",
},
{
  path: "/academic-coach/manage-consultant/select-user",
  name: "BackOffice/Coach/ManageConsultant/SelectUser",
},
{
  path: "/academic-coach/manage-consultant/profile/personal-info",
  name: "BackOffice/Coach/ManageConsultant/Profile/PersonalInfo",
},
{
  path: "/academic-coach/manage-consultant/profile/introduction",
  name: "BackOffice/Coach/ManageConsultant/Profile/Introduction",
},
{
  path: "/academic-coach/manage-consultant/profile/education",
  name: "BackOffice/Coach/ManageConsultant/Profile/Education",
},
{
  path: "/academic-coach/manage-consultant/profile/professional-experience",
  name: "BackOffice/Coach/ManageConsultant/Profile/ProfExp",
},
{
  path: "/academic-coach/manage-consultant/profile/skills",
  name: "BackOffice/Coach/ManageConsultant/Profile/Skills",
},
{
  path: "/academic-coach/manage-consultant/profile/additional-qualifications",
  name: "BackOffice/Coach/ManageConsultant/Profile/AdditionalQualifi",
},
{
  path: "/academic-coach/manage-consultant/profile/awards-and-honors",
  name: "BackOffice/Coach/ManageConsultant/Profile/AwardsandHonors",
},
{
  path: "/academic-coach/manage-consultant/profile/languages",
  name: "BackOffice/Coach/ManageConsultant/Profile/Languages",
},
{
  path: "/academic-coach/manage-consultant/profile/notes",
  name: "BackOffice/Coach/ManageConsultant/Profile/Notes",
},
{
  path: "/academic-coach/manage-consultant/profile/publication",
  name: "BackOffice/Coach/ManageConsultant/Profile/Publication",
},
{
  path: "/academic-coach/manage-consultant/profile/reviews",
  name: "BackOffice/Coach/ManageConsultant/Profile/Reviews",
},
{
  path: "/academic-coach/manage-consultant/profile/seo-keywords",
  name: "BackOffice/Coach/ManageConsultant/Profile/SEOKeywords",
},
{
  path: "/academic-coach/manage-consultant/profile/consultant-philosophy",
  name: "BackOffice/Coach/ManageConsultant/Profile/ConsultantPhilosophy",
},
{
  path: "/academic-coach/manage-consultant/profile/test-taken",
  name: "BackOffice/Coach/ManageConsultant/Profile/TestTaken",
},
{
  path: "/academic-coach/manage-consultant/profile/track-record",
  name: "BackOffice/Coach/ManageConsultant/Profile/TrackRecord",
},
{
  path: "/academic-coach/manage-consultant/profile/past-placements",
  name: "BackOffice/Coach/ManageConsultant/Profile/PastPlacements",
},
{
  path: "/academic-coach/manage-consultant/calendar/view-consultant-calendar",
  name: "BackOffice/Coach/ManageConsultant/Calender/ViewConsultantCalender",
},
{
  path: "/academic-coach/manage-consultant/performance/financial-performance",
  name: "BackOffice/Coach/ManageConsultant/Performance/FinancialPerformance",
},
{
  path: "/academic-coach/manage-consultant/performance/view-interactions",
  name: "BackOffice/Coach/ManageConsultant/Performance/ViewInteractions",
},
{
  path: "/academic-coach/manage-consultant/complaints/view-complaints",
  name: "BackOffice/Coach/ManageConsultant/Complaints/ViewComplaints",
},
{
  path: "/academic-coach/manage-consultant/complaints/complaints-history",
  name: "BackOffice/Coach/ManageConsultant/Complaints/ComplaintsHistory",
},
{
  path: "/academic-coach/manage-consultant/reports/entire-consultant-period-review-received",
  name: "BackOffice/Coach/ManageConsultant/ReportsandReviews/EntireConsultantPeriodReviewReceived",
},
{
  path: "/academic-coach/manage-consultant/reports/pending-consultant-reports",
  name: "BackOffice/Coach/ManageConsultant/ReportsandReviews/PendingConsultantReports",
},
{
  path: "/academic-coach/manage-consultant/reports/previous-consultant-reports",
  name: "BackOffice/Coach/ManageConsultant/ReportsandReviews/PreviousConsultantReports",
},
{
  path: "/academic-coach/manage-consultant/reports/reviews-on-consultant-profile",
  name: "BackOffice/Coach/ManageConsultant/ReportsandReviews/ReviewsonConsultantProfile",
},
{
  path: "/academic-coach/manage-consultant/reports/satisfaction-survey",
  name: "BackOffice/Coach/ManageConsultant/ReportsandReviews/SatisfactionSurvey",
},
{
  path: "/academic-coach/manage-consultant/reports/session-review-received",
  name: "BackOffice/Coach/ManageConsultant/ReportsandReviews/SessionReviewReceived",
},
{
  path: "/academic-coach/manage-consultant/settings/financial-ratios",
  name: "BackOffice/Coach/ManageConsultant/Settings/FinancialRatios",
},
{
  path: "/academic-coach/manage-consultant/settings/webpage-privilages",
  name: "BackOffice/Coach/ManageConsultant/Settings/WebpagePrivilages",
},

{
  path: "/academic-coach/student-progress-report-card/grades-and-scores/ap",
  name: "BackOffice/Coach/StudentProgressReportCard/GradesandScores/AP",
},
{
  path: "/academic-coach/student-progress-report-card/grades-and-scores/toefl",
  name: "BackOffice/Coach/StudentProgressReportCard/GradesandScores/TOEFL",
},
{
  path: "/academic-coach/student-progress-report-card/grades-and-scores/mathematics",
  name: "BackOffice/Coach/StudentProgressReportCard/GradesandScores/Mathematics",
},
{
  path: "/academic-coach/student-progress-report-card/grades-and-scores/microeconomics",
  name: "BackOffice/Coach/StudentProgressReportCard/GradesandScores/Microeconomics",
},
{
  path: "/academic-coach/student-progress-report-card/new-actual-score",
  name: "BackOffice/Coach/StudentProgressReportCard/NewActualScore",
},
{
  path: "/academic-coach/student-progress-report-card/new-predicted-score",
  name: "BackOffice/Coach/StudentProgressReportCard/NewPredictedScore",
},
{
  path: "/academic-coach/student-progress-report-card/overview-of-my-instructors",
  name: "BackOffice/Coach/StudentProgressReportCard/OverviewofMyInstructors",
},
{
  path: "/academic-coach/student-progress-report-card/select-student",
  name: "BackOffice/Coach/StudentProgressReportCard/SelectStudent",
},
{
  path: "/academic-coach/video-chat/phone-video-chat-calendar",
  name: "BackOffice/Coach/VideoChat/PhoneVideoChatCalender",
},
{
  path: "/academic-coach/video-chat/select-user",
  name: "BackOffice/Coach/VideoChat/SelectUser",
},
{
  path: "/academic-coach/video-chat/student-assigned",
  name: "BackOffice/Coach/VideoChat/StudentAssigned",
},
{
  path: "/academic-coach/video-chat/phone-video-chat-handling",
  name: "BackOffice/Coach/VideoChat/PhoneVideoChatHandling",
},
{
  path: "/academic-coach/video-chat/registrations",
  name: "BackOffice/Coach/VideoChat/Registrations",
},
{
  path: "/academic-coach/video-chat/financial-performance",
  name: "BackOffice/Coach/VideoChat/FinancialPerformance",
},
{
  path: "/academic-coach/video-chat/missed-calls",
  name: "BackOffice/Coach/VideoChat/MissedCalls",
},
{
  path: "/academic-coach/video-chat/missed-calls-reports",
  name: "BackOffice/Coach/VideoChat/MissedCallsReports",
},
{
  path: "/academic-coach/handle-complaints/open-complaints",
  name: "BackOffice/Coach/HandleComplaints/OpenComplaints",
},
{
  path: "/academic-coach/handle-complaints/complaint-history",
  name: "BackOffice/Coach/HandleComplaints/ComplaintHistory",
},
{
  path: "/academic-coach/handle-complaints/escalated-to-supervisor",
  name: "BackOffice/Coach/HandleComplaints/EscalatedToSupervisor",
},
{
  path: "/academic-coach/handle-complaints/escalated-to-admin",
  name: "BackOffice/Coach/HandleComplaints/EscalatedToAdmin",
},
{
  path: "/academic-coach/tech-support/closed-cases",
  name: "BackOffice/Coach/TechSupport/CasesForAdmin/ClosedCases",
},
{
  path: "/academic-coach/tech-support/new-case",
  name: "BackOffice/Coach/TechSupport/CasesForAdmin/NewCase",
},
{
  path: "/academic-coach/tech-support/existing-cases",
  name: "BackOffice/Coach/TechSupport/CasesForAdmin/ExistingCases",
},
{
  path: "/academic-coach/tech-support/search-faq",
  name: "BackOffice/Coach/TechSupport/SearchFAQ",
},
{
  path: "/academic-coach/tech-support/search-knowledge-base",
  name: "BackOffice/Coach/TechSupport/SearchKnowledgeBase",
},
{
  path: "/academic-coach/application-package-content/application-package-content",
  name: "BackOffice/Coach/APC/APC",
},
{
  path: "/academic-coach/application-package-content/education",
  name: "BackOffice/Coach/APC/Education",
},
{
  path: "/academic-coach/application-package-content/recommendation-letters",
  name: "BackOffice/Coach/APC/RecommendationLetters",
},
{
  path: "/academic-coach/application-package-content/test-scores",
  name: "BackOffice/Coach/APC/TestScores",
},
{
  path: "/academic-coach/application-package-content/financial-docs",
  name: "BackOffice/Coach/APC/FinancialDocs",
},
{
  path: "/academic-coach/application-package-content/financial-docs",
  name: "BackOffice/Coach/APC/FinancialDocs",
},
{
  path: "/academic-coach/application-package-content/custom-elements/harvard",
  name: "BackOffice/Coach/APC/CustomElements/Harvard",
},
{
  path: "/academic-coach/application-package-content/custom-elements/introduction",
  name: "BackOffice/Coach/APC/CustomElements/Introduction",
},
{
  path: "/academic-coach/application-package-content/custom-elements/cv",
  name: "BackOffice/Coach/APC/CustomElements/CV",
},
{
  path: "/academic-coach/application-package-content/custom-elements/essays/essay-1",
  name: "BackOffice/Coach/APC/CustomElements/Essays/Essay1",
},
{
  path: "/academic-coach/application-package-content/custom-elements/essays/essay-2",
  name: "BackOffice/Coach/APC/CustomElements/Essays/Essay2",
},
{
  path: "/academic-coach/application-package-content/custom-elements/scholarship-essays/essay-1",
  name: "BackOffice/Coach/APC/CustomElements/ScholarshipEssays/Essay1",
},
{
  path: "/academic-coach/application-package-content/custom-elements/scholarship-essays/essay-2",
  name: "BackOffice/Coach/APC/CustomElements/ScholarshipEssays/Essay2",
},

{
  path: "/academic-coach/application-package-content/custom-elements/misc-docs",
  name: "BackOffice/Coach/APC/CustomElements/MiscDocs",
},
{
  path: "/academic-coach/application-package-content/custom-elements/diary",
  name: "BackOffice/Coach/APC/CustomElements/Diary",
},
{
  path: "/academic-coach/application-package-content/custom-elements/notes-for-this-school/MIT",
  name: "BackOffice/Coach/APC/CustomElements/NotesForThisSchool/MIT",
},
{
  path: "/academic-coach/application-package-content/custom-elements/notes-for-this-school/stanford",
  name: "BackOffice/Coach/APC/CustomElements/NotesForThisSchool/Stanford",
},
{
  path: "/academic-coach/application-package-content/custom-elements/school-applications/applied",
  name: "BackOffice/Coach/APC/CustomElements/SchoolApplications/Applied",
},
{
  path: "/academic-coach/application-package-content/custom-elements/school-applications/under-review",
  name: "BackOffice/Coach/APC/CustomElements/SchoolApplications/UnderReview",
},
{
  path: "/academic-coach/application-package-content/custom-elements/school-applications/offers",
  name: "BackOffice/Coach/APC/CustomElements/SchoolApplications/Offers",
},
{
  path: "/academic-coach/application-package-content/custom-elements/school-applications/placed",
  name: "BackOffice/Coach/APC/CustomElements/SchoolApplications/Placed",
},
{
  path: "/academic-coach/application-package-content/custom-elements/school-applications/notes",
  name: "BackOffice/Coach/APC/CustomElements/SchoolApplications/Notes",
},
{
  path: "/academic-coach/application-package-content/past-packages",
  name: "BackOffice/Coach/APC/PastPackages",
},

//BackOffice Consultant routes startpoint ----------------------------------------------------------------
{
  path: "/consultant",
  name: "BackOffice/Consultant/Dashboard",
},
{
  path: "/consultant/profile/personal-info",
  name: "BackOffice/Consultant/Profile/PersonalInfo",
},
{
  path: "/consultant/profile/introduction",
  name: "BackOffice/Consultant/Profile/Introduction",
},
{
  path: "/consultant/profile/education",
  name: "BackOffice/Consultant/Profile/Education",
},
{
  path: "/consultant/profile/professional-experience",
  name: "BackOffice/Consultant/Profile/ProfExp",
},
{
  path: "/consultant/profile/skills",
  name: "BackOffice/Consultant/Profile/Skills",
},
{
  path: "/consultant/profile/additional-qualifications",
  name: "BackOffice/Consultant/Profile/AdditionalQualifi",
},
{
  path: "/consultant/profile/awards-and-honors",
  name: "BackOffice/Consultant/Profile/AwardsandHonors",
},
{
  path: "/consultant/profile/languages",
  name: "BackOffice/Consultant/Profile/Languages",
},
{
  path: "/consultant/profile/notes",
  name: "BackOffice/Consultant/Profile/Notes",
},
{
  path: "/consultant/profile/publication",
  name: "BackOffice/Consultant/Profile/Publication",
},
{
  path: "/consultant/profile/reviews",
  name: "BackOffice/Consultant/Profile/Reviews",
},
{
  path: "/consultant/profile/seo-keywords",
  name: "BackOffice/Consultant/Profile/SEOKeywords",
},
{
  path: "/consultant/profile/consulting-philosophy",
  name: "BackOffice/Consultant/Profile/ConsultingPhilosophy",
},
{
  path: "/consultant/profile/tests-taken",
  name: "BackOffice/Consultant/Profile/TestTaken",
},
{
  path: "/consultant/profile/track-record",
  name: "BackOffice/Consultant/Profile/TrackRecord",
},
{
  path: "/consultant/profile/subject-expertise",
  name: "BackOffice/Consultant/Profile/SubjectExpertise",
},
{
  path: "/consultant/profile/past-placements",
  name: "BackOffice/Consultant/Profile/PastPlacements",
},
{
  path: "/consultant/live-meeting/new-meeting",
  name: "BackOffice/Consultant/LiveMeeting/NewMeeting",
},
{
  path: "/consultant/live-meeting/settings",
  name: "BackOffice/Consultant/LiveMeeting/Settings",
},
{
  path: "/consultant/messages/inbox",
  name: "BackOffice/Consultant/Messages/Inbox",
},
{
  path: "/consultant/messages/create-message",
  name: "BackOffice/Consultant/Messages/CreateMessage",
},
{
  path: "/consultant/messages/drafts",
  name: "BackOffice/Consultant/Messages/Drafts",
},
{
  path: "/consultant/messages/message-settings",
  name: "BackOffice/Consultant/Messages/Message_Settings",
},
{
  path: "/consultant/schedule/my-schedule",
  name: "BackOffice/Consultant/Schedule/MySchedule",
},
{
  path: "/consultant/student-progress-report-card/grades-and-scores/ap",
  name: "BackOffice/Consultant/StudentProgressReportCard/GradesandScores/AP",
},
{
  path: "/consultant/student-progress-report-card/grades-and-scores/toefl",
  name: "BackOffice/Consultant/StudentProgressReportCard/GradesandScores/TOEFL",
},
{
  path: "/consultant/student-progress-report-card/grades-and-scores/mathematics",
  name: "BackOffice/Consultant/StudentProgressReportCard/GradesandScores/Mathematics",
},
{
  path: "/consultant/student-progress-report-card/grades-and-scores/microeconomics",
  name: "BackOffice/Consultant/StudentProgressReportCard/GradesandScores/Microeconomics",
},
{
  path: "/consultant/student-progress-report-card/new-actual-score",
  name: "BackOffice/Consultant/StudentProgressReportCard/NewActualScore",
},
{
  path: "/consultant/student-progress-report-card/new-predicted-score",
  name: "BackOffice/Consultant/StudentProgressReportCard/NewPredictedScore",
},
{
  path: "/consultant/student-progress-report-card/select-student",
  name: "BackOffice/Consultant/StudentProgressReportCard/SelectStudent",
},
{
  path: "/consultant/financials/coin-balance",
  name: "BackOffice/Consultant/Financials/CoinBalance",
},
{
  path: "/consultant/financials/payments-received",
  name: "BackOffice/Consultant/Financials/PaymentsReceived",
},
{
  path: "/consultant/financials/performance",
  name: "BackOffice/Consultant/Financials/Performance",
},
{
  path: "/consultant/financials/settings",
  name: "BackOffice/Consultant/Financials/Settings",
},
{
  path: "/consultant/financials/tax-forms",
  name: "BackOffice/Consultant/Financials/TaxForms",
},
{
  path: "/consultant/complaints-and-suggestions/open-complaints",
  name: "BackOffice/Consultant/ComplaintsandSuggestions/OpenComplaints",
},
{
  path: "/consultant/complaints-and-suggestions/complaints-history",
  name: "BackOffice/Consultant/ComplaintsandSuggestions/ComplaintsHistory",
},
{
  path: "/consultant/complaints-and-suggestions/new-complaint",
  name: "BackOffice/Consultant/ComplaintsandSuggestions/NewComplaint",
},
{
  path: "/consultant/complaints-and-suggestions/new-suggestion",
  name: "BackOffice/Consultant/ComplaintsandSuggestions/NewSuggestion",
},
{
  path: "/consultant/tech-support/closed-cases",
  name: "BackOffice/Consultant/TechSupport/ClosedCases",
},
{
  path: "/consultant/tech-support/new-case",
  name: "BackOffice/Consultant/TechSupport/NewCase",
},
{
  path: "/consultant/tech-support/existing-cases",
  name: "BackOffice/Consultant/TechSupport/ExistingCases",
},
{
  path: "/consultant/tech-support/search-FAQ",
  name: "BackOffice/Consultant/TechSupport/SearchFAQ",
},
{
  path: "/consultant/tech-support/search-knowledge-base",
  name: "BackOffice/Consultant/TechSupport/SearchKnowledgeBase",
},


{
  path: "/consultant/reports/entire-consulting-period-reviews-received",
  name: "BackOffice/Consultant/ReportsandReviews/EntirePeriodReviewsReceived", 
},
{
  path: "/consultant/reports/pending-consultant-reports",
  name: "BackOffice/Consultant/ReportsandReviews/PendingConsultingReports",
},
{
  path: "/consultant/reports/previous-consulting-reports",
  name: "BackOffice/Consultant/ReportsandReviews/PreviousConsultingReports",
},
{
  path: "/consultant/reports/reviews-on-consultant-profile",
  name: "BackOffice/Consultant/ReportsandReviews/ReviewsonConsultantProfile",
},
{
  path: "/consultant/reports/satisfaction-survey",
  name: "BackOffice/Consultant/ReportsandReviews/SatisfactionSurvey",
},
{
  path: "/consultant/reports/consulting-session-reviews-received",
  name: "BackOffice/Consultant/ReportsandReviews/ConsultingSessionReviewsReceived",
},
{
  path: "/consultant/reports/recommendation-letter-request",
  name: "BackOffice/Consultant/ReportsandReviews/RecommendationLetterRequests",
},


{
  path: "/consultant/application-package-content/application-package-content",
  name: "BackOffice/Consultant/APC/APC",
},
{
  path: "/consultant/application-package-content/education",
  name: "BackOffice/Consultant/APC/Education",
},
{
  path: "/consultant/application-package-content/recommendation-letters",
  name: "BackOffice/Consultant/APC/RecommendationLetters",
},
{
  path: "/consultant/application-package-content/test-scores",
  name: "BackOffice/Consultant/APC/TestScores",
},
{
  path: "/consultant/application-package-content/financial-docs",
  name: "BackOffice/Consultant/APC/FinancialDocs",
},
{
  path: "/consultant/application-package-content/financial-docs",
  name: "BackOffice/Consultant/APC/FinancialDocs",
},
{
  path: "/consultant/application-package-content/custom-elements/harvard",
  name: "BackOffice/Consultant/APC/CustomElements/Harvard",
},
{
  path: "/consultant/application-package-content/custom-elements/introduction",
  name: "BackOffice/Consultant/APC/CustomElements/Introduction",
},
{
  path: "/consultant/application-package-content/custom-elements/cv",
  name: "BackOffice/Consultant/APC/CustomElements/CV",
},
{
  path: "/consultant/application-package-content/custom-elements/essays/essay-1",
  name: "BackOffice/Consultant/APC/CustomElements/Essays/Essay1",
},
{
  path: "/consultant/application-package-content/custom-elements/essays/essay-2",
  name: "BackOffice/Consultant/APC/CustomElements/Essays/Essay2",
},
{
  path: "/consultant/application-package-content/custom-elements/scholarship-essays/essay-1",
  name: "BackOffice/Consultant/APC/CustomElements/ScholarshipEssays/Essay1",
},
{
  path: "/consultant/application-package-content/custom-elements/scholarship-essays/essay-2",
  name: "BackOffice/Consultant/APC/CustomElements/ScholarshipEssays/Essay2",
},
{
  path: "/consultant/application-package-content/custom-elements/misc-docs",
  name: "BackOffice/Consultant/APC/CustomElements/MiscDocs",
},
{
  path: "/consultant/application-package-content/custom-elements/diary",
  name: "BackOffice/Consultant/APC/CustomElements/Diary",
},
{
  path: "/consultant/application-package-content/custom-elements/notes-for-this-school/MIT",
  name: "BackOffice/Consultant/APC/CustomElements/NotesForThisSchool/MIT",
},
{
  path: "/consultant/application-package-content/custom-elements/notes-for-this-school/stanford",
  name: "BackOffice/Consultant/APC/CustomElements/NotesForThisSchool/Stanford",
},
{
  path: "/consultant/application-package-content/custom-elements/school-applications/applied",
  name: "BackOffice/Consultant/APC/CustomElements/SchoolApplications/Applied",
},
{
  path: "/consultant/application-package-content/custom-elements/school-applications/under-review",
  name: "BackOffice/Consultant/APC/CustomElements/SchoolApplications/UnderReview",
},
{
  path: "/consultant/application-package-content/custom-elements/school-applications/offers",
  name: "BackOffice/Consultant/APC/CustomElements/SchoolApplications/Offers",
},
{
  path: "/consultant/application-package-content/custom-elements/school-applications/placed",
  name: "BackOffice/Consultant/APC/CustomElements/SchoolApplications/Placed",
},
{
  path: "/consultant/application-package-content/custom-elements/school-applications/notes",
  name: "BackOffice/Consultant/APC/CustomElements/SchoolApplications/Notes",
},
{
  path: "/consultant/application-package-content/past-packages",
  name: "BackOffice/Consultant/APC/PastPackages",
},

//BackOffice Consultant routes endpoint ----------------------------------------------------------------------------------------------




  //Front Office routes beforeEnter: (from, to, next) => next({ path: "/index.html" }),
  {
    path: "/",
    name: "FrontOffice/Login",
    meta:{title:'Login'}
  },
  {
    path: "/index.html",
    name: "FrontOffice/LandPage",
    meta:{title:'Home'}
  },
  {
    path: "/register",
    name: "FrontOffice/Register",
    meta:{title:'Register'},
  },
  {
    path: "/login",
    name: "FrontOffice/Login",
    meta:{title:'Login'}
  },
  {
    path: "/about-us/about-us",
    name: "FrontOffice/AboutUs/AboutUs",
    meta:{title:'About us'}
  },
  {
    path: "/about-us/contact-us",
    name: "FrontOffice/AboutUs/ContactUs",
    meta:{title:'About us - Contact us'}
  },
  {
    path: "/about-us/honor-code",
    name: "FrontOffice/AboutUs/HonorCode",
    meta:{title:'About us - Honor code'}
  },
  {
    path: "/about-us/location",
    name: "FrontOffice/AboutUs/Locations",
    meta:{title:'About us - Locations'}
  },
  {
    path: "/about-us/mission",
    name: "FrontOffice/AboutUs/Mission",
    meta:{title:'About us - Mission'}
  },
  {
    path: "/about-us/our-system",
    name: "FrontOffice/AboutUs/OurSystem",
    meta:{title:'About us - Our system'}
  },
  {
    path: "/about-us/reviews-and-testimonials",
    name: "FrontOffice/AboutUs/Testimonials",
    meta:{title:'About us - Testimonials'}
  },
  {
    path: "/about-us/vision",
    name: "FrontOffice/AboutUs/Vision",
    meta:{title:'About us - Vision'}
  },
  {
    path: "/about-us/why-us",
    name: "FrontOffice/AboutUs/WhyUs",
    meta:{title:'About us - Why us'}
  },
  {
    path: "/about-us/tutoring-consulting-jobs",
    name: "FrontOffice/AboutUs/TutoringConsultingJobs",
    meta:{title:'About us - Tutoring consulting jobs'}
  },
  {
    path: "/about-us/careers",
    name: "FrontOffice/AboutUs/Careers",
    meta:{title:'About us - Careers'}
  },
  {
    path: "/private-tutoring",
    name: "FrontOffice/PrivateTutoring",
    meta:{title:'Private tutoring'}
  },
  {
    path: "/private-tutoring/IB",
    name: "FrontOffice/PrivateTutoring/IB",
    meta:{title:'Private tutoring - IB'}
  },
  {
    path: "/private-tutoring/IB/economics",
    name: "FrontOffice/PrivateTutoring/IB/Economics",
    meta:{title:'Private tutoring - IB - Economics'}
  },
  {
    path: "/private-tutoring/IB/mathematics",
    name: "FrontOffice/PrivateTutoring/IB/Mathematics",
    meta:{title:'Private tutoring - IB - Mathematics'}
  },
  {
    path: "/private-tutoring/AP",
    name: "FrontOffice/PrivateTutoring/AP",
    meta:{title:'Private tutoring - AP'}
  },
  {
    path: "/private-tutoring/AP/microeconomics",
    name: "FrontOffice/PrivateTutoring/AP/Microeconomics",
    meta:{title:'Private tutoring - AP - Microeconomics'}
  },
  {
    path: "/private-tutoring/AP/macroeconomics",
    name: "FrontOffice/PrivateTutoring/AP/Macroeconomics",
    meta:{title:'Private tutoring - AP - Macroeconomics'}
  },
  {
    path: "/private-tutoring/A-Level",
    name: "FrontOffice/PrivateTutoring/ALevel",
    meta:{title:'Private tutoring - A Level'}
  },
  {
    path: "/private-tutoring/A-level/economics",
    name: "FrontOffice/PrivateTutoring/ALevel/Economics",
    meta:{title:'Private tutoring - A Level - Economics'}
  },
  {
    path: "/private-tutoring/AS-Level",
    name: "FrontOffice/PrivateTutoring/ASLevel",
    meta:{title:'Private tutoring - AS Level'}
  },
  {
    path: "/private-tutoring/AS-level/economics",
    name: "FrontOffice/PrivateTutoring/ASLevel/Economics",
    meta:{title:'Private tutoring - AS Level - Economics'}
  },
  {
    path: "/private-tutoring/IGCSE",
    name: "FrontOffice/PrivateTutoring/IGCSE",
    meta:{title:'Private tutoring - IGCSE'}
  },
  {
    path: "/private-tutoring/IGCSE/economics",
    name: "FrontOffice/PrivateTutoring/IGCSE/Economics",
    meta:{title:'Private tutoring - IGCSE - Economics'}
  },
  {
    path: "/test-preparation",
    name: "FrontOffice/TestPreparation",
    meta:{title:'Test preparation'}
  },
  {
    path: "/test-preparation/SAT",
    name: "FrontOffice/TestPreparation/SAT",
    meta:{title:'Test preparation - SAT'}
  },
  {
    path: "/test-preparation/ACT",
    name: "FrontOffice/TestPreparation/ACT",
    meta:{title:'Test preparation - ACT'}
  },
  {
    path: "/test-preparation/ACT/mathematics",
    name: "FrontOffice/TestPreparation/ACT/Mathematics",
    meta:{title:'Test preparation - ACT - Mathematics'}
  },
  {
    path: "/test-preparation/TOEFL",
    name: "FrontOffice/TestPreparation/TOEFL",
    meta:{title:'Test preparation - TOEFL'}
  },
  {
    path: "/test-preparation/IELTS",
    name: "FrontOffice/TestPreparation/IELTS",
    meta:{title:'Test preparation - TOEFL'}
  },
  {
    path: "/consulting",
    name: "FrontOffice/Consulting",
    meta:{title:'Consulting'}
  },
  {
    path: "/consulting/academic-coaching",
    name: "FrontOffice/Consulting/AcademicCoaching",
    meta:{title:'Consulting - Academic coaching'}
  },
  {
    path: "/consulting/overseas-education-consulting",
    name: "FrontOffice/Consulting/OverseasEducationConsulting",
    meta:{title:'Consulting - Overseas education consulting'}
  },
  {
    path: "/consulting/college-counseling",
    name: "FrontOffice/Consulting/CollegeCounseling",
    meta:{title:'Consulting - College counseling'}
  },
  {
    path: "/FAQ",
    name: "FrontOffice/FAQ",
    meta:{title:'FAQ'}
  },
];
const routes = routesOptions.map((route) => {
  if (route.name.includes("BackOffice")) {
    return {
      ...route,
      components: {
        default: () => import(`../pages/${route.name}.vue`),
        Sidebar: () => import("../components/Sidebar.vue"),
      },
    };
  } else {
    return {
      ...route,
      components: {
        default: () => import(`../pages/${route.name}.vue`),
      },
    };
  }
});
/* webpackChunkName: "[request]" */
const router = createRouter({
  history: createWebHistory(),

  routes: routes,

  
  scrollBehavior(to) {
    if (
      to.path.includes("about-us") ||
      to.path.includes("FAQ") ||
      to.path.includes("login")
    ) {
      return {
        top: 0,
        behavior: "smooth",
      };
    }
    return {
      el: "#crumb",
      top: 120,
      behavior: "smooth",
    };
  },
});
/* */
router.beforeEach((to, from, next) => {
  const title = to.meta.title
  if (title) {
    document.title = title;
  }else{
    console.log('title is undefined');
  }


  if (to.path === "/")
    next({
      path: "/index.html",
    });
  else next();
});


export default router;
