<template>
  <footer class="main-footer footer">
    Copyright &copy; 2022 All rights reserved.
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  font-weight: 500;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  background-color: var(--background-color-third);
  color: var(--text-color);
}
.main-footer {
  border: none;
}
</style>
